import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import Home from "./Website/Pages/Home/Home";
import ArtPhotos from "./Website/Pages/ArtPhotos";
import Admin from "./Admin/Pages/Admin";
import Dashboard from "./Admin/Pages/Dashboard";
import PrivateRoute from "./Admin/Components/PrivateRoute";
import ArtView from "./Admin/Pages/ArtView";
import SizesView from "./Admin/Pages/SizesView";
import OrdersView from "./Admin/Pages/OrdersView";
import OrderInformation from "./Admin/Pages/OrderInformation";
import ReportsView from "./Admin/Pages/ReportsView";
import EditPhotos from "./Website/Pages/EditPhotos";
import MyOrders from "./Website/Pages/MyOrders";
import { useTheme } from "./Context/ThemeContext";
import PhotoDetails from "./Website/Pages/PhotoDetails";
import { SkeletonTheme } from "react-loading-skeleton";
import GoogleCallBack from "./Website/Pages/GoogleCallBack";
import PaymentDetails from "./Website/Pages/PaymentDetails";
import PaymentDetailsCallBack from "./Website/Pages/PaymentDetailsCallBack";
import { useContext, useEffect, useState } from "react";
import TecrekVisitorsView from "./Admin/Pages/TecrekVisitorsView";
import VividVisionsVisitorsView from "./Admin/Pages/VividVisionsVisitorsView";
import UsersView from "./Admin/Pages/UsersView";
import Cookies from "js-cookie"
import { PhotoContext } from "./Context/PhotoContext";
import { useTranslation } from "react-i18next";
import ArtHome from "./Website/Pages/BrowseArtCollection/ArtHome";
import { postCuriousRegister } from "./Api/Auth";
import Loading from "./Shared/Components/Loading";
import { getCurrencyByCountry, language } from "./Api/api";
import TermsAndConditions from "./Website/Pages/TermsAndConditions";
import PaymentForm from "./Website/Pages/PaymentForm";
import OrderDetails from "./Website/Pages/OrderDetails";
import Reviews from "./Website/Pages/Reviews";
import React from 'react';
import EditOnePhotoWrapper from "./Website/Components/EditOnePhotoWrapper";
import Tracktions from "./Admin/Pages/Tracktions";
import TractionById from "./Admin/Pages/TractionById";
import { fetchAds } from "./Api/Home";
import Ads from "./Admin/Pages/Ads";
import AccessTokens from "./Admin/Pages/AccessTokens";
import Thanks from "./Website/Pages/Thanks";
import AccessTokenByUserId from "./Admin/Pages/AccessTokenByUserId";
import UsersById from "./Admin/Pages/UsersById";
import ArtHomeByCategoryId from "./Website/Pages/BrowseArtCollection/ArtHomeByCategoryId";
import FirstPage from "./Website/Pages/FirstPage";
import NameRegister from "./Website/Pages/NameRegister";
import EmailRegister from "./Website/Pages/EmailRegister";
// import { onMessage } from 'firebase/messaging';
// import { generateToken, messaging } from "./Shared/Components/Notifacations/firebase";

function App() {
  const [loading, setLoading] = useState(false)
  const curiousToken = Cookies.get("curiousToken");
  const userToken = Cookies.get("userToken");
  const { theme } = useTheme();
  const { i18n } = useTranslation();
  const { dir, setDir, myLang } = useContext(PhotoContext)
  const lng = localStorage.getItem("vividVisionsLang") || "English";
  const lngAlreadyDetected = localStorage.getItem("lngAlreadyDetected");
  const getAdData = async () => {
    try {
      const response = await fetchAds();
      // console.log(response);
      localStorage.setItem("Ads", JSON.stringify(response.data))
    } catch (err) {
      console.error("get ads data err : ", err);
    }
  };
    const fetchCurrency = async () => {
    try {
      const currency = await getCurrencyByCountry();
      // console.log(currency.data);
      localStorage.setItem("currency", currency.data || "USD");
    } catch (err) {
      console.error("get currency err : ", err);
    }
  };

  const curious = async () => {
    try {
      const response = await postCuriousRegister();
      // console.log(response);
      if (response.status === "success") {
        localStorage.setItem("userId", response.data.user.id);
        Cookies.set("curiousToken", response.data.token);
        window.location.reload()
      }
    } catch (err) {
      console.error("get curious token err : ", err);
    }
  };

  const whatIsLanguage = async () => {
    try {
      setLoading(true)
      const response = await language();
      // console.log(response.data.language);
      const detectedLanguage =
        response.data.language === "Arabic"
          ? "Arabic"
          : response.data.language === "German"
            ? "Deutsch"
            : "English";
      localStorage.setItem("vividVisionsLang", detectedLanguage);
      if (response.data) {
        localStorage.setItem("lngAlreadyDetected", true)
      }
    } catch (error) {
      console.error("lang err : ", error);
    }
  };

  const sort = async () => {
    !userToken && !curiousToken && setLoading(true);
    try {
      if (!userToken && !curiousToken) {
        await curious();
      }
      if (!lngAlreadyDetected) {
        await whatIsLanguage();
      }
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    sort()
    fetchCurrency()
  }, [])
  useEffect(() => {
    document.body.dir = dir;
    setDir(lng === "Arabic" ? "rtl" : "ltr")
    i18n.changeLanguage(lng === "Arabic" ? "Arabic" : lng === "English" ? "English" : "Deutsch")
    getAdData();
  }, [dir, lng, myLang]);

//   useEffect(() => {
//       generateToken()
//       onMessage(messaging, (payload) => {
//   console.log(payload)
// })
//     }, [])

  const router = createBrowserRouter([
    // {
    //   path: "/",
    //   element: (curiousToken || userToken) ? <Navigate to={"/home"} />:  <FirstPage />,
    // },
    // {
    //   path: "/curious-to-user",
    //   element:  <CuriousToUser />,
    // },
    // {
    //   path: "/name-register",
    //   element:  (curiousToken || userToken) ? <Navigate to={"/home"} /> : <NameRegister />,
    // },
    // {
    //   path: "/email-register",
    //   element:  (curiousToken || userToken) ? <Navigate to={"/home"} /> : <EmailRegister />,
    // },
    {
      path: "/",
      element:  <Home /> ,
    },
    {
      path: `/api/auth/google/callback`,
      element: <GoogleCallBack />,
    },
    {
      path: "/edit-photos",
      element: <EditPhotos />,
    },
    {
      path: "/edit-photos/:id",
      element: <EditOnePhotoWrapper />,
    },
    {
      path: "/payment-details/:orderNumber",
      element: <PaymentDetails />,
    },
    {
      path: "/payment-form/:orderNumber",
      element: <PaymentForm />,
    },
    {
      path: "/payment-details/callback/:orderNumber",
      element:
        <PaymentDetailsCallBack />,
    },
    {
      path: "/order-details/:orderNumber",
      element: <OrderDetails />,
    },
    {
      path: "/thanks",
      element: <Thanks />,
    },
    {
      path: "/reviews",
      element: <Reviews />,
    },
    {
      path: "/my-orders",
      element: userToken ? <MyOrders /> : <Navigate to={`/`} />,
    },
    {
      path: "/art-home",
      element: <ArtHome />,
    },
    {
      path: "/art-home/:id",
      element: <ArtHomeByCategoryId />,
    },
    {
      path: "/art-photos",
      element: <ArtPhotos />,
    },
    {
      path: "/art-photos/:id",
      element: <PhotoDetails />,
    },
    {
      path: "/terms-of-service",
      element: <TermsAndConditions />,
    },
    // {
    //   path: "/privacy-notice",
    //   element: <PrivacyNotice />,
    // },
    {
      path: "/admin",
      element: <Admin />,
    },
    {
      path: "/dashboard",
      element: (
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      ),
      children: [
        {
          index: true,
          element: <UsersView />,
        },
        {
          path: "user/:id",
          element: <UsersById />,
        },
        {
          path: "art",
          element: <ArtView />,
        },
        {
          path: "sizes",
          element: <SizesView />,
        },
        {
          path: "orders",
          element: <OrdersView />,
        },
        {
          path: "/dashboard/orders/:id",
          element: <OrderInformation />,
        },
        {
          path: "reports",
          element: <ReportsView />,
        },
        {
          path: "tecrek-visitors",
          element: <TecrekVisitorsView />,
        },
        {
          path: "vividVisions-visitors",
          element: <VividVisionsVisitorsView />,
        },
        {
          path: "tracktions",
          element: <Tracktions />,
        },
        {
          path: "/dashboard/tracktions/:id",
          element: <TractionById />,
        },
        {
          path: "ads",
          element: <Ads />,
        },
        {
          path: "access-tokens",
          element: <AccessTokens />,
        },
        {
          path: "/dashboard/access-tokens/:id",
          element: <AccessTokenByUserId />,
        },
      ],
    },
  ]);


  return (
    <div
      className={`${theme === "dark"
        ? "bg-DarkMainBg text-DarkMainText"
        : "bg-MainBg text-MainText"
        } h-full`}
    >
      <SkeletonTheme
        baseColor={`${theme === "dark" ? "#202020" : "#F5F5F5"}`}
        highlightColor={`${theme === "dark" ? "#444" : "#E0E0E0"}`}
      >
        {loading ? <Loading /> :
          <RouterProvider router={router} />
        }
      </SkeletonTheme>
    </div>
  );
}

export default App;
