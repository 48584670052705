import React, { useContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaChartArea, FaHistory } from "react-icons/fa";
import { FaHeart, FaQuestion } from "react-icons/fa6";
import americaFlag from "../../assets/imgs/americaFlag.png";
import germanyFlag from "../../assets/imgs/germanyFlag.png";
import saudiArabiaFlag from "../../assets/imgs/saudiArabiaFlag.png";
import { LiaToggleOffSolid, LiaToggleOnSolid } from "react-icons/lia";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import MainButton from "../../Shared/Components/MainButton";
import SidebarItem from "./SidebarItem";
import { PhotoContext } from "../../Context/PhotoContext";
import { PopupContext } from "../../Context/PopupContext";
import logo from "../../assets/imgs/Logo/logo.png";
import logoDM from "../../assets/imgs/Logo/darkModeLogo.png";
import { userToken } from "../../Api/api";
import { useTheme } from "../../Context/ThemeContext";
import { BsPersonSquare } from "react-icons/bs";
import { FiPercent } from "react-icons/fi";

function Sidebar() {
  const { theme, setTheme } = useTheme();
  const userEmail = window.localStorage.getItem("email");
  const lng = localStorage.getItem("vividVisionsLang") || "English";

  const { t } = useTranslation();
  const {
    setLoginOpen,
    setSideBarOpen,
    setLanguageOpen,
    setPromoCodeOpen,
    setFAQsOpen,
  } = useContext(PopupContext);

  const colors = ["#e64d43", "#43ade6", "#46e643", "#d8e643", "#8f43e6"];
  const {
    sideBarOpen,
    selectedLanguage,
    handleLogout,
    totalPhotos,
    logNavigation,
  } = useContext(PhotoContext);

  const [randomColor, setRandomColor] = useState("");
  function getRandomColor() {
    const index = Math.floor(Math.random() * colors.length);
    return colors[index];
  }

  useEffect(() => {
    const userToken = Cookies.get("userToken");
    if (userToken && !randomColor) {
      setRandomColor(getRandomColor());
    }
  }, [userToken]);

  return (
    <div className={`overflow-y-auto h-full z30`}>
      <div
        className={`flex justify-center items-center h-[82px] relative mb-[25px] border-b border-[#ededed]`}
      >
        <Link
          onClick={() => {
            setSideBarOpen(false);
            logNavigation("/home");
          }}
          to="/"
          className={`${
            theme === "dark" ? "text-DarkMainText" : "text-MainText"
          } ${
            lng === "Arabic" && "flex-row-reverse"
          } flex items-center f-bold text-size17 tracking-[2px]`}
        >
          {theme === "dark" ? (
            <img className=" h-[50px]" src={logoDM} alt="" />
          ) : (
            <img className=" h-[50px]" src={logo} alt="" />
          )}
        </Link>
        <div
          className={`${
            theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
          } ${
            lng === "Arabic" ? "left-[15px]" : "right-[15px]"
          } absolute c-pointer top-[50%] -translate-y-[50%] p-[5px] rounded-full`}
        >
          <AiOutlineClose onClick={() => setSideBarOpen(false)} size={20} />
        </div>
      </div>
      <div className="px-[12px] max-vsm:px-[5px]">
        <div className="whitespace-pre-line px-3 py-1 border-b border-[#ededed]">
          {userToken ? (
            <div className="px-[12px] max-vsm:px-[5px]">
              <div className="mt-[15px] vsm:mr-[13px] mb-[5px] vsm:ml-[12px] flex max-vsm:flex-col max-vsm:gap-2 pb-[25px] ">
                <div
                  style={{
                    backgroundColor: randomColor,
                  }}
                  className="border border-[#ededed] rounded-[4px] w-[40px] h-[40px] min-w-[40px] min-h-[40px] p-1 overflow-hidden flex justify-center items-center"
                >
                  {userEmail?.substring(0, 2).toUpperCase()}
                </div>
                <div
                  className={`${
                    lng === "Arabic" ? "vsm:mr-[18px]" : "vsm:ml-[18px]"
                  } max-vsm:flex max-vsm:flex-col max-vsm:gap-2`}
                >
                  <h1
                    className={`${
                      theme === "dark" ? "text-DarkMainText" : "text-MainText"
                    } font-[700] text-size14 leading-[19px]`}
                  >
                    {userEmail}
                  </h1>
                  <h2
                    onClick={handleLogout}
                    className={`${
                      theme === "dark" ? "text-DarkMainText" : "text-black"
                    } c-pointer font-[400] text-size14 underline`}
                  >
                    {t("Logout")}
                  </h2>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1
                className={`${
                  theme === "dark" ? "text-DarkMainText" : "text-MainText"
                } text-size16 leading-[20px] mb-4`}
              >
                <span
                  className={`${lng === "Arabic" ? "ml-1" : "mr-1"} f-bold`}
                >
                  {t("Register")}
                </span>
                {lng === "Arabic" && <br />}
                {t("to save your progress and monitor your orders.")}
              </h1>
              <MainButton
                onClick={() => {
                  setLoginOpen(true);
                }}
                content={t("Login")}
                className="min-w-[165px] min-h-[36px] rounded-[6px] font-[700] px-2 py-1 mb-[24px]"
              />
            </>
          )}
        </div>
      </div>
      <div className="px-[12px] max-vsm:px-[5px]">
        <ul className="border-b border-[#ededed]">
          <SidebarItem
            onClick={() => {
              setSideBarOpen(false);
              logNavigation("/edit-photos");
            }}
            link={"/edit-photos"}
            content={totalPhotos > 0 ? t("Continue Design") : t("Start Design")}
            icon={<BsPersonSquare className="text-PrimaryColor" />}
          />
          <SidebarItem
            onClick={() => {
              setSideBarOpen(false);
              logNavigation("/art-home");
            }}
            link="/art-home"
            content={t("Art Gallery")}
            icon={<FaChartArea className="text-PrimaryColor" />}
          />
        </ul>
        <ul className="border-b border-[#ededed]">
          {/* <SidebarItem
            onClick={() => {
              setPromoCodeOpen(true);
            }}
            content={t("Discount Codes")}
            icon={<FiPercent className="text-PrimaryColor" />}
          /> */}
          {userToken && (
            <SidebarItem
              onClick={() => setSideBarOpen(false)}
              link="/my-orders"
              content={t("My Orders")}
              icon={<FaHistory className="text-PrimaryColor" />}
            />
          )}
          <SidebarItem
            link={`/reviews`}
            content={t("Reviews")}
            icon={<FaHeart className="text-PrimaryColor" />}
          />
          <SidebarItem
            onClick={() => {
              setFAQsOpen(true);
            }}
            content={t("FAQs")}
            icon={<FaQuestion className="text-PrimaryColor" />}
          />
          {/* <SidebarItem
            onClick={() => {
              theme === "light" ? setTheme("dark") : setTheme("light");
            }}
            content={t(theme === "light" ? t("Dark Mode") : t("Light Mode"))}
            icon={
              theme === "light" ? <LiaToggleOffSolid /> : <LiaToggleOnSolid />
            }
          /> */}
        </ul>
      </div>
      <div
        className={`${
          lng === "Arabic"
            ? "mr-[32px] max-vsm:mr-[20px]"
            : "ml-[32px] max-vsm:ml-[20px]"
        } px-[12px] max-vsm:px-[5px] mt-[13px]`}
      >
        <div
          className={`${
            theme === "dark" && "text-DarkMainText"
          } flex flex-col justify-center text-[#777]`}
        >
          <Link
            onClick={() => setSideBarOpen(false)}
            to="/terms-of-service"
            className={`${
              theme === "dark" && "text-DarkMainText/70"
            } text-size14 py-[13px] w-fit`}
          >
            {t("Terms of Service")}
          </Link>
          <div
            onClick={() => setLanguageOpen(true)}
            className={`${
              theme === "dark"
                ? "text-DarkMainText bg-[#535353] hover:bg-[#414141]"
                : "text-MainText bg-white  hover:bg-[#d1d1d1] shadow-md"
            } d-300 flex items-center justify-between mt-4 mb-6 py-2 px-4 c-pointer rounded-xl hover:bg-[#EDEDED] w-[150px]`}
          >
            <h1>{lng}</h1>
            <img
              onClick={() => setLanguageOpen(true)}
              className="w-[28px] h-[20px] rounded-sm c-pointer"
              src={
                lng === "Arabic"
                  ? saudiArabiaFlag
                  : lng === "English"
                  ? americaFlag
                  : germanyFlag
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
