import React from "react";

function Promo() {
  const lng = localStorage.getItem("vividVisionsLang") || "English";
  const ads = JSON.parse(localStorage.getItem("Ads")) || [];

  return (
    <>
      <div className="max-md:hidden w-full overflow-hidden bg-PrimaryColor t-white f-semi-bold h-[53px] ">
        <span
          className={`${
            lng === "Arabic" ? "marqueeArabic" : "marquee"
          } max-w-fit h-[53px] `}
        >
          {ads && ads.length > 0
            ? lng === "English"
              ? ads[0]?.description_en
              : lng === "Deutsch"
              ? ads[0]?.description_gr
              : null
            : null}
        </span>
      </div>
      <div className="md:hidden w-full overflow-hidden bg-PrimaryColor t-white f-semi-bold h-[37px] ">
        <span
          className={`${
            lng === "Arabic" ? "marqueeMobArabic" : "marqueeMob"
          } max-w-fit h-[37px]  text-size14`}
        >
          {ads && ads.length > 0
            ? lng === "English"
              ? ads[0]?.description_en
              : lng === "Deutsch"
              ? ads[0]?.description_gr
              : null
            : null}
        </span>
      </div>
    </>
  );
}

export default Promo;
