import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/imgs/Logo/logo.png";
import logoDM from "../../assets/imgs/Logo/darkModeLogo.png";
import BreakDiv from "../../Shared/Components/BreakDiv";
import { useTranslation } from "react-i18next";
import { PhotoContext } from "../../Context/PhotoContext";
import { PopupContext } from "../../Context/PopupContext";
import { useTheme } from "../../Context/ThemeContext";
import americaFlag from "../../assets/imgs/americaFlag.png";
import germanyFlag from "../../assets/imgs/germanyFlag.png";
import saudiArabiaFlag from "../../assets/imgs/saudiArabiaFlag.png";
import SelectLanguage from "./SelectLanguage";
import facebookLogo from "../../assets/imgs/SocialMediaLogo/fbk.png";
import instagramLogo from "../../assets/imgs/SocialMediaLogo/ig.png";
import pinterestLogo from "../../assets/imgs/SocialMediaLogo/pt.png";
import tictokLogo from "../../assets/imgs/SocialMediaLogo/tt.png";
import emailLogo from "../../assets/imgs/SocialMediaLogo/em.webp";
import trust from "../../assets/imgs/Footer/trust.png";
import payWays from "../../assets/imgs/Footer/payWays.png";
import { FaPinterestP } from "react-icons/fa6";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const { theme } = useTheme();
  const { selectedLanguage } = useContext(PhotoContext);
  const { setLanguageOpen, setFAQsOpen, setPromoCodeOpen } =
    useContext(PopupContext);
  const { t } = useTranslation();
  const lng = localStorage.getItem("vividVisionsLang") || "English";
  const currency = window.localStorage.getItem("currency");

  return (
    <>
      {" "}
      <div className={`${theme === "dark" && "text-DarkMainText"} `}>
        <div className="grid grid-cols-4 max-md:text-center max-md:grid-cols-1">
          <Link
            to="/"
            className={`${
              lng === "Arabic" && "flex-row-reverse"
            } flex items-center f-bold text-size17 tracking-[2px] my-5 w-fit max-md:mx-auto`}
          >
            {theme === "dark" ? (
              <img className=" h-[50px]" src={logoDM} alt="" />
            ) : (
              <img className=" h-[50px]" src={logo} alt="" />
            )}
          </Link>
          <div className="flex flex-col">
            <h1 className="text-size16 py-3 f-bold max-md:hidden w-fit">
              {t("About")}
            </h1>
            <Link
              to={"/art-home"}
              className="md:block md:w-fit md:hover:text-PrimaryColor md:d-300 md:text-size16 md:py-3 max-md:leading-[42px]"
            >
              {t("Art Gallery")}
            </Link>
            <Link
              to={"/reviews"}
              className="md:block md:w-fit md:hover:text-PrimaryColor md:d-300 md:text-size16 md:py-3 max-md:leading-[42px]"
            >
              {t("Reviews")}
            </Link>
            <Link
              onClick={() => setFAQsOpen(true)}
              className="md:block md:w-fit md:hover:text-PrimaryColor md:d-300 md:text-size16 md:py-3 max-md:leading-[42px]"
            >
              {t("FAQs")}
            </Link>
          </div>
          <BreakDiv className="my-[10px] md:hidden" />
          <div className="flex md:flex-col max-md:gap-2 max-md:justify-center max-md:items-center max-md:mb-3 max-md:mt-3">
            <h1 className=" text-size16 py-3 f-bold max-md:hidden w-fit">
              {t("Follow Us")}
            </h1>
            <Link
              to="https://www.pinterest.com/vividvisionsprint/"
              className="md:w-fit flex items-center gap-2 md:hover:text-PrimaryColor md:d-300 md:text-size16 md:py-3 max-md:leading-[42px]"
            >
              <img
                className="w-[20px] h-[20px] max-md:w-[25px] max-md:h-[25px]"
                src={pinterestLogo}
                alt=""
              />
              <span className="max-md:hidden">{t("Pinterest")}</span>
            </Link>
            <Link
              to="https://www.instagram.com/vividvisionsprint?igsh=MXEzbDVuenA2eXV1NA=="
              className="md:w-fit flex items-center gap-2 md:hover:text-PrimaryColor md:d-300 md:text-size16 md:py-3 max-md:leading-[42px]"
            >
              <img
                className="w-[20px] h-[20px] max-md:w-[25px] max-md:h-[25px]"
                src={instagramLogo}
                alt=""
              />
              <span className="max-md:hidden">{t("Instagram")}</span>
            </Link>
            <Link
              to="https://www.facebook.com/share/17ofKoPE7g/?mibextid=LQQJ4d"
              className="md:w-fit flex items-center gap-2 md:hover:text-PrimaryColor md:d-300 md:text-size16 md:py-3 max-md:leading-[42px]"
            >
              <img
                className="w-[20px] h-[20px] max-md:w-[25px] max-md:h-[25px]"
                src={facebookLogo}
                alt=""
              />
              <span className="max-md:hidden">{t("Facebook")}</span>
            </Link>
            <Link
              to="https://www.tiktok.com/@vividvisionsprint?_t=8qDBs4yZ4mz&_r=1"
              className="md:w-fit flex items-center gap-2 md:hover:text-PrimaryColor md:d-300 md:text-size16 md:py-3 max-md:leading-[42px]"
            >
              <img
                className="w-[20px] h-[20px] max-md:w-[25px] max-md:h-[25px]"
                src={tictokLogo}
                alt=""
              />
              <span className="max-md:hidden">{t("Tiktok")}</span>
            </Link>
          </div>
          {/* <div className="flex md:flex-col max-md:gap-2 max-md:justify-center max-md:items-center max-md:mb-3 max-md:mt-3">
            <h1 className=" text-size16 py-3 f-bold max-md:hidden w-fit">
              {t("Follow Us")}
            </h1>
            <Link
              to="https://www.pinterest.com/vividvisionsprint/"
              className="md:w-fit flex items-center gap-2 md:hover:text-PrimaryColor d-300 md:text-size16 md:py-3 max-md:p-3 r-12 bg-[#d3d3d3] hover:bg-[#c2c1c1] max-md:leading-[42px]"
            >
              <FaPinterestP />
            </Link>
            <Link
              to="https://www.instagram.com/vividvisionsprint?igsh=MXEzbDVuenA2eXV1NA=="
              className="md:w-fit flex items-center gap-2 md:hover:text-PrimaryColor d-300 md:text-size16 md:py-3 max-md:p-3 r-12 bg-[#d3d3d3] hover:bg-[#c2c1c1] max-md:leading-[42px]"
            >
              <FaInstagram />
            </Link>
            <Link
              to="https://www.facebook.com/share/17ofKoPE7g/?mibextid=LQQJ4d"
              className="md:w-fit flex items-center gap-2 md:hover:text-PrimaryColor d-300 md:text-size16 md:py-3 max-md:p-3 r-12 bg-[#d3d3d3] hover:bg-[#c2c1c1] max-md:leading-[42px]"
            >
              <FaFacebookF />
            </Link>
            <Link
              to="https://www.tiktok.com/@vividvisionsprint?_t=8qDBs4yZ4mz&_r=1"
              className="md:w-fit flex items-center gap-2 md:hover:text-PrimaryColor d-300 md:text-size16 md:py-3 max-md:p-3 r-12 bg-[#d3d3d3] hover:bg-[#c2c1c1] max-md:leading-[42px]"
            >
              <img
                className="w-[20px] h-[20px] max-md:w-[25px] max-md:h-[25px]"
                src={tictokLogo}
                alt=""
              />
              <span className="max-md:hidden">{t("Tiktok")}</span>
            </Link>
          </div> */}
          <div className="flex md:flex-col max-md:justify-center max-md:items-center">
            <h1 className="text-size16 py-3 f-bold max-md:hidden w-fit">
              {t("Contact Us")}
            </h1>
            <Link
              to="mailto:info@vividvisionsprint.com"
              className="md:w-fit flex items-center gap-2 md:hover:text-PrimaryColor md:d-300 md:text-size16 md:py-3 max-md:leading-[42px]"
            >
              <img className="w-[25px] h-[17px]" src={emailLogo} alt="" />
              info@vividvisionsprint.com
            </Link>
          </div>
        </div>
        <BreakDiv className="my-[10px] md:hidden" />
        <div className="flex md:justify-between items-center max-md:flex-col max-md:gap-5 max-md:py-3">
          <div className="max-w-[125px] max-h-[45px]">
            <img className="w-full h-full o-cover" src={trust} alt="" />
          </div>
          <div className="flex items-center gap-2 max-md:flex-col max-md:gap-5">
            <div className="max-w-[328px] max-h-[25px]">
              <img className="w-full h-full o-cover" src={payWays} alt="" />
            </div>
            {currency && (
              <div
                className={`${
                  theme === "dark"
                    ? "text-DarkMainText bg-[#535353]"
                    : "text-MainText bg-white shadow-md"
                } f-semi-bold text-size14 py-2 px-4 rounded-xl w-fit flex justify-center items-center`}
              >
                {currency}
              </div>
            )}
          </div>
        </div>
        <BreakDiv className="my-[10px] md:hidden" />
        <div className="flex items-center justify-between pb-4 max-lg:grid max-lg:grid-cols-2 max-md:grid-cols-1 max-md:gap-4 max-md:text-center">
          <div className="flex items-center md:gap-3 max-md:flex-col">
            <Link
              to="/terms-of-service"
              className={`${
                theme === "dark" && "text-DarkMainText/70"
              } text-size12 leading-[33px]`}
            >
              {t("Terms of Service")}
            </Link>
          </div>
          <div className="text-[#958289] text-size14 leading-[18px]">
            {t(
              `© ${t("All rights reserved")} 2023-${currentYear} Vivid Visions`
            )}
          </div>
          <div
            onClick={() => setLanguageOpen(true)}
            className={`${
              theme === "dark"
                ? "text-DarkMainText bg-[#535353] hover:bg-[#414141]"
                : "text-MainText bg-white  hover:bg-[#d1d1d1] shadow-md"
            } d-300 flex items-center justify-between mt-2 mb-6 py-2 px-4 c-pointer rounded-xl hover:bg-[#EDEDED] w-[150px] max-md:mx-auto`}
          >
            <h1>{lng}</h1>
            <img
              onClick={() => setLanguageOpen(true)}
              className="w-[28px] h-[20px] rounded-sm c-pointer"
              src={
                lng === "Arabic"
                  ? saudiArabiaFlag
                  : lng === "English"
                  ? americaFlag
                  : germanyFlag
              }
              alt=""
            />
          </div>
        </div>
      </div>
      <SelectLanguage />
    </>
  );
}

export default Footer;
