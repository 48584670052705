import React, { useContext } from "react";
import { PopupContext } from "../../Context/PopupContext";
import { MdLanguage } from "react-icons/md";
import MainButton from "../../Shared/Components/MainButton";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import Popup from "../../Shared/Components/Popup";

function PrivacySettings() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { privacySettingsOpen, setPrivacySettingsOpen, setLanguageOpen } =
    useContext(PopupContext);
  const setPrivacyShowed = () => {
    setPrivacySettingsOpen(false);
    localStorage.setItem("privacyShowed", true);
  };
  return (
    <div
      className={`${
        theme === "dark" ? "bg-DarkMainBg" : "bg-white"
      } languageBoxShadow w-full z0 flex flex-col sticky bottom-0 left-0 text-MainText d-300`}
    >
      <div
        className={`${
          theme === "dark" ? "text-DarkMainText" : "text-MainText"
        } pt-[24px] px-[24px] pb-[12px] max-md:p-3`}
      >
        <div
          className={`flex justify-between items-center mb-[8px] font-[700] text-size18 leading-[14px]`}
        >
          <h1
            className={`text-size18 max-md:text-size14 font-[700] leading-[18px]`}
          >
            {t("Information Protection Settings")}
          </h1>
          <MdLanguage
            onClick={() => setLanguageOpen(true)}
            className="c-pointer"
            size={25}
          />
        </div>
        <div className={`py-[4px] leading-[1.25] bm-[8px] max-md:text-size12`}>
          {t(
            "Our website utilizes external website tracking tools to enrich and customize our services, while also serving advertising goals. The activation of optional cookies relies on your consent, which you are free to revoke at any time"
          )}
        </div>
      </div>
      <div className="px-[24px] max-md:px-3 flex items-center gap-3">
        <button
          onClick={() => {
            setPrivacyShowed();
          }}
          className={`${
            theme === "dark"
              ? "bg-MainBg hover:bg-[#eae0e4]"
              : "bg-[#e8e8e8] hover:bg-[#d0d0d0]"
          } min-w-[280px] max-w-[280px] max-md:min-w-[125px] max-md:max-w-[180px] max-md:text-size14 f-bold r-12 text-PrimaryColor d-300 c-pointer rounded-[4px] py-[12px] px-[16px] mb-[24px] max-md:mb-2 max-md:py-2 max-md:px-3 flex justify-center items-center`}
        >
          {t("Refuse")}
        </button>
        <MainButton
          content={`${t("Agree")}`}
          className="min-w-[280px] max-w-[280px] max-md:min-w-[125px] max-md:max-w-[180px] max-md:text-size14 r-12 py-[12px] px-[16px] mb-[24px] max-md:mb-2 max-md:py-2 max-md:px-3"
          onClick={() => setPrivacyShowed()}
        />
      </div>
    </div>
  );
}

export default PrivacySettings;
