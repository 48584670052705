import React from "react";

function OfferTimerItem({ value, text }) {
  return (
    <div className="flex flex-col min-w-[62px] max-vsm:min-w-[49px] items-center p-2 rounded-[20px] bg-PrimaryColor t-white">
      <span className="font-[700] border-b-[2px] border-b-[#777] text-size20 max-vsm:text-size16">
        {value}
      </span>
      <span className="uppercase text-size14 max-vsm:text-size10">{text}</span>
    </div>
  );
}

export default OfferTimerItem;
