import { useTheme } from "../../Context/ThemeContext";
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import React, { useEffect } from "react";
import Cookies from "js-cookie";
import ScrollTopButton from "../Components/ScrollTopButton";
import { useTranslation } from "react-i18next";

function TermsAndConditions() {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const curiousToken = Cookies.get("curiousToken");
  const userToken = Cookies.get("userToken");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <ScrollTopButton />
      <Navbar
        sticky={true}
        hideSidebar={true}
        hideLapNavItems={curiousToken || userToken ? false : true}
        className="mb-[0px]"
      />
      <div
        className={`${
          theme === "dark"
            ? "text-DarkMainText bg-DarkMainBg"
            : "text-MainText bg-MainBg"
        } flex flex-col xl:max-w-[950px] w-full mx-auto h-full max-xl:p-[25px]`}
      >
        <h1 className="text-size18 leading-[23px] font-[700] xl:my-[55px] xl:text-[42px] xl:leading-[54px]">
          {t("Vivid Visions Terms Of Service")}
        </h1>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] underline">
          {t("Dispute Resolution and Arbitration")}:
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] ">
          {t(
            "All disputes, claims, or controversies arising from or relating to your use of Vivid Visions services or products must be settled through binding arbitration. This means that disputes will not be resolved in court, and neither party may bring or participate in any class, collective, or representative action. Arbitration will be conducted under the rules of the American Arbitration Association (AAA), and both parties agree to waive the right to a trial by jury."
          )}
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] underline">
          {t("Image Ownership and Use")}:
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] ">
          {t(
            "You retain full ownership of all images uploaded to the Vivid Visions platform. Vivid Visions will not use, sell, or share your images for any purposes beyond printing and delivering the products you ordered. By using our services, you grant Vivid Visions a limited, non-exclusive right to use your images solely for fulfilling your order. This includes activities necessary to print and deliver the products, such as image processing and quality control."
          )}
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] underline">
          {t("App Permissions")}:
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] ">
          {t(
            "Vivid Visions requires access to your device’s photo gallery in order to allow you to select, crop, and order images through our app. This permission is necessary to facilitate the functionality of our services. You may revoke this access at any time by adjusting your device settings. However, disabling this access may limit your ability to use certain features of the app."
          )}
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] underline">
          {t("Data Deletion and Privacy")}:
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] ">
          {t(
            "We respect your privacy and offer the ability to delete your personal data and images from our systems upon request. To initiate a data deletion, simply send us an email from the address you used when placing your order. We will ensure that all associated data is removed from our servers. Our privacy practices comply with relevant data protection laws, including the General Data Protection Regulation (GDPR) for users located in the European Union."
          )}
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] underline">
          {t("Content Restrictions")}:
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] ">
          {t(
            "You are responsible for ensuring that the images you upload are your own and do not contain prohibited content, such as pornography, discriminatory material, or any content that infringes upon third-party intellectual property rights. Vivid Visions reserves the right to refuse to print or remove any content that violates these terms. If any legal disputes arise from the content you upload, you will be responsible for any related legal costs or claims."
          )}
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] underline">
          {t("Support and Issue Resolution")}:
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] ">
          {t(
            "If you encounter any issues with your order or the products you receive, we encourage you to contact our support team via email or WhatsApp. Our team is dedicated to resolving issues promptly, and we will work closely with you to ensure any problems are addressed efficiently. Response times may vary depending on the nature of your inquiry, but we strive to provide effective support for all customer concerns."
          )}
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] underline">
          {t("Use of Services and Compliance")}:
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] ">
          {t(
            "By using Vivid Visions services, you agree to comply with all applicable laws and regulations. You are also responsible for ensuring that the content you upload does not violate any intellectual property rights, privacy rights, or applicable laws. We may modify these terms at any time, and it is your responsibility to stay informed about any changes. Continued use of our services after such changes will constitute your acceptance of the revised terms."
          )}
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] underline">
          {t("Account Security and Responsibility")}:
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] ">
          {t(
            "You are solely responsible for maintaining the security of your account. This includes ensuring that your account credentials are kept confidential and that your account information remains up to date. Any actions taken through your account are your responsibility, and you are liable for any misuse, including unauthorized access. If you suspect that your account has been compromised, you must notify Vivid Visions immediately to prevent further unauthorized use."
          )}
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] underline">
          {t("Subscriptions and Payments")}:
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] ">
          {t(
            "If you subscribe to Vivid Visions services, your subscription will automatically renew at the end of each billing cycle unless you cancel it before the renewal date. Subscription fees will be charged to the payment method you provided during registration. We do not offer refunds for partial subscription periods, but we will provide refunds in cases of product errors or damage. If you wish to cancel your subscription, please contact our support team before the renewal date to avoid further charges."
          )}
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] underline">
          {t("Refunds and Returns")}:
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] ">
          {t(
            "Vivid Visions offers refunds or replacements if the product you receive is damaged, defective, or does not meet the specifications of your order. In such cases, please contact our support team and provide relevant details, including photos of the product if necessary. We do not offer refunds if you change your mind after placing an order or if there are user errors during the ordering process, unless otherwise required by applicable law."
          )}
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] underline">
          {t("Third-Party Links and Services")}:
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] ">
          {t(
            "Vivid Visions may provide links to third-party websites or services as part of our app functionality. Please note that we are not responsible for the content, privacy policies, or practices of these third-party sites. If you choose to engage with third-party services through our app, you do so at your own risk. We encourage you to review the terms and privacy policies of any third-party services you access through Vivid Visions."
          )}
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] underline">
          {t("Limitation of Liability")}:
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] ">
          {t(
            "Vivid Visions will not be held liable for any indirect, incidental, special, or consequential damages arising from the use of our services, products, or any content you upload. This includes, but is not limited to, damages related to loss of data, personal injury, or issues with product quality. Our liability is limited to the amount you paid for the services in the 12 months preceding your claim. This limitation does not apply in cases of gross negligence or willful misconduct on our part."
          )}
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] underline">
          {t("GDPR and Privacy Compliance")}:
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] ">
          {t(
            "For users in the European Union, Vivid Visions fully complies with the General Data Protection Regulation (GDPR). We are committed to protecting your personal data and providing transparency in how it is collected, used, and stored. You have the right to access, modify, or delete your personal data at any time. Images uploaded but not printed will be automatically deleted from our systems after 30 days."
          )}
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] underline">
          {t("Intellectual Property")}:
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] ">
          {t(
            "You retain full intellectual property rights to the images you upload. However, by using our services, you grant Vivid Visions a limited, non-exclusive, royalty-free license to use, reproduce, and print your images for the purpose of fulfilling your order. Vivid Visions owns all intellectual property rights related to the app and services, including trademarks, service marks, and content, unless otherwise stated."
          )}
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] underline">
          {t("Account Termination")}:
        </p>
        <p className="text-size16 leading-[21px] my-[16px] xl:text-size18 xl:leading-[25px] xl:my-[18px] pb-5">
          {t(
            "Vivid Visions reserves the right to suspend or terminate your account at any time if you breach these terms or engage in fraudulent, abusive, or illegal behavior. Account suspension or termination does not affect your obligations under these terms, including indemnification, liability, and payment responsibilities."
          )}
        </p>
        <Footer />
      </div>
    </div>
  );
}

export default TermsAndConditions;
