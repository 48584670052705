import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../../../Context/ThemeContext";
import MainButton from "../../../../Shared/Components/MainButton";
import imgMob from "../../../../assets/imgs/ArtHome/mainMob.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/controller";
import "swiper/css/effect-fade";
import { PhotoContext } from "../../../../Context/PhotoContext";
import { useParams } from "react-router-dom";

function LandingArt() {
  const { id } = useParams();
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { logNavigation } = useContext(PhotoContext);
  const [selectedPhotos, setSelectedPhotos] = useState(null);
  const originalHomePictures = {
    pictures: [
      "https://backend.tecrek.com/public/Photos/cro3.png",
      "https://backend.tecrek.com/public/Photos/cr.jpg",
      "https://backend.tecrek.com/public/Photos/va.jpg",
      "https://backend.tecrek.com/public/Photos/la.jpg",
      "https://backend.tecrek.com/public/Photos/aa.jpg",
      "https://backend.tecrek.com/public/Photos/ur.jpg",
      "https://backend.tecrek.com/public/Photos/mo.jpg",
      "https://backend.tecrek.com/public/Photos/ha.jpg",
    ],
    picturesMobile: [
      "https://backend.tecrek.com/public/Photos/cro3M.png",
      "https://backend.tecrek.com/public/Photos/art1.jpg",
      "https://backend.tecrek.com/public/Photos/art2.jpg",
      "https://backend.tecrek.com/public/Photos/art3.jpg",
      "https://backend.tecrek.com/public/Photos/art4.jpg",
      "https://backend.tecrek.com/public/Photos/art5.jpg",
      "https://backend.tecrek.com/public/Photos/art6.jpg",
      // imgMob,
      // "https://backend.tecrek.com/public/Photos/art1.jpg",
      // "https://backend.tecrek.com/public/Photos/110.jpg",
      // "https://backend.tecrek.com/public/Photos/50.jpg",
    ],
  };

  const homePicturesByCategory = [
    {
      id: 1,
      name: "Vintage Animals",
      pictures: [
        "https://backend.tecrek.com/public/Photos/va.jpg",
        "https://backend.tecrek.com/public/Photos/va.jpg",
      ],
      picturesMobile: [
        "https://backend.tecrek.com/public/Photos/cro3M.png",
        "https://backend.tecrek.com/public/Photos/art2.jpg",
        "https://backend.tecrek.com/public/Photos/blackV1.jpeg",
        "https://backend.tecrek.com/public/Photos/180.jpg",
        "https://backend.tecrek.com/public/Photos/110.jpg",
        imgMob,
      ],
    },
    {
      id: 6,
      name: "Christmas",
      pictures: [
        "https://backend.tecrek.com/public/Photos/cro3.png",
        "https://backend.tecrek.com/public/Photos/cr.jpg",
        "https://backend.tecrek.com/public/Photos/cro3.png",
        "https://backend.tecrek.com/public/Photos/cr.jpg",
      ],
      picturesMobile: [
        "https://backend.tecrek.com/public/Photos/cro3M.png",
        "https://backend.tecrek.com/public/Photos/art1.jpg",
        "https://backend.tecrek.com/public/Photos/blackC.jpeg",
        "https://backend.tecrek.com/public/Photos/180.jpg",
        "https://backend.tecrek.com/public/Photos/110.jpg",
        imgMob,
      ],
    },
    {
      id: 2,
      name: "Animals in Bath",
      pictures: [
        "https://backend.tecrek.com/public/Photos/aa.jpg",
        "https://backend.tecrek.com/public/Photos/aa.jpg",
      ],
      picturesMobile: [
        "https://backend.tecrek.com/public/Photos/cro3M.png",
        "https://backend.tecrek.com/public/Photos/art6.jpg",
        "https://backend.tecrek.com/public/Photos/blackV.jpeg",
        "https://backend.tecrek.com/public/Photos/180.jpg",
        "https://backend.tecrek.com/public/Photos/110.jpg",
        imgMob,
      ],
    },
    {
      id: 3,
      name: "Baby Animals",
      pictures: [
        "https://backend.tecrek.com/public/Photos/aa.jpg",
        "https://backend.tecrek.com/public/Photos/aa.jpg",
      ],
      picturesMobile: [
        "https://backend.tecrek.com/public/Photos/cro3M.png",
        "https://backend.tecrek.com/public/Photos/blackV1.jpeg",
        "https://backend.tecrek.com/public/Photos/180.jpg",
        "https://backend.tecrek.com/public/Photos/110.jpg",
        imgMob,
      ],
    },
    {
      id: 4,
      name: "Landscape and Scenery",
      pictures: [
        "https://backend.tecrek.com/public/Photos/la.jpg",
        "https://backend.tecrek.com/public/Photos/la.jpg",
      ],
      picturesMobile: [
        "https://backend.tecrek.com/public/Photos/cro3M.png",
        "https://backend.tecrek.com/public/Photos/art3.jpg",
        "https://backend.tecrek.com/public/Photos/blackL.jpeg",
        "https://backend.tecrek.com/public/Photos/blackL1.jpeg",
        "https://backend.tecrek.com/public/Photos/180.jpg",
        "https://backend.tecrek.com/public/Photos/110.jpg",
        imgMob,
      ],
    },
    {
      id: 7,
      name: "Modern Animals",
      pictures: [
        "https://backend.tecrek.com/public/Photos/mo.jpg",
        "https://backend.tecrek.com/public/Photos/mo.jpg",
      ],
      picturesMobile: [
        "https://backend.tecrek.com/public/Photos/cro3M.png",
        "https://backend.tecrek.com/public/Photos/art4.jpg",
        "https://backend.tecrek.com/public/Photos/blackM.jpeg",
        "https://backend.tecrek.com/public/Photos/180.jpg",
        "https://backend.tecrek.com/public/Photos/110.jpg",
        imgMob,
      ],
    },
    {
      id: 8,
      name: "Urban Blooms",
      pictures: [
        "https://backend.tecrek.com/public/Photos/ur.jpg",
        "https://backend.tecrek.com/public/Photos/ur.jpg",
      ],
      picturesMobile: [
        "https://backend.tecrek.com/public/Photos/cro3M.png",
        "https://backend.tecrek.com/public/Photos/art5.jpg",
        "https://backend.tecrek.com/public/Photos/blackU.jpeg",
        "https://backend.tecrek.com/public/Photos/blackU1.jpeg",
        "https://backend.tecrek.com/public/Photos/180.jpg",
        "https://backend.tecrek.com/public/Photos/110.jpg",
        imgMob,
      ],
    },
    {
      id: 5,
      name: "Halloween",
      pictures: [
        "https://backend.tecrek.com/public/Photos/ha.jpg",
        "https://backend.tecrek.com/public/Photos/ha.jpg",
      ],
      picturesMobile: [
        "https://backend.tecrek.com/public/Photos/cro3M.png",
        "https://backend.tecrek.com/public/Photos/180.jpg",
        "https://backend.tecrek.com/public/Photos/110.jpg",
        imgMob,
      ],
    },
  ];
  useEffect(() => {
    if (id) {
      const selectedCategory = homePicturesByCategory.filter(
        (item) => parseFloat(item.id) === parseFloat(id)
      );
      // console.log("the selectedCategory is : ", selectedCategory);
      setSelectedPhotos(selectedCategory[0]);
    } else {
      setSelectedPhotos(originalHomePictures);
    }
  }, []);

  return (
    <div className={`${theme === "dark" && "text-DarkMainText"} mb-[20px]`}>
      <Swiper
        modules={[Autoplay, EffectFade]}
        slidesPerView={1}
        loop
        autoplay
        effect="fade"
        speed={500}
      >
        {selectedPhotos !== null &&
          selectedPhotos.pictures.length > 0 &&
          selectedPhotos.pictures.map((pic, index) => (
            <SwiperSlide key={index}>
              <div className="flex justify-center items-center relative min-w-full aspect-video max-h-[600px] bg-black max-md:hidden">
                <div className="flex flex-col items-center gap-5 z10 t-white">
                  <div className="flex flex-col items-center gap-2">
                    <div className="text-size14">
                      {t("Award-Winning Gallery Art")}
                    </div>
                    <div className="text-[30px] leading-[1.2] tracking-[3px]">
                      {t("Art For All Seasons, Style For Every Reason.")}
                    </div>
                  </div>
                  <MainButton
                    onClick={() => logNavigation("/art-photos")}
                    link={`/art-photos`}
                    content={t("SHOP NOW")}
                    className={`w-fit mx-auto font-[700] py-[14px] px-[28px]`}
                  />
                </div>
                <img
                  className="w-full h-full o-cover absolute left-0 top-0 opacity-70"
                  src={pic}
                  alt=""
                />
              </div>
            </SwiperSlide>
          ))}
      </Swiper>

      <div className="flex flex-col items-center gap-5 max-md:mt-[0px] md:hidden shadow-xl">
        <div className="w-full overflow-hidden">
          <Swiper
            modules={[Autoplay, EffectFade]}
            slidesPerView={1}
            loop
            autoplay
            effect="fade"
            speed={500}
          >
            {selectedPhotos !== null &&
              selectedPhotos.picturesMobile.length > 0 &&
              selectedPhotos.picturesMobile.map((picture, index) => (
                <SwiperSlide key={index}>
                  <div className="h-[480px] overflow-hidden flex justify-center items-center relative">
                    {/* Centered Box */}
                    <div className="flex flex-col items-center gap-3 p-5 bg-black/50 rounded-lg text-center text-DarkMainText z-10 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                      <div className="flex flex-col items-center gap-2 w-fit">
                        <div className="text-size20 whitespace-nowrap">
                          {t("Award-Winning Gallery Art")}
                        </div>
                        {/* <div className="text-size18 leading-[1.2] tracking-[3px]">
                          {t("Art For All Seasons, Style For Every Reason.")}
                        </div> */}
                      </div>
                      <MainButton
                        onClick={() => logNavigation("/art-photos")}
                        link={`/art-photos`}
                        content={t("SHOP NOW")}
                        className={`w-fit mx-auto text-size14 font-[700] py-[14px] px-[28px]`}
                      />
                    </div>
                    <img
                      className="w-full h-full o-cover"
                      src={picture}
                      alt=""
                    />
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default LandingArt;
