import React, { useContext, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { PopupContext } from "../../Context/PopupContext";
import { useTranslation } from "react-i18next";
import { useTheme } from "../../Context/ThemeContext";
import ProcessSpinner from "../../Shared/Components/ProcessSpinner";
import { postDiscountCode } from "../../Api/Editphotos";
import Popup from "../../Shared/Components/Popup";
import { toastSuccess } from "../../Shared/Components/ToastNotification/Toast";

function InputPromoCode() {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const [promoCode, setPromoCode] = useState("");
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const { inputPromoCodeOpen, setPromoCodeOpen, setInputPromoCodeOpen } =
    useContext(PopupContext);
  const savePromoCode = async (e) => {
    e.preventDefault();
    try {
      setSpinnerLoad(true);
      const response = await postDiscountCode(promoCode);
      // console.log(response);
      if (response.success === "true") {
        toastSuccess(response.message);
        setSpinnerLoad(false);
        setPromoCode("");
      }
    } catch (error) {
      console.error("post discount code error", error);
    } finally {
      setInputPromoCodeOpen(false);
    }
  };
  return (
    <Popup
      toggleSwitch={inputPromoCodeOpen}
      children={
        <form
          onSubmit={savePromoCode}
          className={`${
            theme === "dark"
              ? "bg-DarkMainBg text-DarkMainText"
              : "text-MainText bg-MainBg"
          } rounded-[20px] max-md:rounded-none md:languageBoxShadow md:w-[460px] flex flex-col max-md:h-screen`}
        >
          <div
            className={`${
              theme === "dark" ? "bg-DarkMainBg" : "md:bg-MainBg"
            } flex justify-between items-center px-[12px] border-b border-b-[#ebe7e7] font-[700] text-size18 leading-[14px] h-[56px] md:sticky md:top-0 md:left-0`}
          >
            <div
              className={`${
                theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
              } p-[5px] rounded-full d-300`}
            >
              <AiOutlineClose
                className="c-pointer"
                onClick={() => {
                  setInputPromoCodeOpen(false);
                }}
                size={26}
              />
            </div>
            <h1
              className={`${
                theme === "dark" ? "text-DarkMainText" : "text-MainText"
              } md:py-[27px]`}
            >
              {t("Discount Code")}
            </h1>
            <button
              type="submit"
              className="flex justify-center items-center min-w-[93px] h-[52px] text-size18 c-pointer font-[700] text-PrimaryColor hover:underline d-300"
            >
              {spinnerLoad ? <ProcessSpinner /> : t("Completed")}
            </button>
          </div>
          <div className="lg:min-w-[400px] lg:min-h-[70px] pt-[25px] pb-[40px] overflow-y-auto">
            <div className="flex flex-col px-[15px] mx-[10px] mb-[5px]">
              <h1
                className={`${
                  theme === "dark" ? "text-DarkMainText/70" : "text-SecondText"
                } text-size15 leading-[17px] mb-[6px]`}
              >
                {t("Your Code")}
              </h1>
              <input
                placeholder={t("Enter Here")}
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                className={`${
                  theme === "dark"
                    ? "bg-DarkBoxBg text-DarkMainText border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                    : "caret-[#958289] focus:border-MainText border-[#f2e9e9] hover:border-[#b5a7ac66] placeholder:text-[#958289]"
                } w-full min-w-[325px] h-[60px] border-2 d-300 rounded-[8px] placeholder:font-[500] placeholder:text-size16 px-[24px] py-[21px] text-size18 no-outline focus:ring-0`}
              />
            </div>
          </div>
        </form>
      }
      overLayClassName="z40"
      className={`z50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] d-300 max-w-[769px] max-md:w-full max-md:h-screen rounded-[20px] overflow-hidden max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
      onClose={() => setInputPromoCodeOpen(false)}
    />
  );
}

export default InputPromoCode;
