import { PhotoContext } from "../../../Context/PhotoContext";
import BreakDiv from "../../../Shared/Components/BreakDiv";
import Container from "../../../Shared/Components/Container";
import Loading from "../../../Shared/Components/Loading";
import Features from "./Sections/Features";
import Landing from "./Sections/Landing";
import StickyFrame from "./Sections/StickyFrame";
import Footer from "../../Components/Footer";
import Navbar from "../../Components/Navbar";
import { useContext, useEffect, useRef } from "react";
import { useTheme } from "../../../Context/ThemeContext";
import ScrollTopButton from "../../Components/ScrollTopButton";
import { whoVisitWebsite } from "../../../Api/api";
import PrivacySettings from "../../Components/PrivacySettings";
import LandingGallery from "./Sections/LandingGallery";
import SelectLanguage from "../../Components/SelectLanguage";
import MainButton from "../../../Shared/Components/MainButton";
import { useTranslation } from "react-i18next";
import ReviewsSection from "./Sections/ReviewsSection";
import OfferTimer from "../../Components/OfferTimer";
import offerPic from "../../../assets/imgs/Offer/sitePc.jpg";
import offerPicMob from "../../../assets/imgs/Offer/siteMobile.jpg";
import brands from "../../../assets/imgs/home brands.png";

function Home() {
  const { theme } = useTheme();
  const userId = localStorage.getItem("userId");
  const { t } = useTranslation();
  const { loading, lazyLoading, totalPhotos, logNavigation } =
    useContext(PhotoContext);
  const vividVisions = async () => {
    try {
      const response = await whoVisitWebsite();
    } catch (error) {
      console.error("vividVisions err : ", error);
    }
  };
  const privacyShowed = localStorage.getItem("privacyShowed");

  // const desktopVideoRef = useRef();
  const mobileVideoRef = useRef();

  useEffect(() => {
    logNavigation("/home");
    lazyLoading();
    !privacyShowed && vividVisions();
    // window.pintrk &&
    //   window.pintrk("track", "homePagevisit", {
    //     event_id: "eventId0005",
    //     em: userId,
    //   });
  }, []);

  return (
    <div className="flex flex-col justify-center items-center">
      {loading ? (
        <Loading />
      ) : (
        <div
          className={`${theme === "dark"
              ? "text-DarkMainText bg-DarkMainBg"
              : "text-MainText"
            } z0 w-full`}
        >
          <Navbar sticky={loading ? false : true} />
          <OfferTimer />
          <div className="max-md:hidden">
            <img
              className="w-full h-full o-cover"
              src={offerPic}
              alt="offerImgDesktop"
            />
          </div>
          <div className="md:hidden">
            <img
              className="w-full h-full o-cover"
              src={offerPicMob}
              alt="offerimgMobile"
            />
          </div>
          <Container>
            <Landing />
            <BreakDiv className="my-[40px] " />
            <div className="max-w-[700px] mx-auto">
              <img className="w-full h-full object-cover" src={brands} alt="" />
            </div>
            <BreakDiv className="my-[40px] md:hidden" />

            {/* <div className="desktop max-md:hidden mx-auto w-fit">
              <video
                ref={desktopVideoRef}
                loop
                muted
                playsInline
                src="https://d2pkj4iuzqk8im.cloudfront.net/videos/homepage/c_scale,w_650--q_auto:best--vc_h264:high:auto--v1--assets--homepage--2023--videos--phone.mp4"
              ></video>
            </div> */}
            <div className="md:hidden text-center">
              <h1 className="f-bold text-[50px] md:leading-[4rem] mb-6 max-md:mb-4 max-xl:f-bold max-xl:text-[45px] max-xl:leading-none 2xl:max-w-[500px] max-md:text-[22px]">
                {t("Create a Photo Wall in a Few Taps")}
              </h1>
              <div className="max-md:mb-4 max-md:flex max-md:flex-col max-md:items-center">
                <p className="text-size18 max-md:text-size14 mb-6 max-md:mb-4 f-semi-bold ">
                  {t("Pick the perfect style for your home")}
                </p>
              </div>
            </div>
            <div className="mobile md:hidden my-4 border-[10px] border-black rounded-[20px] overflow-hidden max-w-[300px] max-h-[600px] mx-auto">
              <video
                ref={mobileVideoRef}
                loop
                muted
                playsInline
                autoPlay
                src="https://backend.tecrek.com/public/Photos/mobile2.mp4"
              ></video>
            </div>
            <BreakDiv className="my-[40px]" />
            <StickyFrame />
            <BreakDiv className="my-[40px]" />

            {/* <div className="flex justify-between max-md:flex-col max-md:gap-3 max-md:mt-[40px] max-md:text-center">
              <div className="flex flex-col my-auto gap-5">
                <div className="text-[36px] font-[600] leading-[50px] max-md:hidden">
                  {t("Hanging Photos")},
                  <br />
                  {t("Reinvented")}
                </div>
                <div className="flex flex-col gap-3 max-md:gap-2">
                  <div className="md:pl-5 md:border-l-2 md:border-l-PrimaryColor text-[26px] max-md:text-[24px] leading-[36px] font-[500]">
                    {t("No hammers and nails")}
                  </div>
                  <div className="md:pl-5 md:border-l-2 md:border-l-PrimaryColor text-[26px] max-md:text-[24px] leading-[36px] font-[500]">
                    {t("Adjust to perfection")}
                  </div>
                  <div className="md:pl-5 md:border-l-2 md:border-l-PrimaryColor text-[26px] max-md:text-[24px] leading-[36px] font-[500]">
                    {t("Leaves no marks")}
                  </div>
                </div>
              </div>
              <div className="aspect-video shadow-photoItemShadow md:w-[600px]">
                <video
                  ref={videoRef}
                  className="w-full h-full rounded-[6px] o-cover"
                  loop
                  muted
                  playsInline
                  src={vid}
                ></video>
              </div>
            </div> */}
            <LandingGallery />
            <BreakDiv className="my-[40px] max-md:hidden" />
            <Features />
            <BreakDiv className="my-[40px]" />
            <ReviewsSection homeMode={true} />
            <BreakDiv className="my-[40px]" />
            <Footer />
            <ScrollTopButton />
          </Container>
          <div
            className={`${theme === "dark" ? "bg-DarkMainBg" : "bg-white"
              } sticky left-0 bottom-0 z0 w-full mx-auto py-[12px] max-md: md:hidden`}
          >
            <MainButton
              link={"/edit-photos"}
              content={
                totalPhotos && totalPhotos > 0
                  ? `${t("Continue Design")} (${totalPhotos})`
                  : t("Start Design")
              }
              className="w-[95%] m-auto flex justify-center items-center min-w-[209px] h-[52px] text-size18 r-12"
            />
          </div>
        </div>
      )}
      {!privacyShowed && <PrivacySettings />}
      <SelectLanguage />
    </div>
  );
}

export default Home;
