import React, { useEffect } from "react";
import { useTheme } from "../../Context/ThemeContext";
import { payment } from "../../Api/Paymentdetails";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

function PaymentDetailsCallBack() {
  const { t } = useTranslation();
  // const paymentId = window.localStorage.getItem("paymentId");
  // console.log("payment id is : ", paymentId);
  const { orderNumber } = useParams();
  const navigate = useNavigate();
  const paymentIntent = new URLSearchParams(window.location.search).get(
    "payment_intent"
  );
  const paymentProcess = async () => {
    try {
      const response = await payment(paymentIntent);
      // console.log(response);
      if (response.success === "true") {
        navigate(`/thanks`);
        localStorage.removeItem("giftNote");
        localStorage.removeItem("paymentId");
        window.location.reload();
      }
    } catch (err) {
      console.error("payment err", err);
    }
  };
  useEffect(() => {
    paymentProcess();
    localStorage.setItem("orderNumber", orderNumber);
  }, [orderNumber]);
  const { theme } = useTheme();
  return (
    <div
      className={`${
        theme === "dark" ? "text-DarkMainText bg-DarkMainBg" : "text-MainText"
      } h-screen flex justify-center items-center text-[24px]`}
    >
      {t("Loading")} ...
    </div>
  );
}

export default PaymentDetailsCallBack;
