import Cookies from "js-cookie";
import React, { useState } from "react";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import ProcessSpinner from "../../Shared/Components/ProcessSpinner";
import { login } from "../../Api/Dashboard";
import { useTheme } from "../../Context/ThemeContext";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import {
  toastError,
  toastSuccess,
} from "../../Shared/Components/ToastNotification/Toast";
import { useTranslation } from "react-i18next";

function Admin() {
  const { t } = useTranslation();
  const { theme, setTheme } = useTheme();
  const [adminEmail, setAdminEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (adminEmail === "" || password === "") {
        toastError(t("email and password are required"));
      } else {
        setLoading(true);
        const loginData = await login(adminEmail, password);
        // console.log(loginData);
        if (loginData.status === "success") {
          Cookies.set("adminToken", loginData.data.token);
          toastSuccess(t("Login completed"));
          navigate("/dashboard");
        }
      }
    } catch (error) {
      console.error(error);
      toastError(error);
    }
    setLoading(false);
  };

  return (
    <div
      style={{ direction: "ltr" }}
      className={`${
        theme === "dark" ? "bg-DarkMainBg" : "bg-MainBg"
      } h-screen flex justify-center items-center relative`}
    >
      {/* <div
        onClick={() => {
          theme === "light" ? setTheme("dark") : setTheme("light");
        }}
        className={`${theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
          } absolute top-5 right-5 p-[5px] c-pointer rounded-full`}
      >
        {theme === "dark" ? (
          <MdLightMode size={20} />
        ) : (
          <MdDarkMode size={20} />
        )}
      </div> */}
      <div
        className={`${
          theme === "dark"
            ? "bg-DarkBoxBg shadow-white shadow-registerWayBoxShadow"
            : "bg-white shadow-xl"
        } r-12 py-[20px] px-[36px] w-[402px] m-auto overflow-hidden`}
      >
        <h1 className="text-center my-[15px] font-[700] text-[25px] leading-[32px]">
          Admin Login
        </h1>
        <form onSubmit={handleSubmit} className="flex flex-col gap-2">
          <input
            className={`${
              theme === "dark"
                ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
            } w-full mb-3 h-[46px] rounded-[8px] text-size16 font-[500] border-2 outline-none py-[21px] px-[19px] focus:border-2 focus:ring-0 d-300 c-pointer`}
            type="text"
            placeholder="Please input your email ."
            value={adminEmail}
            onChange={(e) => setAdminEmail(e.target.value)}
          />
          <div className="relative ">
            <input
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } w-full h-[46px] rounded-[8px] text-size16 font-[500] border-2 outline-none py-[21px] px-[19px] focus:border-2 focus:ring-0 d-300 c-pointer`}
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <IoEye
              onClick={() => setShowPassword(true)}
              className={`${
                showPassword ? "hidden" : ""
              } absolute c-pointer right-4 top-[50%] -translate-y-[50%]`}
            />
            <IoEyeOff
              onClick={() => setShowPassword(false)}
              className={`${
                showPassword ? "" : "hidden"
              } absolute c-pointer right-4 top-[50%] -translate-y-[50%]`}
            />
          </div>
          <button
            className={`${
              loading ? "opacity-30 cursor-default pointer-events-none" : ""
            } flex justify-center items-center h-[46px] w-full min-w-[209px]  text-size20 text-[#fff] font-[700] outline-none r-12 bg-PrimaryColor hover:bg-HoverPrimaryColor my-[10px] d-300`}
            type="submit"
          >
            {loading ? <ProcessSpinner /> : "Log In"}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Admin;
