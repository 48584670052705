import React from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { useTheme } from "../../Context/ThemeContext";

function SearchInput({ query, setQuery, search, loading, placeHolder }) {
  const { theme } = useTheme();

  return (
    <div className="w-full relative md:max-w-[500px]">
      <input
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        className={`${
          theme === "dark"
            ? "text-DarkMainText bg-DarkBoxBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
            : "bg-[#fff] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
        } pl-5 pr-[58px] py-[7.5px] w-full outline-none focus:ring-0 border d-300 rounded-[20px] c-pointer min-h-[38.34px]`}
        placeholder={placeHolder}
      />
      <div
        onClick={() => {
          !loading && search();
        }}
        className={`right-0 rounded-r-[20px] absolute c-pointer top-[50%] -translate-y-[50%] text-size13 text-MainText bg-[#e2e2e2] hover:bg-[#c4c4c4] d-300 h-full p-2 flex justify-center items-center`}
      >
        {loading ? (
          <AiOutlineLoading3Quarters className="animate-spin" />
        ) : (
          <FaSearch />
        )}
      </div>
    </div>
  );
}

export default SearchInput;
