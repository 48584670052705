import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  English: {
    translation: {
      // FirstPage
      "Let's initiate your journey with Vivid Visions":
        "Let's initiate your journey with Vivid Visions",
      "Turn your image into budget-friendly, breathtaking wall art.":
        "Turn your image into budget-friendly, breathtaking wall art.",
      "Let's begin!": "Let's begin!",
      // PrivacySettings
      "Information Protection Settings": "Information Protection Settings",
      "Our website utilizes external website tracking tools to enrich and customize our services, while also serving advertising goals. The activation of optional cookies relies on your consent, which you are free to revoke at any time":
        "Our website utilizes external website tracking tools to enrich and customize our services, while also serving advertising goals. The activation of optional cookies relies on your consent, which you are free to revoke at any time",
      Refuse: "Refuse",
      Agree: "Agree",
      // NameRegister
      "Please enter more than 3 characters":
        "Please enter more than 3 characters",
      "Let's start! What's your identity?":
        "Let's start! What's your identity?",
      "What are you called?": "What are you called?",
      Next: "Next",
      Prev: "Prev",
      // EmailRegister
      "Name should be more than 3 characters": "Name should be more than 3 characters",
      "Please enter a valid email": "Please enter a valid email",
      "Please consent to the terms below": "Please consent to the terms below",
      "Some thing was wrong": "Some thing was wrong",
      Hello: "Hello",
      "Your email address, please.": "Your email address, please.",
      "Of course, I'd be interested in receiving information and updates on new offers.":
        "Of course, I'd be interested in receiving information and updates on new offers.",
      "By sending, you acknowledge the terms.":
        "By sending, you acknowledge the terms.",
      "Terms of service": "Terms of service",
      "&": "&",
      "Confidentiality agreement": "Confidentiality agreement",
      Okay: "Okay",
      // Home
      // Landing
      "Turn your photos into stunning wall art.": "Turn your photos into stunning wall art.",
      "Create beautiful walls filled with memories using your favorite photos.":
        "Create beautiful walls filled with memories using your favorite photos.",
      "Free worldwide shipping!": "Free worldwide shipping!",
      "Start Design": "Start Design",
      "let's begin.": "let's begin.",
      // Features
      "No nails needed!": "No nails needed!",
      "Our frames stick to any type of wall.":
        "Our frames stick to any type of wall.",
      "Delivered to your door in just 7 days.":
        "Delivered to your door in just 7 days.",
      "Your satisfaction is guaranteed.": "Your satisfaction is guaranteed.",
      "Not satisfied? Get a full refund within 30 days, no questions asked.":
        "Not satisfied? Get a full refund within 30 days, no questions asked.",
      // StickyFrame
      "The magic frame that sticks to any surface.":
        "The magic frame that sticks to any surface.",
      "Get your photos in stylish frames that attach directly to your wall, leaving no damage.":
        "Get your photos in stylish frames that attach directly to your wall, leaving no damage.",
      "limited-edition art—only 10 pieces available per artwork.": "limited-edition art—only 10 pieces available per artwork.",
      "Each exclusive masterpiece offers a unique, stunning visual experience.": "Each exclusive masterpiece offers a unique, stunning visual experience.",
      // Reviews
      "Customer Reviews": "Customer Reviews",
      "Your feedback drives us – your happiness is our goal!":
        "Your feedback drives us – your happiness is our goal!",
      "You're likely familiar with my discussions about these photos, and I must say, they're truly unparalleled":
        "You're likely familiar with my discussions about these photos, and I must say, they're truly unparalleled",
      // sidebar
      Register: "Register",
      "to save your progress and monitor your orders.":
        "to save your progress and monitor your orders.",
      "Login": "Login",
      "Start Design": "Start Design",
      "Continue Design": "Continue Design",
      "Home": "Home",
      "Art Gallery": "Art Gallery",
      "Purchase a Gift Card": "Purchase a Gift Card",
      "Discount Codes": "Discount Codes",
      "My Orders": "My Orders",
      "FAQs": "FAQs",
      "Terms of Service": "Terms of Service",
      Logout: "Logout",
      // LogInAndSignUp
      "Password should be more than 6 characters":
        "Password should be more than 6 characters",
      "Password and password confirmation are not matched":
        "Password and password confirmation are not matched",
      "Email and password can not be empty":
        "Email and password can not be empty",
      successful: "successful",
      "Email has already been taken": "Email has already been taken",
      "Email or password is invalid": "Email or password is invalid",
      Login: "Login",
      "Enter Your Name": "Enter Your Name",
      "Enter Your Email": "Enter Your Email",
      "Enter Password": "Enter Password",
      "Enter Password Confirmation": "Enter Password Confirmation",
      or: "or",
      "sign in with Google": "sign in with Google",
      "You are already have an account ?": "You are already have an account ?",
      "You do not have an account ?": "You do not have an account ?",
      "and": "and",
      // Footer
      About: "About",
      "Follow Us": "Follow Us",
      "Contact Us": "Contact Us",
      "Pinterest": "Pinterest",
      Instagram: "Instagram",
      Facebook: "Facebook",
      Tictok: "Tictok",
      "All rights reserved":
        "All rights reserved",
      // SelectLanguage
      "Choose Language": "Choose Language",
      English: "English",
      German: "Deutsch",
      Arabic: "العربية",
      // PromoCode
      "Discount Code": "Discount Code",
      Done: "Done",
      "Quantity Discount Applied": "Quantity Discount Applied",
      "Discount depends on size & material":
        "Discount depends on size & material",
      "Hello (default)": "Hello (default)",
      "Save up to 45%": "Save up to 45%",
      "Enter Discount Code or Apply Gift Card":
        "Enter Discount Code or Apply Gift Card",
      "Your Code": "Your Code",
      "Enter Here": "Enter Here",
      // ProcessOfAddPhoto
      "What would you like to include?": "What would you like to include?",
      "Photo Tiles": "Photo Tiles",
      "Add Photos": "Add Photos",
      // EditPhotos
      "Upload Your Photo": "Upload Your Photo",
      // EditPhotos and EditOnePhoto
      Material: "Material",
      Size: "Size",
      Impact: "Impact",
      Mat: "Mat",
      // MaterialList
      "Choose Material": "Choose Material",
      "Black Frame": "Black Frame",
      "A design that is enduring and embodies classic elegance":
        "A design that is enduring and embodies classic elegance",
      Canvas: "Canvas",
      "Cotton canvas that enhances any space":
        "Cotton canvas that enhances any space",
      // SizeList
      "Choose Size": "Choose Size",
      // EffectList
      "Choose Effect": "Choose Effect",
      Authentic: "Authentic",
      Silver: "Silver",
      Noir: "Noir",
      Vivid: "Vivid",
      Dramatic: "Dramatic",
      // MaterialList SizeList EffectList MatList
      Completed: "Completed",
      // EditOnePhoto
      "delete photo": "delete photo",
      "Low Resolution Photo": "Low Resolution Photo",
      "Replace": "Replace",
      "Edit Tile": "Edit Tile",
      Delete: "Delete",
      // CropPhoto
      "Switch to Rectangular": "Switch to Rectangular",
      "Switch to Square": "Switch to Square",
      "Switch to Horizontal": "Switch to Horizontal",
      "Switch to Vertical": "Switch to Vertical",
      "Crop and Save": "Crop and Save",
      // ArtHome
      // LandingArt
      Exquisite: "Exquisite",
      "Art in frames": "Art in frames",
      "For everyone.": "For everyone.",
      "Begin now.": "Begin now.",
      // CheckList
      "Art is": "Art is",
      "The frame": "The frame",
      "Complimentary shipping to every state in the USA":
        "Complimentary shipping to every state in the USA",
      // Artists
      "Crafted by the most esteemed artists globally.":
        "Crafted by the most esteemed artists globally.",
      "With every purchase, we contribute to supporting the artists,":
        "With every purchase, we contribute to supporting the artists,",
      "They maintain complete ownership of the artworks.":
        "They maintain complete ownership of the artworks.",
      // ArtPhotos
      "Sorted by": "Sorted by",
      Latest: "Latest",
      "Higher Price": "Higher Price",
      "Lower Price": "Lower Price",
      "Higher Rating": "Higher Rating",
      "Lower Rating": "Lower Rating",
      "Best Seller": "Best Seller",
      "Show All Photos": "Show All Photos",
      // FAQsList
      "What exactly is Vivid Visions ?": "What exactly is Vivid Visions ?",
      "Introducing Vivid Visions —your hassle-free solution for converting your digital images into ready-to-display photo tiles for your walls. Explore a variety of designs to complement your home decor perfectly. And here's the kicker: Our adhesive backing ensures zero wall damage, giving you the flexibility to rearrange your photo tiles at your convenience, as often as you desire.":
        "Introducing Vivid Visions —your hassle-free solution for converting your digital images into ready-to-display photo tiles for your walls. Explore a variety of designs to complement your home decor perfectly. And here's the kicker: Our adhesive backing ensures zero wall damage, giving you the flexibility to rearrange your photo tiles at your convenience, as often as you desire.",
      "Sounds intriguing! How can I go about making a purchase?":
        "Sounds intriguing! How can I go about making a purchase?",
      "If you're here, you've successfully landed on our website! To proceed with your order, simply navigate to the menu icon in the top corner and select (Continue Design) Once there, upload your images, pick your preferred photos style, and leave the rest to us!":
        "If you're here, you've successfully landed on our website! To proceed with your order, simply navigate to the menu icon in the top corner and select (Continue Design) Once there, upload your images, pick your preferred photos style, and leave the rest to us!",
      "This is thrilling!, Please share all the details about your tiles with me!":
        "This is thrilling!, Please share all the details about your tiles with me!",
      'We offer an array of sizes and designs, allowing you to mix and match until you discover the ideal fit for your home\'s aesthetic. from from 8.4" x 8.4" to a stunning 27" x 36", We\'ve got all sizes available. Select from black, white, wood-toned frames, frameless designs, or opt for our authentic Canvas option. Additionally, we provide a range of mat shapes, sizes, and colors, along with various photo effects and collage options! Just follow the steps outlined above to start personalizing your order!':
        'We offer an array of sizes and designs, allowing you to mix and match until you discover the ideal fit for your home\'s aesthetic. from from 8.4" x 8.4" to a stunning 27" x 36", We\'ve got all sizes available. Select from black, white, wood-toned frames, frameless designs, or opt for our authentic Canvas option. Additionally, we provide a range of mat shapes, sizes, and colors, along with various photo effects and collage options! Just follow the steps outlined above to start personalizing your order!',
      "Can you explain how your promotional codes function?":
        "Can you explain how your promotional codes function?",
      "Excellent inquiry! The amount you save with our promotional codes varies based on your selection of size and material. However, you'll always be able to view your savings as you proceed through the ordering process and again on the Checkout page before confirming your purchase.":
        "Excellent inquiry! The amount you save with our promotional codes varies based on your selection of size and material. However, you'll always be able to view your savings as you proceed through the ordering process and again on the Checkout page before confirming your purchase.",
      "Oh, and if you haven’t subscribed to our newsletter yet, now's the perfect time to do so! Simply head over to www.Vivid Visions.com/getstarted to sign up. That’s where we exclusively share our top-notch offers and discounts.":
        "Oh, and if you haven’t subscribed to our newsletter yet, now's the perfect time to do so! Simply head over to www.Vivid Visions.com/getstarted to sign up. That’s where we exclusively share our top-notch offers and discounts.",
      "Uh oh! I've already placed my order and I need to make some changes.":
        "Uh oh! I've already placed my order and I need to make some changes.",
      "Wonderful question! The amount you'll save varies depending on the size and material you select. However, you'll always be able to view your savings as you progress through the ordering process and again on the Checkout page before completing your purchase.":
        "Wonderful question! The amount you'll save varies depending on the size and material you select. However, you'll always be able to view your savings as you progress through the ordering process and again on the Checkout page before completing your purchase.",
      'For app orders, simply navigate to "My Orders" by tapping the three lines in the upper corner. Once there, you can conveniently make adjustments such as editing or replacing photos, changing the style, or updating the delivery address!':
        'For app orders, simply navigate to "My Orders" by tapping the three lines in the upper corner. Once there, you can conveniently make adjustments such as editing or replacing photos, changing the style, or updating the delivery address!',
      "For web orders, if you need to modify your delivery address, simply log in or create a Vivid Visions account using the same email address associated with your order. As long as we haven't begun processing it, you'll have the flexibility to make the necessary changes.":
        "For web orders, if you need to modify your delivery address, simply log in or create a Vivid Visions account using the same email address associated with your order. As long as we haven't begun processing it, you'll have the flexibility to make the necessary changes.",
      "If you require assistance with anything else or if you've missed the opportunity to make changes, don't fret! Simply click on the three lines once more and select \"Chat With Us,\" or feel free to send us an email at hi@Vivid Visions .com. We're always available to help with any additional inquiries or requests you may have!":
        "If you require assistance with anything else or if you've missed the opportunity to make changes, don't fret! Simply click on the three lines once more and select \"Chat With Us,\" or feel free to send us an email at hi@Vivid Visions .com. We're always available to help with any additional inquiries or requests you may have!",
      "Now that I have my Vivid Visions , what comes next?":
        "Now that I have my Vivid Visions , what comes next?",
      "Your Vivid Visions photos have arrived – how exciting! Before anything else, examine the back to determine if it has a single sticky strip adhesive or a magnetic square adhesive. Once done, plan your layout and proceed with the following steps:":
        "Your Vivid Visions photos have arrived – how exciting! Before anything else, examine the back to determine if it has a single sticky strip adhesive or a magnetic square adhesive. Once done, plan your layout and proceed with the following steps:",
      "Single Sticky Adhesive Strip: First, grab a dry cloth and give the wall a quick wipe. Once you're ready, peel off that protective sheet from the sticky and apply pressure evenly around the frame.":
        "Single Sticky Adhesive Strip: First, grab a dry cloth and give the wall a quick wipe. Once you're ready, peel off that protective sheet from the sticky and apply pressure evenly around the frame.",
      "Magnetic Adhesives: Begin by removing the protective white sheet. Next, position the tile on the desired spot on the wall and firmly press the magnet into place.":
        "Magnetic Adhesives: Begin by removing the protective white sheet. Next, position the tile on the desired spot on the wall and firmly press the magnet into place.",
      "Now, simply relish those cherished memories displayed on your walls! If you ever find yourself needing to relocate your Brintbent photos, just safeguard the adhesive part with standard kitchen wax paper, and they'll easily adhere to their new location.":
        "Now, simply relish those cherished memories displayed on your walls! If you ever find yourself needing to relocate your Brintbent photos, just safeguard the adhesive part with standard kitchen wax paper, and they'll easily adhere to their new location.",
      "What should I do if my tiles don't match my expectations?":
        "What should I do if my tiles don't match my expectations?",
      "We offer a Happiness Guarantee to support our product. If you're not completely satisfied with your purchase for any reason, please don't hesitate to contact us. Your happiness is our top priority, and we are committed to ensuring your satisfaction!":
        "We offer a Happiness Guarantee to support our product. If you're not completely satisfied with your purchase for any reason, please don't hesitate to contact us. Your happiness is our top priority, and we are committed to ensuring your satisfaction!",
      "Tell me about your shipping.": "Tell me about your shipping.",
      "We generally provide complimentary worldwide shipping! Occasionally, there may be a nominal shipping fee for smaller orders, which will be clearly indicated on the Checkout page prior to finalizing your purchase. Additionally, in certain countries, we offer expedited shipping for those who require their orders to arrive more quickly. Should express shipping be an option for your designated delivery address, you'll notice the choice presented on the Checkout page, accompanied by the corresponding fee for this expedited service.":
        "We generally provide complimentary worldwide shipping! Occasionally, there may be a nominal shipping fee for smaller orders, which will be clearly indicated on the Checkout page prior to finalizing your purchase. Additionally, in certain countries, we offer expedited shipping for those who require their orders to arrive more quickly. Should express shipping be an option for your designated delivery address, you'll notice the choice presented on the Checkout page, accompanied by the corresponding fee for this expedited service.",
      "And for our Canadian customers, rest assured that the import fee displayed on the Checkout screen is simply a substitution for your local sales tax. This means no unexpected charges for you!":
        "And for our Canadian customers, rest assured that the import fee displayed on the Checkout screen is simply a substitution for your local sales tax. This means no unexpected charges for you!",
      "How can I keep track of my order's progress?":
        "How can I keep track of my order's progress?",
      "No problem at all! We'll ensure you're informed about every step of your order's progress through email notifications. You'll receive an email once your order begins production, and another once it's dispatched for delivery. Plus, the tracking link provided will keep you updated on the whereabouts of your package at all times!":
        "No problem at all! We'll ensure you're informed about every step of your order's progress through email notifications. You'll receive an email once your order begins production, and another once it's dispatched for delivery. Plus, the tracking link provided will keep you updated on the whereabouts of your package at all times!",
      "Is it possible to present Vivid Visions as a gift?":
        "Is it possible to present Vivid Visions as a gift?",
      'Certainly! Through our gift cards, the recipient has the freedom to select their preferred photos and craft their unique Vivid Visions . To access the Buy a Gift Card feature, simply tap on the three horizontal lines. It\'s worth noting: our gift cards are exclusively tailored for our. 8.4" x 8.4" size only.':
        'Certainly! Through our gift cards, the recipient has the freedom to select their preferred photos and craft their unique Vivid Visions . To access the Buy a Gift Card feature, simply tap on the three horizontal lines. It\'s worth noting: our gift cards are exclusively tailored for our. 8.4" x 8.4" size only.',
      "size only.": "",
      "If you have particular images in mind already, no problem! We provide free worldwide shipping, allowing you to send the Vivid Visions directly to your loved one for a delightful surprise!":
        "If you have particular images in mind already, no problem! We provide free worldwide shipping, allowing you to send the Vivid Visions directly to your loved one for a delightful surprise!",
      "Do you have any additional inquiries?":
        "Do you have any additional inquiries?",
      "Visit our": "Visit our",
      "Support Center": "Support Center",
      // my orders
      "It looks like you haven't placed any orders yet.":
        "It looks like you haven't placed any orders yet.",
      "Select some pictures": "Select some pictures",
      Orders: "Orders",
      "Order ID": "Order ID",
      Status: "Status",
      "Earliest Delivery Date": "Earliest Delivery Date",
      "Latest Delivery Date": "Latest Delivery Date",
      Items: "Items",
      "Details": "Details",
      Note: "Note",
      Price: "Price",
      Total: "Total",
      "The gift note": "The gift note",
      "Login is necessary": "Login is necessary",
      "To view your orders, please login or register.":
        "To view your orders, please login or register.",
      "Register / Log In": "Register / Log In",
      // payment details
      "Payment Details": "Payment Details",
      "Pay Now": "Pay Now",
      "Discount Total": "Discount Total",
      "Total Before Discount": "Total Before Discount",
      "The special note": "The special note",
      // Add address
      "Add Address": "Add Address",
      Done: "Done",
      Email: "Email",
      "Your Email": "Your Email",
      "Full name": "Full name",
      "First and last name": "First and last name",
      Country: "Country",
      "Select Country": "Select Country",
      Select: "Select",
      "Province": "Province",
      "State": "State",
      "Town": "Town",
      "Select Region": "Select Region",
      City: "City",
      "Your City": "Your City",
      "Address line": "Address line",
      "Street address": "Street address",
      "Additional address": "Additional address",
      "Zip Code": "Zip Code",
      "Postal Code": "Postal Code",
      "Phone number": "Phone number",
      "Your phone number will only be used if we need to reach you regarding delivery.":
        "Your phone number will only be used if we need to reach you regarding delivery.",
      // make decision
      "Are you sure you want to": "Are you sure you want to",
      "delete": "delete",
      "photo item": "photo item",
      this: "this",
      Cancel: "Cancel",
      Ok: "OK",
      // login with code
      "We'll send the code in a matter of seconds":
        "We'll send the code in a matter of seconds",
      Continue: "Continue",
      "Log I or Register": "Log In or Register",
      "Christmas Offer: Log in for an extra 5% off!": "Christmas Offer: Log in for an extra 5% off!",
      "Please input your email .": "Please input your email .",
      or: "or",
      "By registering, you consent to our":
        "By registering, you consent to our",
      "Verification code has been sent to your email":
        "Verification code has been sent to your email",
      "Payment Checkout": "Payment Checkout",
      "Submit Payment": "Submit Payment",
      "Go back": "Go back",
      "An email containing the code has been dispatched to you:":
        "An email containing the code has been dispatched to you:",
      "Please input the code": "Please input the code",
      "Send Code Again": "Send Code Again",
      Loading: "Loading",
      // CheckoutForm
      Submit: "Submit",
      // PhotoDetails
      "Add photo to cart": "Add photo to cart",
      "The picture has been added to your cart":
        "The picture has been added to your cart",
      "Regular": "Regular",
      "Limited Release": "Limited Release",
      "Only": "Only",
      "Left": "Left",
      "Items sold in the last 24 hours": "Items sold in the last 24 hours",
      From: "From",
      Rating: "Rating",
      Type: "Type",
      "Select type": "Select type",
      "Select size": "Select size",
      "Add to cart": "Add to cart",
      "Required": "Required",
      "Print & frame specification": "Print & frame specification",
      "Delivery & returns": "Delivery & returns",
      "SHIPPING": "SHIPPING",
      "Destination": "Destination",
      "Delivery time": "Delivery time",
      "These times are estimated and may be longer, especially during peak periods or holidays. Shipping costs are calculated at checkout. Rates may differ depending on your payment currency": "These times are estimated and may be longer, especially during peak periods or holidays. Shipping costs are calculated at checkout. Rates may differ depending on your payment currency",
      "RETURNS": "RETURNS",
      "My item arrived damaged": "My item arrived damaged",
      "Very rarely items can be damaged in transit, but don't worry, just contact us within 14 days of the item's delivery and we'll send you a free express replacement": "Very rarely items can be damaged in transit, but don't worry, just contact us within 14 days of the item's delivery and we'll send you a free express replacement",
      "I ordered a print or frame, but changed my mind and I want to return it": "I ordered a print or frame, but changed my mind and I want to return it",
      "Don't worry, we get it! Contact us within 14 days of receiving your order and we can discuss your options to return it for a full refund": "Don't worry, we get it! Contact us within 14 days of receiving your order and we can discuss your options to return it for a full refund",
      "Related Photos": "Related Photos",
      "See more": "See more",
      "Sorry, No related photos available": "Sorry, No related photos available",
      Reviews: "Reviews",
      "No feedback available": "No feedback available",
      // checkout
      "Please enter your address details": "Please enter your address details",
      "Please sign in to your account to continue":
        "Please sign in to your account to continue",
      Checkout: "Checkout",
      Modify: "Modify",
      "Attach a special note": "Attach a special note",
      "Enter gift note here": "Enter gift note here",
      "The note has been saved": "The note has been saved",
      Save: "Save",
      "Promo: WELCOME": "Promo: WELCOME",
      Tiles: "Tiles",
      "Tile": "Tile",
      "Add a discount code": "Add a discount code",
      Digits: "Digits",
      "12 tiles available at 99": "12 tiles available at 99",
      "Add 10 tiles to your cart to activate the special offer!":
        "Add 10 tiles to your cart to activate the special offer!",
      Shipping: "Shipping",
      Free: "Free",
      "Confirm & Pay": "Confirm & Pay",
      "Dark Mode": "Dark Mode",
      "Light Mode": "Light Mode",
      // TermsAndConditions
      "Vivid Visions Terms Of Service": "Vivid Visions Terms Of Service",
      "Dispute Resolution and Arbitration": "Dispute Resolution and Arbitration",
      "All disputes, claims, or controversies arising from or relating to your use of Vivid Visions services or products must be settled through binding arbitration. This means that disputes will not be resolved in court, and neither party may bring or participate in any class, collective, or representative action. Arbitration will be conducted under the rules of the American Arbitration Association (AAA), and both parties agree to waive the right to a trial by jury.": "All disputes, claims, or controversies arising from or relating to your use of Vivid Visions services or products must be settled through binding arbitration. This means that disputes will not be resolved in court, and neither party may bring or participate in any class, collective, or representative action. Arbitration will be conducted under the rules of the American Arbitration Association (AAA), and both parties agree to waive the right to a trial by jury.",
      "Image Ownership and Use": "Image Ownership and Use",
      "You retain full ownership of all images uploaded to the Vivid Visions platform. Vivid Visions will not use, sell, or share your images for any purposes beyond printing and delivering the products you ordered. By using our services, you grant Vivid Visions a limited, non-exclusive right to use your images solely for fulfilling your order. This includes activities necessary to print and deliver the products, such as image processing and quality control.": "You retain full ownership of all images uploaded to the Vivid Visions platform. Vivid Visions will not use, sell, or share your images for any purposes beyond printing and delivering the products you ordered. By using our services, you grant Vivid Visions a limited, non-exclusive right to use your images solely for fulfilling your order. This includes activities necessary to print and deliver the products, such as image processing and quality control.",
      "App Permissions": "App Permissions",
      "Vivid Visions requires access to your device’s photo gallery in order to allow you to select, crop, and order images through our app. This permission is necessary to facilitate the functionality of our services. You may revoke this access at any time by adjusting your device settings. However, disabling this access may limit your ability to use certain features of the app.": "Vivid Visions requires access to your device’s photo gallery in order to allow you to select, crop, and order images through our app. This permission is necessary to facilitate the functionality of our services. You may revoke this access at any time by adjusting your device settings. However, disabling this access may limit your ability to use certain features of the app.",
      "Data Deletion and Privacy": "Data Deletion and Privacy",
      "We respect your privacy and offer the ability to delete your personal data and images from our systems upon request. To initiate a data deletion, simply send us an email from the address you used when placing your order. We will ensure that all associated data is removed from our servers. Our privacy practices comply with relevant data protection laws, including the General Data Protection Regulation (GDPR) for users located in the European Union.": "We respect your privacy and offer the ability to delete your personal data and images from our systems upon request. To initiate a data deletion, simply send us an email from the address you used when placing your order. We will ensure that all associated data is removed from our servers. Our privacy practices comply with relevant data protection laws, including the General Data Protection Regulation (GDPR) for users located in the European Union.",
      "Content Restrictions": "Content Restrictions",
      "You are responsible for ensuring that the images you upload are your own and do not contain prohibited content, such as pornography, discriminatory material, or any content that infringes upon third-party intellectual property rights. Vivid Visions reserves the right to refuse to print or remove any content that violates these terms. If any legal disputes arise from the content you upload, you will be responsible for any related legal costs or claims.": "You are responsible for ensuring that the images you upload are your own and do not contain prohibited content, such as pornography, discriminatory material, or any content that infringes upon third-party intellectual property rights. Vivid Visions reserves the right to refuse to print or remove any content that violates these terms. If any legal disputes arise from the content you upload, you will be responsible for any related legal costs or claims.",
      "Support and Issue Resolution": "Support and Issue Resolution",
      "If you encounter any issues with your order or the products you receive, we encourage you to contact our support team via email or WhatsApp. Our team is dedicated to resolving issues promptly, and we will work closely with you to ensure any problems are addressed efficiently. Response times may vary depending on the nature of your inquiry, but we strive to provide effective support for all customer concerns.": "If you encounter any issues with your order or the products you receive, we encourage you to contact our support team via email or WhatsApp. Our team is dedicated to resolving issues promptly, and we will work closely with you to ensure any problems are addressed efficiently. Response times may vary depending on the nature of your inquiry, but we strive to provide effective support for all customer concerns.",
      "Use of Services and Compliance": "Use of Services and Compliance",
      "By using Vivid Visions services, you agree to comply with all applicable laws and regulations. You are also responsible for ensuring that the content you upload does not violate any intellectual property rights, privacy rights, or applicable laws. We may modify these terms at any time, and it is your responsibility to stay informed about any changes. Continued use of our services after such changes will constitute your acceptance of the revised terms.": "By using Vivid Visions services, you agree to comply with all applicable laws and regulations. You are also responsible for ensuring that the content you upload does not violate any intellectual property rights, privacy rights, or applicable laws. We may modify these terms at any time, and it is your responsibility to stay informed about any changes. Continued use of our services after such changes will constitute your acceptance of the revised terms.",
      "Account Security and Responsibility": "Account Security and Responsibility",
      "You are solely responsible for maintaining the security of your account. This includes ensuring that your account credentials are kept confidential and that your account information remains up to date. Any actions taken through your account are your responsibility, and you are liable for any misuse, including unauthorized access. If you suspect that your account has been compromised, you must notify Vivid Visions immediately to prevent further unauthorized use.": "You are solely responsible for maintaining the security of your account. This includes ensuring that your account credentials are kept confidential and that your account information remains up to date. Any actions taken through your account are your responsibility, and you are liable for any misuse, including unauthorized access. If you suspect that your account has been compromised, you must notify Vivid Visions immediately to prevent further unauthorized use.",
      "Subscriptions and Payments": "Subscriptions and Payments",
      "If you subscribe to Vivid Visions services, your subscription will automatically renew at the end of each billing cycle unless you cancel it before the renewal date. Subscription fees will be charged to the payment method you provided during registration. We do not offer refunds for partial subscription periods, but we will provide refunds in cases of product errors or damage. If you wish to cancel your subscription, please contact our support team before the renewal date to avoid further charges.": "If you subscribe to Vivid Visions services, your subscription will automatically renew at the end of each billing cycle unless you cancel it before the renewal date. Subscription fees will be charged to the payment method you provided during registration. We do not offer refunds for partial subscription periods, but we will provide refunds in cases of product errors or damage. If you wish to cancel your subscription, please contact our support team before the renewal date to avoid further charges.",
      "Refunds and Returns": "Refunds and Returns",
      "Vivid Visions offers refunds or replacements if the product you receive is damaged, defective, or does not meet the specifications of your order. In such cases, please contact our support team and provide relevant details, including photos of the product if necessary. We do not offer refunds if you change your mind after placing an order or if there are user errors during the ordering process, unless otherwise required by applicable law.": "Vivid Visions offers refunds or replacements if the product you receive is damaged, defective, or does not meet the specifications of your order. In such cases, please contact our support team and provide relevant details, including photos of the product if necessary. We do not offer refunds if you change your mind after placing an order or if there are user errors during the ordering process, unless otherwise required by applicable law.",
      "Third-Party Links and Services": "Third-Party Links and Services",
      "Vivid Visions may provide links to third-party websites or services as part of our app functionality. Please note that we are not responsible for the content, privacy policies, or practices of these third-party sites. If you choose to engage with third-party services through our app, you do so at your own risk. We encourage you to review the terms and privacy policies of any third-party services you access through Vivid Visions.": "Vivid Visions may provide links to third-party websites or services as part of our app functionality. Please note that we are not responsible for the content, privacy policies, or practices of these third-party sites. If you choose to engage with third-party services through our app, you do so at your own risk. We encourage you to review the terms and privacy policies of any third-party services you access through Vivid Visions.",
      "Limitation of Liability": "Limitation of Liability",
      "Vivid Visions will not be held liable for any indirect, incidental, special, or consequential damages arising from the use of our services, products, or any content you upload. This includes, but is not limited to, damages related to loss of data, personal injury, or issues with product quality. Our liability is limited to the amount you paid for the services in the 12 months preceding your claim. This limitation does not apply in cases of gross negligence or willful misconduct on our part.": "Vivid Visions will not be held liable for any indirect, incidental, special, or consequential damages arising from the use of our services, products, or any content you upload. This includes, but is not limited to, damages related to loss of data, personal injury, or issues with product quality. Our liability is limited to the amount you paid for the services in the 12 months preceding your claim. This limitation does not apply in cases of gross negligence or willful misconduct on our part.",
      "GDPR and Privacy Compliance": "GDPR and Privacy Compliance",
      "For users in the European Union, Vivid Visions fully complies with the General Data Protection Regulation (GDPR). We are committed to protecting your personal data and providing transparency in how it is collected, used, and stored. You have the right to access, modify, or delete your personal data at any time. Images uploaded but not printed will be automatically deleted from our systems after 30 days.": "For users in the European Union, Vivid Visions fully complies with the General Data Protection Regulation (GDPR). We are committed to protecting your personal data and providing transparency in how it is collected, used, and stored. You have the right to access, modify, or delete your personal data at any time. Images uploaded but not printed will be automatically deleted from our systems after 30 days.",
      "Intellectual Property": "Intellectual Property",
      "You retain full intellectual property rights to the images you upload. However, by using our services, you grant Vivid Visions a limited, non-exclusive, royalty-free license to use, reproduce, and print your images for the purpose of fulfilling your order. Vivid Visions owns all intellectual property rights related to the app and services, including trademarks, service marks, and content, unless otherwise stated.": "You retain full intellectual property rights to the images you upload. However, by using our services, you grant Vivid Visions a limited, non-exclusive, royalty-free license to use, reproduce, and print your images for the purpose of fulfilling your order. Vivid Visions owns all intellectual property rights related to the app and services, including trademarks, service marks, and content, unless otherwise stated.",
      "Account Termination": "Account Termination",
      "Vivid Visions reserves the right to suspend or terminate your account at any time if you breach these terms or engage in fraudulent, abusive, or illegal behavior. Account suspension or termination does not affect your obligations under these terms, including indemnification, liability, and payment responsibilities.": "Vivid Visions reserves the right to suspend or terminate your account at any time if you breach these terms or engage in fraudulent, abusive, or illegal behavior. Account suspension or termination does not affect your obligations under these terms, including indemnification, liability, and payment responsibilities.",
      // FAQsList 
      "What is Vivid Visions?": "What is Vivid Visions?",
      "Vivid Visions offers a hassle-free solution for turning your digital images into beautiful photo tiles. Choose from a variety of designs and styles to match your decor, and enjoy the flexibility of adhesive backing, which allows you to rearrange your tiles without damaging the walls.": "Vivid Visions offers a hassle-free solution for turning your digital images into beautiful photo tiles. Choose from a variety of designs and styles to match your decor, and enjoy the flexibility of adhesive backing, which allows you to rearrange your tiles without damaging the walls.",
      "How to Make a Purchase?": "How to Make a Purchase?",
      "Upload your images, pick your preferred style, and proceed to checkout. Vivid Visions provides a user-friendly process for designing and ordering photo tiles.": "Upload your images, pick your preferred style, and proceed to checkout. Vivid Visions provides a user-friendly process for designing and ordering photo tiles.",
      "Details About the Tiles": "Details About the Tiles",
      "Vivid Visions offers various sizes and frame options, including black, white, wood-toned, and frameless, as well as canvas options. You can also customize with mat shapes, colors, and collage effects.": "Vivid Visions offers various sizes and frame options, including black, white, wood-toned, and frameless, as well as canvas options. You can also customize with mat shapes, colors, and collage effects.",
      "Promotional Codes": "Promotional Codes",
      "Discounts vary depending on your selection, and you can see your savings during checkout. Subscribe to the newsletter for exclusive deals.": "Discounts vary depending on your selection, and you can see your savings during checkout. Subscribe to the newsletter for exclusive deals.",
      "Making Changes to Your Order": "Making Changes to Your Order",
      "You can edit or update your order if it hasn't been processed yet. Simply go to \"My Orders\" on the app or website, and make changes to the delivery address, style, or photos.": "You can edit or update your order if it hasn't been processed yet. Simply go to \"My Orders\" on the app or website, and make changes to the delivery address, style, or photos.",
      "What to Do After Receiving Your Vivid Visions": "What to Do After Receiving Your Vivid Visions",
      "Instructions for applying the adhesive photo tiles to your walls, whether using sticky strips or magnetic adhesives.": "Instructions for applying the adhesive photo tiles to your walls, whether using sticky strips or magnetic adhesives.",
      "Not Satisfied?": "Not Satisfied?",
      "Vivid Visions offers a \"Happiness Guarantee.\" If you are not satisfied with your product, you can contact them for support.": "Vivid Visions offers a \"Happiness Guarantee.\" If you are not satisfied with your product, you can contact them for support.",
      "Shipping": "Shipping",
      "Worldwide shipping is available, and sometimes there may be a small fee for smaller orders. Expedited shipping is offered in some countries, and there are no unexpected charges for Canadian customers.": "Worldwide shipping is available, and sometimes there may be a small fee for smaller orders. Expedited shipping is offered in some countries, and there are no unexpected charges for Canadian customers.",
      "Order Tracking": "Order Tracking",
      "You will receive email notifications with updates on your order’s progress, including tracking information.": "You will receive email notifications with updates on your order’s progress, including tracking information.",
      // LandingArt 
      "Award-Winning Gallery Art": "Award-Winning Gallery Art",
      "Art For All Seasons, Style For Every Reason.": "Art For All Seasons, Style For Every Reason.",
      "SHOP NOW": "SHOP NOW",
      // Promo
      "Autumn Sale": "Autumn Sale",
      "20% OFF Everything + 15% EXTRA OFF every second poster - use code: AUTUMN": "20% OFF Everything + 15% EXTRA OFF every second poster - use code: AUTUMN",
      // LandingGallery 
      "Transform your walls with stunning, one-of-a-kind art from our exclusive collection.": "Transform your walls with stunning, one-of-a-kind art from our exclusive collection.",
      "Discover unique masterpieces that will elevate your space into a stunning visual experience.": "Discover unique masterpieces that will elevate your space into a stunning visual experience.",
      "Shop Now": "Shop Now",
      "Browse our collection and bring the beauty of art into your home today!": "Browse our collection and bring the beauty of art into your home today!",
      // PhotoItem; 
      "Out of stack": "Out of stack",
      "Left in stack": "Left in stack",
      "Limited Stock Ended": "Limited Stock Ended",
      "Limited Release": "Limited Release",
      // OfferTimer 
      "black friday prices": "black friday prices",
      "While stock lasts": "While stock lasts",
      // 
      "Create a Photo Wall in a Few Taps": "Create a Photo Wall in a Few Taps",
      "Pick the perfect style for your home":"Pick the perfect style for your home",
    },
  },
  Deutsch: {
    translation: {
      // FirstPage
      "Let's initiate your journey with Vivid Visions":
        "Lass uns deine Reise mit Vivid Visions beginnen",
      "Turn your image into budget-friendly, breathtaking wall art.":
        "Verwandeln Sie Ihr Bild in preisgünstige, atemberaubende Wandkunst.",
      "Let's begin!": "Lass uns anfangen!",
      // PrivacySettings
      "Information Protection Settings": "Einstellungen zum Informationsschutz",
      "Our website utilizes external website tracking tools to enrich and customize our services, while also serving advertising goals. The activation of optional cookies relies on your consent, which you are free to revoke at any time":
        "Unsere Website verwendet externe Website-Tracking-Tools, um unsere Dienste zu verbessern und anzupassen, während sie auch Werbeziele verfolgt. Die Aktivierung optionaler Cookies hängt von Ihrer Zustimmung ab, die Sie jederzeit widerrufen können",
      Refuse: "Ablehnen",
      Agree: "Zustimmen",
      // NameRegister
      "Please enter more than 3 characters":
        "Bitte geben Sie mehr als 3 Zeichen ein",
      "Let's start! What's your identity?":
        "Lassen Sie uns anfangen! Wer sind Sie?",
      "What are you called?": "Wie heißen Sie?",
      Next: "Weiter",
      Prev: "Zurück",
      // EmailRegister
      "Name should be more than 3 characters": "Der Name muss mehr als 3 Zeichen lang sein",
      "Please enter a valid email":
        "Bitte geben Sie eine gültige E-Mail-Adresse ein",
      "Please consent to the terms below":
        "Bitte stimmen Sie den unten stehenden Bedingungen zu.",
      "Some thing was wrong": "Etwas ist schiefgelaufen",
      Hello: "Hallo",
      "Your email address, please.": "Ihre E-Mail-Adresse, bitte.",
      "Of course, I'd be interested in receiving information and updates on new offers.":
        "Natürlich, ich wäre interessiert daran, Informationen und Updates zu neuen Angeboten zu erhalten.",
      "By sending, you acknowledge the terms.": "Durch Absenden stimmen Sie zu",
      "Terms of service": "Nutzungsbedingungen",
      "&": "&",
      "Confidentiality agreement": "Datenschutzrichtlinie",
      Okay: "Okay",
      // Home
      // Landing
      "Turn your photos into stunning wall art.":
        "Mach aus deinen Fotos in stylische Wandkunst.",
      "Create beautiful walls filled with memories using your favorite photos.":
        "Gestalte wunderschöne Wände voller Erinnerungen mit deinen Lieblingsfotos.",
      "Free worldwide shipping!": "Kostenloser weltweiter Versand!",
      "Start Design": "Starte dein Design",
      "let's begin.": "Los geht's",
      // Features
      "No nails needed!": "Keine Nägel nötig!",
      "Our frames stick to any type of wall.":
        "Unsere Rahmen haften auf jeder Wand.",
      "Delivered to your door in just 7 days.":
        "In nur 7 Tagen direkt zu dir nach Hause geliefert.",
      "Your satisfaction is guaranteed.": "Deine Zufriedenheit ist garantiert.",
      "Not satisfied? Get a full refund within 30 days, no questions asked.":
        "Nicht zufrieden? Innerhalb von 30 Tagen volle Rückerstattung, ohne Fragen!",
      // StickyFrame
      "The magic frame that sticks to any surface.":
        "Der erstaunliche Rahmen, der an jeder Oberfläche haftet",
      "Get your photos in stylish frames that attach directly to your wall, leaving no damage.":
        "Erhalten Sie Ihre Bilder in modischen Rahmen, die direkt an Ihrer Wand haften und keinen Schaden verursachen",
      "limited-edition art—only 10 pieces available per artwork.": "Kunst in limitierter Auflage – pro Kunstwerk sind nur 10 Stück verfügbar.",
      "Each exclusive masterpiece offers a unique, stunning visual experience.": "Jedes exklusive Meisterwerk bietet ein einzigartiges, atemberaubendes visuelles Erlebnis.",
      // Reviews
      "Customer Reviews": "Kundenbewertungen",
      "Your feedback drives us – your happiness is our goal!":
        "Euer Feedback treibt uns an – eure Zufriedenheit ist unser Ziel!",
      "You're likely familiar with my discussions about these photos, and I must say, they're truly unparalleled":
        "Sie sind wahrscheinlich mit meinen Diskussionen über diese Fotos vertraut, und ich muss sagen, sie sind wirklich unvergleichlich.",
      // Sidebar
      Register: "Registrieren",
      "to save your progress and monitor your orders.":
        "Um Ihren Fortschritt zu speichern und Bestellungen zu verfolgen",
      "Login": "Anmelden",
      "Start Design": "Design starten",
      "Continue Design": "Weiter gestalten",
      "Home": "Main",
      "Art Gallery": "Kunstgalerie",
      "Purchase a Gift Card": "Geschenkkarte kaufen",
      "Discount Codes": "Rabattcodes",
      "My Orders": "Meine Bestellungen",
      "FAQs": "HGF",
      "Terms of Service": "Nutzungsbedingungen",
      Logout: "Abmelden",
      // LogInAndSignUp
      "Password should be more than 6 characters":
        "Das Passwort sollte mehr als 6 Zeichen lang sein",
      "Password and password confirmation are not matched":
        "Das Passwort und die Passwortbestätigung stimmen nicht überein",
      "Email and password can not be empty":
        "E-Mail und Passwort dürfen nicht leer sein",
      successful: "Erfolgreich",
      "Email has already been taken":
        "Die E-Mail-Adresse wurde bereits verwendet",
      "Email or password is invalid": "E-Mail oder Passwort ist ungültig",
      Login: "Anmelden",
      "Enter Your Name": "Geben Sie Ihren Namen ein",
      "Enter Your Email": "Geben Sie Ihre E-Mail-Adresse ein",
      "Enter Password": "Geben Sie Ihr Passwort ein",
      "Enter Password Confirmation": "Geben Sie die Passwortbestätigung ein",
      or: "oder",
      "sign in with Google": "Anmelden mit Google",
      "You are already have an account ?": "Sie haben bereits ein Konto ?",
      "You do not have an account ?": "Sie haben kein Konto ?",
      "and": "und",
      // Footer
      About: "Über uns",
      "Follow Us": "Folge uns",
      "Contact Us": "Kontaktieren Sie uns",
      "Pinterest": "Pinterest",
      Instagram: "Instagram",
      Facebook: "Facebook",
      Tictok: "Tictok",
      "All rights reserved":
        "Alle Rechte vorbehalten",
      // SelectLanguage
      "Choose Language": "Sprache wählen",
      English: "English",
      German: "Deutsch",
      Arabic: "العربية",
      // PromoCode
      "Discount Code": "Rabattcode",
      Done: "Erledigt",
      "Quantity Discount Applied": "Mengenrabatt angewendet",
      "Discount depends on size & material":
        "Der Rabatt hängt von Größe und Material ab",
      "Hello (default)": "Hallo",
      "Save up to 45%": "Profitieren Sie von Ersparnissen von bis zu 45%",
      "Enter Discount Code or Apply Gift Card":
        "Geben Sie den Rabattcode ein oder wenden Sie die Geschenkkarte an.",
      "Your Code": "Ihr Code",
      "Enter Here": "Hier eingeben",
      // ProcessOfAddPhoto
      "What would you like to include?": "Was möchten Sie hinzufügen?",
      "Photo Tiles": "Fotokacheln",
      "Add Photos": "Fotos hochladen",
      // EditPhotos
      "Upload Your Photo": "Laden Sie Ihr Foto hoch",
      // EditPhotos and EditOnePhoto
      "delete photo": "Foto löschen",
      "Low Resolution Photo": "Niedrige Auflösung",
      "Replace": "Ersetzen",
      Material: "Bildart",
      Size: "Große",
      Impact: "Effekt",
      Mat: "Rand",
      // MaterialList
      "Choose Material": "Wählen Sie das Material aus",
      "Black Frame": "Rahmen",
      "A design that is enduring and embodies classic elegance":
        "Ein Design, das beständig ist und klassische Eleganz verkörpert",
      Canvas: "Leinwand",
      "Cotton canvas that enhances any space":
        "Baumwollleinwand, die jeden Raum verbessert",
      // SizeList
      "Choose Size": "Wählen Sie die Größe aus",
      // EffectList
      "Choose Effect": "Wählen Sie den Effekt aus",
      Authentic: "Authentisch",
      Silver: "Silber",
      Noir: "Noir",
      Vivid: "Lebhaft",
      Dramatic: "Dramatisch",
      // MatList
      "Choose Mat": "Wählen Sie den Mat",
      // MaterialList SizeList EffectList MatList
      Completed: "Erledigt",
      // EditOnePhoto
      "Edit Tile": "Foto bearbeiten",
      Delete: "Löschen",
      // CropPhoto
      "Switch to Rectangular": "Wechseln zu Rechteckig",
      "Switch to Square": "Wechseln Sie zu Square",
      "Switch to Horizontal": "Wechseln zu Horizontal",
      "Switch to Vertical": "Wechseln zu Vertikal",
      "Crop and Save": "Zuschneiden und speichern",
      // ArtHome
      // LandingArt
      Exquisite: "Exquisit",
      "Art in frames": "Gerahmte Kunst",
      "For everyone.": "Für alle",
      "Begin now.": "Beginnen Sie jetzt",
      // CheckList
      "Art is": "Kunst ist",
      "The frame": "Der Rahmen",
      "Complimentary shipping to every state in the USA":
        "Kostenloser Versand in jeden Bundesstaat der USA",
      // Magic
      "Enchantment is part of the": "Verzauberung ist Teil des",
      // MagicList
      "The renowned frame that adheres, and adheres again":
        "Der renommierte Rahmen, der haftet und immer wieder haftet",
      "No nails needed.": "Keine Notwendigkeit für Nägel.",
      "No traces left on your wall.":
        "Keine Spuren auf Ihrer Wand hinterlassen.",
      // Artists
      "Crafted by the most esteemed artists globally.":
        "Von den besten Künstlern der Welt.",
      "With every purchase, we contribute to supporting the artists,":
        "Mit jedem Kauf tragen wir dazu bei, die Künstler zu unterstützen,",
      "They maintain complete ownership of the artworks.":
        "Sie behalten die vollständige Eigentümerschaft an den Kunstwerken.",
      // ArtPhotos
      "Sorted by": "Sortiert nach",
      Latest: "Neueste",
      "Higher Price": "Höherer Preis",
      "Lower Price": "Niedrigerer Preis",
      "Higher Rating": "Höhere Bewertung",
      "Lower Rating": "Niedrigere Bewertung",
      "Best Seller": "Bestseller",
      "Show All Photos": "Alle Fotos anzeigen",
      // FAQsList
      "What exactly is Vivid Visions ?": "Was ist Vivid Visions ?",
      "Introducing Vivid Visions —your hassle-free solution for converting your digital images into ready-to-display photo tiles for your walls. Explore a variety of designs to complement your home decor perfectly. And here's the kicker: Our adhesive backing ensures zero wall damage, giving you the flexibility to rearrange your photo tiles at your convenience, as often as you desire.":
        "Hier ist Vivid Visions - Ihre problemlose Lösung, um Ihre digitalen Bilder in fertige Fotokacheln für Ihre Wände umzuwandeln. Entdecken Sie eine Vielzahl von Designs, die perfekt zu Ihrer Inneneinrichtung passen. Und das Beste daran: Unsere selbstklebende Rückseite garantiert keinerlei Wandbeschädigungen und gibt Ihnen die Flexibilität, Ihre Fotokacheln nach Belieben und so oft Sie möchten neu anzuordnen.",
      "Sounds intriguing! How can I go about making a purchase?":
        "Das klingt spannend! Wie kann ich einen Kauf tätigen?",
      "If you're here, you've successfully landed on our website! To proceed with your order, simply navigate to the menu icon in the top corner and select (Continue Design) Once there, upload your images, pick your preferred photos style, and leave the rest to us!":
        'Wenn Sie hier sind, sind Sie erfolgreich auf unserer Website gelandet! Um mit Ihrer Bestellung fortzufahren, navigieren Sie einfach zum Menüsymbol in der oberen Ecke und wählen Sie "Rahmen Sie Ihre Fotos". Laden Sie dann Ihre Bilder hoch, wählen Sie Ihren bevorzugten Fotostil aus und überlassen Sie den Rest uns!',
      "This is thrilling!, Please share all the details about your tiles with me!":
        "Das ist aufregend! Bitte teilen Sie mir alle Details über Ihre Fliesen mit!",
      'We offer an array of sizes and designs, allowing you to mix and match until you discover the ideal fit for your home\'s aesthetic. from from 8.4" x 8.4" to a stunning 27" x 36", We\'ve got all sizes available. Select from black, white, wood-toned frames, frameless designs, or opt for our authentic Canvas option. Additionally, we provide a range of mat shapes, sizes, and colors, along with various photo effects and collage options! Just follow the steps outlined above to start personalizing your order!':
        'Wir bieten eine Reihe von Größen und Designs an, die Sie kombinieren können, bis Sie die ideale Lösung für die Ästhetik Ihres Zuhauses gefunden haben. Von 8,4" x 8,4" bis zu atemberaubenden 27" x 36", wir haben alle Größen verfügbar. Wählen Sie zwischen schwarzen, weißen, holzfarbenen Rahmen und rahmenlosen Designs oder entscheiden Sie sich für unsere authentische Leinwandoption. Darüber hinaus bieten wir eine Reihe von Mattenformen, -größen und -farben sowie verschiedene Fotoeffekte und Collagenoptionen! Befolgen Sie einfach die oben beschriebenen Schritte, um mit der Personalisierung Ihrer Bestellung zu beginnen!',
      "Can you explain how your promotional codes function?":
        "Können Sie erklären, wie Ihre Aktionscodes funktionieren?",
      "Excellent inquiry! The amount you save with our promotional codes varies based on your selection of size and material. However, you'll always be able to view your savings as you proceed through the ordering process and again on the Checkout page before confirming your purchase.":
        "Ausgezeichnete Frage! Der Betrag, den Sie mit unseren Aktionscodes sparen, variiert je nach Ihrer Auswahl an Größe und Material. Sie werden jedoch immer in der Lage sein, Ihre Ersparnisse zu sehen, während Sie den Bestellvorgang durchlaufen, und erneut auf der Checkout-Seite, bevor Sie Ihren Kauf bestätigen.",
      "Oh, and if you haven’t subscribed to our newsletter yet, now's the perfect time to do so! Simply head over to www.Vivid Visions.com/getstarted to sign up. That’s where we exclusively share our top-notch offers and discounts.":
        "Oh, und wenn Sie unseren Newsletter noch nicht abonniert haben, ist jetzt der perfekte Zeitpunkt dafür! Gehen Sie einfach zu www.Vivid Visions.com/getstarted, um sich anzumelden. Hier teilen wir exklusiv unsere erstklassigen Angebote und Rabatte.",
      "Uh oh! I've already placed my order and I need to make some changes.":
        "Oh oh! Ich habe meine Bestellung bereits aufgegeben und muss einige Änderungen vornehmen.",
      "Wonderful question! The amount you'll save varies depending on the size and material you select. However, you'll always be able to view your savings as you progress through the ordering process and again on the Checkout page before completing your purchase.":
        "Wunderbare Frage! Der Betrag, den Sie sparen werden, variiert je nach der Größe und dem Material, das Sie wählen. Allerdings werden Sie Ihre Ersparnisse immer sehen können, während Sie den Bestellvorgang durchlaufen, und erneut auf der Checkout-Seite, bevor Sie Ihren Kauf abschließen.",
      'For app orders, simply navigate to "My Orders" by tapping the three lines in the upper corner. Once there, you can conveniently make adjustments such as editing or replacing photos, changing the style, or updating the delivery address!':
        'Für App-Bestellungen navigieren Sie einfach zum Bereich "Meine Bestellungen", indem Sie auf die drei Linien in der oberen Ecke tippen. Dort können Sie bequem Anpassungen vornehmen, wie das Bearbeiten oder Ersetzen von Fotos, das Ändern des Stils oder das Aktualisieren der Lieferadresse!',
      "For web orders, if you need to modify your delivery address, simply log in or create a Vivid Visions account using the same email address associated with your order. As long as we haven't begun processing it, you'll have the flexibility to make the necessary changes.":
        "Webbestellungen: Wenn sich Ihre Lieferadresse ändern muss, melden Sie sich einfach an oder erstellen Sie ein Vivid Visions-Konto bei uns mit derselben E-Mail-Adresse, die Sie für Ihre Bestellung verwendet haben. Solange wir noch nicht mit der Bearbeitung begonnen haben, können Sie diese Änderung vornehmen.",
      "If you require assistance with anything else or if you've missed the opportunity to make changes, don't fret! Simply click on the three lines once more and select \"Chat With Us,\" or feel free to send us an email at hi@Vivid Visions .com. We're always available to help with any additional inquiries or requests you may have!":
        'Wenn Sie Hilfe bei etwas anderem benötigen oder wenn Sie die Gelegenheit verpasst haben, Änderungen vorzunehmen, machen Sie sich keine Sorgen! Klicken Sie einfach noch einmal auf die drei Linien und wählen Sie "Chat mit uns" aus, oder senden Sie uns eine E-Mail an hi@Vivid Visions .com. Wir stehen Ihnen jederzeit zur Verfügung, um weitere Anfragen oder Wünsche zu beantworten!',
      "Now that I have my Vivid Visions , what comes next?":
        "Ich habe meinen Vivid Visions erhalten, und jetzt?",
      "Your Vivid Visions photos have arrived – how exciting! Before anything else, examine the back to determine if it has a single sticky strip adhesive or a magnetic square adhesive. Once done, plan your layout and proceed with the following steps:":
        "Ihre Vivid Visions-Fotos sind angekommen - wie aufregend! Bevor Sie etwas anderes tun, überprüfen Sie die Rückseite, um festzustellen, ob sie einen einzelnen Klebestreifen oder ein magnetisches Quadrat enthält. Wenn Sie fertig sind, planen Sie Ihr Layout und fahren Sie mit den folgenden Schritten fort:",
      "Single Sticky Adhesive Strip: First, grab a dry cloth and give the wall a quick wipe. Once you're ready, peel off that protective sheet from the sticky and apply pressure evenly around the frame.":
        "Einzelner Klebestreifen: Reinigen Sie die Wand zunächst mit einem trockenen Tuch. Entfernen Sie anschließend die Schutzschicht vom Kleber und drücken Sie ihn gleichmäßig an den Rahmen.",
      "Magnetic Adhesives: Begin by removing the protective white sheet. Next, position the tile on the desired spot on the wall and firmly press the magnet into place.":
        "Magnetische Klebstoffe: Entfernen Sie zunächst die weiße Schutzfolie. Positionieren Sie anschließend die Fliese an der gewünschten Stelle an der Wand und drücken Sie den Magneten fest an.",
      "Now, simply relish those cherished memories displayed on your walls! If you ever find yourself needing to relocate your Brintbent photos, just safeguard the adhesive part with standard kitchen wax paper, and they'll easily adhere to their new location.":
        "Nun können Sie einfach diese kostbaren Erinnerungen an Ihren Wänden genießen! Wenn Sie jemals Ihre Vivid Visions-Fotos umstellen müssen, schützen Sie einfach den Klebeteil mit herkömmlichem Küchenwachspapier, und sie werden problemlos an ihrem neuen Standort haften.",
      "What should I do if my tiles don't match my expectations?":
        "Was sollte ich tun, wenn meine Fliesen nicht meinen Erwartungen entsprechen?",
      "We offer a Happiness Guarantee to support our product. If you're not completely satisfied with your purchase for any reason, please don't hesitate to contact us. Your happiness is our top priority, and we are committed to ensuring your satisfaction!":
        "Wir bieten eine Zufriedenheitsgarantie, um unser Produkt zu unterstützen. Wenn Sie aus irgendeinem Grund mit Ihrem Kauf nicht vollständig zufrieden sind, zögern Sie bitte nicht, uns zu kontaktieren. Ihre Zufriedenheit hat für uns oberste Priorität, und wir sind darauf bedacht, Ihre Zufriedenheit sicherzustellen!",
      "Tell me about your shipping.":
        "Erzählen Sie mir etwas über Ihren Versand.",
      "We generally provide complimentary worldwide shipping! Occasionally, there may be a nominal shipping fee for smaller orders, which will be clearly indicated on the Checkout page prior to finalizing your purchase. Additionally, in certain countries, we offer expedited shipping for those who require their orders to arrive more quickly. Should express shipping be an option for your designated delivery address, you'll notice the choice presented on the Checkout page, accompanied by the corresponding fee for this expedited service.":
        "In der Regel bieten wir weltweit kostenlosen Versand an! Gelegentlich kann es für kleinere Bestellungen eine geringe Versandgebühr geben, die auf der Checkout-Seite vor Abschluss Ihres Kaufs deutlich angegeben wird. Darüber hinaus bieten wir in bestimmten Ländern einen Expressversand für diejenigen an, die möchten, dass ihre Bestellungen schneller eintreffen. Sollte der Expressversand eine Option für Ihre angegebene Lieferadresse sein, werden Sie auf der Checkout-Seite die entsprechende Auswahlmöglichkeit bemerken, begleitet von der entsprechenden Gebühr für diesen beschleunigten Service.",
      "And for our Canadian customers, rest assured that the import fee displayed on the Checkout screen is simply a substitution for your local sales tax. This means no unexpected charges for you!":
        "Oh, und wenn Sie in Kanada sind, machen Sie sich keine Sorgen über die Importgebühr, die Sie auf dem Checkout-Bildschirm sehen. Sie dient dazu, Ihre lokale Umsatzsteuer zu ersetzen, also keine versteckten Überraschungen für Sie!",
      "How can I keep track of my order's progress?":
        "Wie kann ich den Fortschritt meiner Bestellung verfolgen?",
      "No problem at all! We'll ensure you're informed about every step of your order's progress through email notifications. You'll receive an email once your order begins production, and another once it's dispatched for delivery. Plus, the tracking link provided will keep you updated on the whereabouts of your package at all times!":
        "Kein Problem! Wir werden sicherstellen, dass Sie über jeden Schritt des Fortschritts Ihrer Bestellung durch E-Mail-Benachrichtigungen informiert werden. Sie erhalten eine E-Mail, sobald Ihre Bestellung in Produktion geht, und eine weitere, sobald sie zum Versand abgegeben wird. Außerdem wird der bereitgestellte Tracking-Link Sie jederzeit über den Aufenthaltsort Ihres Pakets auf dem Laufenden halten!",
      "Is it possible to present Vivid Visions as a gift?":
        "Ist es möglich, Vivid Visions als Geschenk zu präsentieren?",
      'Certainly! Through our gift cards, the recipient has the freedom to select their preferred photos and craft their unique Vivid Visions . To access the Buy a Gift Card feature, simply tap on the three horizontal lines. It\'s worth noting: our gift cards are exclusively tailored for our. 8.4" x 8.4" size only.':
        'Natürlich! Durch unsere Geschenkkarten hat der Empfänger die Freiheit, seine bevorzugten Fotos auszuwählen und sein einzigartiges Vivid Visions zu gestalten. Um auf die Funktion zum Kauf einer Geschenkkarte zuzugreifen, tippen Sie einfach auf die drei horizontalen Linien. Es ist erwähnenswert: Unsere Geschenkkarten sind exklusiv für unsere. 8.4" x 8.4" Nur die Größe.',
      "If you have particular images in mind already, no problem! We provide free worldwide shipping, allowing you to send the Vivid Visions directly to your loved one for a delightful surprise!":
        "Wenn Sie bereits bestimmte Bilder im Kopf haben, kein Problem! Wir bieten kostenlosen weltweiten Versand, sodass Sie das Vivid Visions direkt an Ihren Liebsten für eine schöne Überraschung senden können!",
      "Do you have any additional inquiries?": "Haben Sie weitere Fragen?",
      "Visit our": "Besuchen Sie unsere",
      "Support Center": "Hilfecenter",
      // my orders
      "It looks like you haven't placed any orders yet.":
        "Es sieht so aus, als hätten Sie noch keine Bestellungen aufgegeben.",
      "Select some pictures": "Wählen Sie einige Bilder aus.",
      Orders: "Bestellungen",
      "Order ID": "Bestellungs-ID",
      Status: "Zustand",
      "Earliest Delivery Date": "Frühestes Lieferdatum",
      "Latest Delivery Date": "Lieferfrist",
      Items: "Artikel",
      "Details": "Details",
      Note: "Bemerkung",
      Price: "Preis",
      Total: "Gesamt",
      "The gift note": "Das Geschenkaufsteller",
      "Login is necessary": "Ein Login ist erforderlich.",
      "To view your orders, please login or register.":
        "Um Ihre Bestellungen anzusehen, melden Sie sich bitte an oder registrieren Sie sich.",
      "Register / Log In": "Registrieren / Anmelden",
      // payment details
      "Payment Details": "Zahlungsdaten",
      "Pay Now": "Jetzt bezahlen",
      "Discount Total": "Rabatt Gesamt",
      "Total Before Discount": "Gesamtbetrag vor Rabatt",
      "The special note": "Der besondere Hinweis",
      remove: "Entfernen",
      "cart item": "Warenkorb",
      // Add address
      "Add Address": "Adresse eingeben",
      Done: "Fertig",
      Email: "E-Mail",
      "Your Email": "Ihre E-Mail",
      "Full name": "Vollständiger Name",
      "First and last name": "Vor- und Nachname",
      Country: "Land",
      "Select Country": "Wähle ein Land",
      Select: "Wählen",
      "Province": "Provinz",
      "State": "Zustand",
      "Town": "Stadt",
      "Select Region": "Bereich auswählen",
      City: "Stadt",
      "Your City": "Deine Stadt",
      "Address line": "Adresszeile",
      "Street address": "Straßeninformation",
      "Additional address": "Sekundäre Adresse",
      "Zip Code": "Postleitzahl",
      "Postal Code": "PLZ",
      "Phone number": "Handynummer",
      "Your phone number will only be used if we need to reach you regarding delivery.":
        "Ihre Telefonnummer wird nur verwendet, wenn wir Sie bezüglich der Lieferung kontaktieren müssen.",
      // make decision
      "Are you sure you want to": "Sind Sie sicher, dass Sie möchten",
      "delete": "löschen",
      "photo item": "foto",
      this: "dieses",
      Cancel: "Abbrechen",
      Ok: "OK",
      // login with code
      "We'll send the code in a matter of seconds":
        "Wir werden den Code in wenigen Sekunden senden.",
      Continue: "Weitermachen",
      "Log In or Register": "Einloggen oder Registrieren",
      "Christmas Offer: Log in for an extra 5% off!": "Christmas Aktion: Melden Sie sich an und sichern Sie sich 5% Extra-Rabatt!",
      "Please input your email .": "Bitte geben Sie Ihre E-Mail-Adresse ein.",
      or: "oder",
      "By registering, you consent to our":
        "Durch die Registrierung stimmen Sie unseren",
      "Verification code has been sent to your email":
        "Der Bestätigungscode wurde an Ihre E-Mail-Adresse gesendet.",
      "Payment Checkout": "Zahlungsabwicklung",
      "Submit Payment": "Bestätigen Sie die Zahlung",
      "Go back": "Geh zurück",
      "An email containing the code has been dispatched to you:":
        "Eine E-Mail mit dem Code wurde gesendet an",
      "Please input the code": "Bitte geben Sie den Code ein",
      "Send Code Again": "Code erneut senden",
      Loading: "Laden",
      // CheckoutForm
      Submit: "Einreichen",
      // PhotoDetails
      "Add photo to cart": "Foto in den Warenkorb legen",
      "The picture has been added to your cart":
        "Das Bild wurde Ihrem Warenkorb hinzugefügt",
      "Regular": "Regulär",
      "Limited Release": "Begrenzte Veröffentlichung",
      "Only": "Nur",
      "Left": "Links",
      "Items sold in the last 24 hours": "In den letzten 24 Stunden verkaufte Artikel",
      From: "von",
      Rating: "Bewertung",
      Type: "Typ",
      "Select type": "Wählen Sie den Typ",
      "Select size": "Größe auswählen",
      "Add to cart": "In den Warenkorb legen",
      "Required": "Erforderlich",
      "Print & frame specification": "Druck- und Rahmenspezifikation",
      "Delivery & returns": "Lieferung",
      "SHIPPING": "VERSAND",
      "Destination": "Ziel",
      "Delivery time": "Lieferzeit",
      "These times are estimated and may be longer, especially during peak periods or holidays. Shipping costs are calculated at checkout. Rates may differ depending on your payment currency": "Diese Zeiten sind geschätzt und können länger sein, insbesondere während Spitzenzeiten oder an Feiertagen. Die Versandkosten werden an der Kasse berechnet. Die Preise können je nach Zahlungswährung unterschiedlich sein.",
      "RETURNS": "Rückversand",
      "My item arrived damaged": "Mein Artikel ist beschädigt angekommen",
      "Very rarely items can be damaged in transit, but don't worry, just contact us within 14 days of the item's delivery and we'll send you a free express replacement": "In sehr seltenen Fällen kann es beim Transport zu Beschädigungen kommen. Aber keine Sorge, kontaktieren Sie uns einfach innerhalb von 14 Tagen nach Lieferung des Artikels und wir schicken Ihnen einen kostenlosen Express-Ersatz.",
      "I ordered a print or frame, but changed my mind and I want to return it": "Ich habe einen Druck oder Rahmen bestellt, es mir aber anders überlegt und möchte ihn zurückgeben",
      "Don't worry, we get it! Contact us within 14 days of receiving your order and we can discuss your options to return it for a full refund": "Keine Sorge, wir verstehen das! Kontaktieren Sie uns innerhalb von 14 Tagen nach Erhalt Ihrer Bestellung und wir besprechen Ihre Möglichkeiten zur Rückgabe gegen volle Rückerstattung.",
      "Related Photos": "Ähnliche Fotos",
      "See more": "Mehr anzeigen",
      "Sorry, No related photos available": "Leider sind keine entsprechenden Fotos verfügbar",
      Reviews: "Bewertungen",
      "No feedback available": "Kein Feedback verfügbar",
      // checkout
      "Please enter your address details":
        "Bitte geben Sie Ihre Adressdetails ein",
      "Please sign in to your account to continue":
        "Bitte melden Sie sich an, um fortzufahren",
      Checkout: "Zur Kasse gehen",
      Modify: "Ändern",
      "Attach a special note": "Fügen Sie eine besondere Notiz hinzu",
      "Enter gift note here": "Geben Sie hier den Geschenkgutschein ein",
      "The note has been saved": "Die Notiz wurde gespeichert",
      Save: "Speichern",
      "Promo: WELCOME": "Rabatt: WILLKOMMEN",
      Tiles: "Fliesen",
      "Tile": "Porträt",
      "Add a discount code": "Einen Rabattcode hinzufügen",
      Digits: "Digitalis",
      "12 tiles available at 99": " 12 Fliesen für 99 erhältlich",
      "Add 10 tiles to your cart to activate the special offer!":
        "Fügen Sie 10 Fliesen Ihrem Warenkorb hinzu, um das Sonderangebot zu aktivieren!",
      Shipping: "Versand",
      Free: "Kostenlos",
      "Confirm & Pay": "Bestätigen & bezahlen",
      "Dark Mode": "Dunkler Modus",
      "Light Mode": "Lichtmodus",
      // TermsAndConditions
      "Vivid Visions Terms Of Service": "Allgemeine Geschäftsbedingungen von Vivid Visions",
      "Dispute Resolution and Arbitration": "Streitbeilegung und Schiedsverfahren",
      "All disputes, claims, or controversies arising from or relating to your use of Vivid Visions services or products must be settled through binding arbitration. This means that disputes will not be resolved in court, and neither party may bring or participate in any class, collective, or representative action. Arbitration will be conducted under the rules of the American Arbitration Association (AAA), and both parties agree to waive the right to a trial by jury.": "Alle Streitigkeiten, Ansprüche oder Kontroversen, die sich aus oder im Zusammenhang mit Ihrer Nutzung der Dienste oder Produkte von Vivid Visions ergeben, müssen durch ein bindendes Schiedsverfahren beigelegt werden. Dies bedeutet, dass Streitigkeiten nicht vor Gericht beigelegt werden und keine der Parteien eine Sammelklage oder kollektive oder repräsentative Klage einbringen oder daran teilnehmen darf. Das Schiedsverfahren wird gemäß den Regeln der American Arbitration Association (AAA) durchgeführt, und beide Parteien verzichten auf das Recht auf ein Gerichtsverfahren vor einer Jury.",
      "Image Ownership and Use": "Bildrechte und Nutzung",
      "You retain full ownership of all images uploaded to the Vivid Visions platform. Vivid Visions will not use, sell, or share your images for any purposes beyond printing and delivering the products you ordered. By using our services, you grant Vivid Visions a limited, non-exclusive right to use your images solely for fulfilling your order. This includes activities necessary to print and deliver the products, such as image processing and quality control.": "Sie behalten das volle Eigentum an allen Bildern, die auf die Vivid Visions-Plattform hochgeladen werden. Vivid Visions wird Ihre Bilder nicht für andere Zwecke als das Drucken und Liefern der bestellten Produkte verwenden, verkaufen oder teilen. Durch die Nutzung unserer Dienste gewähren Sie Vivid Visions ein begrenztes, nicht-exklusives Recht zur Nutzung Ihrer Bilder ausschließlich zur Erfüllung Ihrer Bestellung. Dies umfasst notwendige Tätigkeiten wie die Bildbearbeitung und Qualitätskontrolle.",
      "App Permissions": "App-Berechtigungen",
      "Vivid Visions requires access to your device’s photo gallery in order to allow you to select, crop, and order images through our app. This permission is necessary to facilitate the functionality of our services. You may revoke this access at any time by adjusting your device settings. However, disabling this access may limit your ability to use certain features of the app.": "Vivid Visions benötigt den Zugriff auf Ihre Fotogalerie, um Ihnen die Auswahl, das Zuschneiden und das Bestellen von Bildern über unsere App zu ermöglichen. Diese Berechtigung ist notwendig, um die Funktionalität unserer Dienste zu gewährleisten. Sie können diesen Zugriff jederzeit durch Ändern der Einstellungen Ihres Geräts widerrufen. Das Deaktivieren des Zugriffs kann jedoch Ihre Nutzung bestimmter Funktionen der App einschränken.",
      "Data Deletion and Privacy": "Datenlöschung und Datenschutz",
      "We respect your privacy and offer the ability to delete your personal data and images from our systems upon request. To initiate a data deletion, simply send us an email from the address you used when placing your order. We will ensure that all associated data is removed from our servers. Our privacy practices comply with relevant data protection laws, including the General Data Protection Regulation (GDPR) for users located in the European Union.": "Wir respektieren Ihre Privatsphäre und bieten Ihnen die Möglichkeit, Ihre persönlichen Daten und Bilder auf Anfrage aus unseren Systemen zu löschen. Um eine Datenlöschung zu initiieren, senden Sie uns einfach eine E-Mail von der Adresse, die Sie bei Ihrer Bestellung verwendet haben. Wir stellen sicher, dass alle zugehörigen Daten von unseren Servern entfernt werden. Unsere Datenschutzpraktiken entsprechen den geltenden Datenschutzgesetzen, einschließlich der Datenschutz-Grundverordnung (DSGVO) für Nutzer in der Europäischen Union.",
      "Content Restrictions": "Inhaltsbeschränkungen",
      "You are responsible for ensuring that the images you upload are your own and do not contain prohibited content, such as pornography, discriminatory material, or any content that infringes upon third-party intellectual property rights. Vivid Visions reserves the right to refuse to print or remove any content that violates these terms. If any legal disputes arise from the content you upload, you will be responsible for any related legal costs or claims.": "Sie sind dafür verantwortlich, dass die von Ihnen hochgeladenen Bilder Ihnen gehören und keinen verbotenen Inhalt wie Pornografie, diskriminierendes Material oder Inhalte, die die Rechte Dritter verletzen, enthalten. Vivid Visions behält sich das Recht vor, das Drucken von Inhalten abzulehnen oder Inhalte zu entfernen, die gegen diese Bedingungen verstoßen. Wenn durch die von Ihnen hochgeladenen Inhalte rechtliche Streitigkeiten entstehen, sind Sie für alle damit verbundenen Kosten und Ansprüche verantwortlich.",
      "Support and Issue Resolution": "Support und Problemlösung",
      "If you encounter any issues with your order or the products you receive, we encourage you to contact our support team via email or WhatsApp. Our team is dedicated to resolving issues promptly, and we will work closely with you to ensure any problems are addressed efficiently. Response times may vary depending on the nature of your inquiry, but we strive to provide effective support for all customer concerns.": "Sollten Sie Probleme mit Ihrer Bestellung oder den erhaltenen Produkten haben, empfehlen wir Ihnen, unser Support-Team per E-Mail oder WhatsApp zu kontaktieren. Unser Team ist bestrebt, Probleme schnell zu lösen, und wir werden eng mit Ihnen zusammenarbeiten, um sicherzustellen, dass alle Probleme effizient angegangen werden. Die Antwortzeiten können je nach Art der Anfrage variieren, aber wir streben an, effektiven Support für alle Kundenanliegen zu bieten.",
      "Use of Services and Compliance": "Nutzung der Dienste und Einhaltung der Vorschriften",
      "By using Vivid Visions services, you agree to comply with all applicable laws and regulations. You are also responsible for ensuring that the content you upload does not violate any intellectual property rights, privacy rights, or applicable laws. We may modify these terms at any time, and it is your responsibility to stay informed about any changes. Continued use of our services after such changes will constitute your acceptance of the revised terms.": "Durch die Nutzung der Vivid Visions-Dienste erklären Sie sich damit einverstanden, alle geltenden Gesetze und Vorschriften einzuhalten. Sie sind auch dafür verantwortlich sicherzustellen, dass die von Ihnen hochgeladenen Inhalte keine Rechte an geistigem Eigentum, Datenschutzrechte oder geltende Gesetze verletzen. Wir können diese Bedingungen jederzeit ändern, und es liegt in Ihrer Verantwortung, sich über etwaige Änderungen zu informieren. Die fortgesetzte Nutzung unserer Dienste nach solchen Änderungen gilt als Annahme der überarbeiteten Bedingungen.",
      "Account Security and Responsibility": "Kontosicherheit und Verantwortung",
      "You are solely responsible for maintaining the security of your account. This includes ensuring that your account credentials are kept confidential and that your account information remains up to date. Any actions taken through your account are your responsibility, and you are liable for any misuse, including unauthorized access. If you suspect that your account has been compromised, you must notify Vivid Visions immediately to prevent further unauthorized use.": "Sie sind allein für die Sicherheit Ihres Kontos verantwortlich. Dies umfasst die Gewährleistung, dass Ihre Kontodaten vertraulich bleiben und Ihre Kontoinformationen auf dem neuesten Stand gehalten werden. Alle Aktionen, die über Ihr Konto durchgeführt werden, liegen in Ihrer Verantwortung, und Sie haften für jede missbräuchliche Nutzung, einschließlich unbefugtem Zugriff. Wenn Sie vermuten, dass Ihr Konto kompromittiert wurde, müssen Sie Vivid Visions unverzüglich benachrichtigen, um weiteren unbefugten Zugriff zu verhindern.",
      "Subscriptions and Payments": "Abonnements und Zahlungen",
      "If you subscribe to Vivid Visions services, your subscription will automatically renew at the end of each billing cycle unless you cancel it before the renewal date. Subscription fees will be charged to the payment method you provided during registration. We do not offer refunds for partial subscription periods, but we will provide refunds in cases of product errors or damage. If you wish to cancel your subscription, please contact our support team before the renewal date to avoid further charges.": "Wenn Sie ein Abonnement für Vivid Visions-Dienste abschließen, wird Ihr Abonnement am Ende jedes Abrechnungszeitraums automatisch verlängert, es sei denn, Sie kündigen es vor dem Verlängerungsdatum. Die Abonnementgebühren werden über die von Ihnen bei der Registrierung angegebene Zahlungsmethode abgerechnet. Wir bieten keine Rückerstattungen für teilweise Abonnementzeiträume an, es sei denn, es liegen Produktfehler oder Schäden vor. Wenn Sie Ihr Abonnement kündigen möchten, wenden Sie sich bitte vor dem Verlängerungsdatum an unser Support-Team, um weitere Gebühren zu vermeiden.",
      "Refunds and Returns": "Rückerstattungen und Rückgaben",
      "Vivid Visions offers refunds or replacements if the product you receive is damaged, defective, or does not meet the specifications of your order. In such cases, please contact our support team and provide relevant details, including photos of the product if necessary. We do not offer refunds if you change your mind after placing an order or if there are user errors during the ordering process, unless otherwise required by applicable law.": "Vivid Visions bietet Rückerstattungen oder Ersatz an, wenn das Produkt, das Sie erhalten haben, beschädigt oder defekt ist oder nicht den Spezifikationen Ihrer Bestellung entspricht. In solchen Fällen kontaktieren Sie bitte unser Support-Team und geben Sie relevante Details an, einschließlich Fotos des Produkts, falls erforderlich. Wir bieten keine Rückerstattungen, wenn Sie Ihre Meinung nach der Bestellung ändern oder Benutzerfehler bei der Bestellung vorliegen, es sei denn, dies ist gesetzlich vorgeschrieben.",
      "Third-Party Links and Services": "Links und Dienste von Drittanbietern",
      "Vivid Visions may provide links to third-party websites or services as part of our app functionality. Please note that we are not responsible for the content, privacy policies, or practices of these third-party sites. If you choose to engage with third-party services through our app, you do so at your own risk. We encourage you to review the terms and privacy policies of any third-party services you access through Vivid Visions.": "Vivid Visions kann als Teil der App-Funktionalität Links zu Websites oder Diensten von Drittanbietern bereitstellen. Bitte beachten Sie, dass wir nicht für die Inhalte, Datenschutzrichtlinien oder Praktiken dieser Drittanbieter-Websites verantwortlich sind. Wenn Sie sich entscheiden, über unsere App auf Dienste von Drittanbietern zuzugreifen, tun Sie dies auf eigenes Risiko. Wir empfehlen Ihnen, die Bedingungen und Datenschutzrichtlinien der von Ihnen genutzten Drittanbieterdienste zu überprüfen.",
      "Limitation of Liability": "Haftungsbeschränkung",
      "Vivid Visions will not be held liable for any indirect, incidental, special, or consequential damages arising from the use of our services, products, or any content you upload. This includes, but is not limited to, damages related to loss of data, personal injury, or issues with product quality. Our liability is limited to the amount you paid for the services in the 12 months preceding your claim. This limitation does not apply in cases of gross negligence or willful misconduct on our part.": "Vivid Visions haftet nicht für indirekte, zufällige, besondere oder Folgeschäden, die sich aus der Nutzung unserer Dienste, Produkte oder von Ihnen hochgeladener Inhalte ergeben. Dies umfasst unter anderem Schäden im Zusammenhang mit Datenverlust, Personenschäden oder Qualitätsproblemen des Produkts. Unsere Haftung ist auf den Betrag beschränkt, den Sie für die Dienste in den 12 Monaten vor Ihrem Anspruch gezahlt haben. Diese Beschränkung gilt nicht in Fällen grober Fahrlässigkeit oder vorsätzlichen Fehlverhaltens unsererseits.",
      "GDPR and Privacy Compliance": "DSGVO und Datenschutzkonformität",
      "For users in the European Union, Vivid Visions fully complies with the General Data Protection Regulation (GDPR). We are committed to protecting your personal data and providing transparency in how it is collected, used, and stored. You have the right to access, modify, or delete your personal data at any time. Images uploaded but not printed will be automatically deleted from our systems after 30 days.": "Für Nutzer in der Europäischen Union erfüllt Vivid Visions die Anforderungen der Datenschutz-Grundverordnung (DSGVO) vollständig. Wir sind bestrebt, Ihre persönlichen Daten zu schützen und Transparenz darüber zu gewährleisten, wie sie erfasst, verwendet und gespeichert werden. Sie haben jederzeit das Recht, auf Ihre persönlichen Daten zuzugreifen, sie zu ändern oder zu löschen. Hochgeladene, aber nicht gedruckte Bilder werden nach 30 Tagen automatisch aus unseren Systemen gelöscht.",
      "Intellectual Property": "Geistiges Eigentum",
      "You retain full intellectual property rights to the images you upload. However, by using our services, you grant Vivid Visions a limited, non-exclusive, royalty-free license to use, reproduce, and print your images for the purpose of fulfilling your order. Vivid Visions owns all intellectual property rights related to the app and services, including trademarks, service marks, and content, unless otherwise stated.": "Sie behalten alle Rechte an geistigem Eigentum in Bezug auf die von Ihnen hochgeladenen Bilder. Durch die Nutzung unserer Dienste gewähren Sie Vivid Visions jedoch ein begrenztes, nicht-exklusives, gebührenfreies Lizenzrecht, Ihre Bilder zur Erfüllung Ihrer Bestellung zu verwenden, zu reproduzieren und zu drucken. Vivid Visions besitzt alle Rechte an geistigem Eigentum in Bezug auf die App und die Dienste, einschließlich Marken, Dienstleistungsmarken und Inhalte, sofern nicht anders angegeben.",
      "Account Termination": "Kontokündigung",
      "Vivid Visions reserves the right to suspend or terminate your account at any time if you breach these terms or engage in fraudulent, abusive, or illegal behavior. Account suspension or termination does not affect your obligations under these terms, including indemnification, liability, and payment responsibilities.": "Vivid Visions behält sich das Recht vor, Ihr Konto jederzeit zu sperren oder zu kündigen, wenn Sie gegen diese Bedingungen verstoßen oder sich an betrügerischem, missbräuchlichem oder illegalem Verhalten beteiligen. Eine Sperrung oder Kündigung des Kontos beeinträchtigt nicht Ihre Verpflichtungen aus diesen Bedingungen, einschließlich Entschädigung, Haftung und Zahlungspflichten.",
      // FAQsList 
      "What is Vivid Visions?": "Was ist Vivid Visions?",
      "Vivid Visions offers a hassle-free solution for turning your digital images into beautiful photo tiles. Choose from a variety of designs and styles to match your decor, and enjoy the flexibility of adhesive backing, which allows you to rearrange your tiles without damaging the walls.": "Vivid Visions bietet eine unkomplizierte Lösung, um Ihre digitalen Bilder in wunderschöne Foto-Kacheln zu verwandeln. Wählen Sie aus einer Vielzahl von Designs und Stilen, die zu Ihrer Einrichtung passen, und genießen Sie die Flexibilität der selbstklebenden Rückseite, die es Ihnen ermöglicht, Ihre Kacheln neu zu arrangieren, ohne die Wände zu beschädigen.",
      "How to Make a Purchase?": "Wie tätige ich einen Kauf?",
      "Upload your images, pick your preferred style, and proceed to checkout. Vivid Visions provides a user-friendly process for designing and ordering photo tiles.": "Laden Sie Ihre Bilder hoch, wählen Sie Ihren bevorzugten Stil aus und gehen Sie zur Kasse. Vivid Visions bietet einen benutzerfreundlichen Prozess für die Gestaltung und Bestellung von Foto-Kacheln.",
      "Details About the Tiles": "Details zu den Kacheln",
      "Vivid Visions offers various sizes and frame options, including black, white, wood-toned, and frameless, as well as canvas options. You can also customize with mat shapes, colors, and collage effects.": "Vivid Visions bietet verschiedene Größen und Rahmenoptionen an, darunter Schwarz, Weiß, Holzoptik und rahmenlose sowie Leinwandoptionen. Sie können auch mit Mattformen, Farben und Collagen-Effekten personalisieren.",
      "Promotional Codes": "Rabattcodes",
      "Discounts vary depending on your selection, and you can see your savings during checkout. Subscribe to the newsletter for exclusive deals.": "Die Rabatte variieren je nach Auswahl, und Sie können Ihre Einsparungen während des Bestellvorgangs und an der Kasse einsehen. Abonnieren Sie den Newsletter für exklusive Angebote.",
      "Making Changes to Your Order": "Änderungen an Ihrer Bestellung vornehmen",
      "You can edit or update your order if it hasn't been processed yet. Simply go to \"My Orders\" on the app or website, and make changes to the delivery address, style, or photos.": "Sie können Ihre Bestellung bearbeiten oder aktualisieren, solange sie noch nicht bearbeitet wurde. Gehen Sie einfach zu \"Meine Bestellungen\" in der App oder auf der Website und ändern Sie die Lieferadresse, den Stil oder die Fotos.",
      "What to Do After Receiving Your Vivid Visions": "Was tun nach Erhalt Ihrer Vivid Visions?",
      "Instructions for applying the adhesive photo tiles to your walls, whether using sticky strips or magnetic adhesives.": "Anweisungen zum Anbringen der selbstklebenden Foto-Kacheln an Ihrer Wand, ob mit Klebestreifen oder magnetischen Klebern.",
      "Not Satisfied?": "Nicht zufrieden?",
      "Vivid Visions offers a \"Happiness Guarantee.\" If you are not satisfied with your product, you can contact them for support.": "Vivid Visions bietet eine \"Happiness Guarantee\". Wenn Sie mit Ihrem Produkt nicht zufrieden sind, können Sie sich an den Support wenden.",
      "Shipping": "Versand",
      "Worldwide shipping is available, and sometimes there may be a small fee for smaller orders. Expedited shipping is offered in some countries, and there are no unexpected charges for Canadian customers.": "Der weltweite Versand ist verfügbar, manchmal fällt bei kleineren Bestellungen eine kleine Gebühr an. In einigen Ländern wird auch ein Expressversand angeboten, und für kanadische Kunden gibt es keine unerwarteten Gebühren.",
      "Order Tracking": "Auftragsverfolgung",
      "You will receive email notifications with updates on your order’s progress, including tracking information.": "Sie erhalten E-Mail-Benachrichtigungen mit Updates zum Fortschritt Ihrer Bestellung, einschließlich Tracking-Informationen.",
      // LandingArt 
      "Award-Winning Gallery Art": "PREISGEKRÖNTE GALERIEKUNST",
      "Art For All Seasons, Style For Every Reason.": "Geben Sie sich nicht mit Mittelmäßigkeit zufrieden",
      "SHOP NOW": "JETZT EINKAUFEN",
      // Promo
      "Autumn Sale": "Herbstschlussverkauf",
      "20% OFF Everything + 15% EXTRA OFF every second poster - use code: AUTUMN": "20 % Rabatt auf alles + 15 % EXTRA-Rabatt auf jedes zweite Poster – verwenden Sie den Code: AUTUMN",
      // LandingGallery 
      "Transform your walls with stunning, one-of-a-kind art from our exclusive collection.": "Verschönern Sie Ihre Wände mit atemberaubender Kunst aus unserer exklusiven Galerie.",
      "Discover unique masterpieces that will elevate your space into a stunning visual experience.": "Entdecken Sie einzigartige Meisterwerke, die Ihren Raum in ein atemberaubendes visuelles Erlebnis verwandeln.",
      "Shop Now": "Genießen Sie Kunst",
      "Browse our collection and bring the beauty of art into your home today!": "Stöbern Sie in unserer Sammlung und holen Sie sich noch heute die Schönheit der Kunst in Ihr Zuhause!",
      // PhotoItem;
      "Out of stack": "Aus dem Stapel",
      "Left in stack": "Links im Stapel",
      "Limited Stock Ended": "Begrenzter Vorrat beendet",
      "Limited Release": "Begrenzte Veröffentlichung",
      // OfferTimer 
      "black friday prices": "Black Friday Preise",
      "While stock lasts": "Solange der Vorrat reicht",
      // 
      "Create a Photo Wall in a Few Taps": "Erstellen Sie mit wenigen Fingertipps eine Fotowand",
      "Pick the perfect style for your home":"Wählen Sie den perfekten Stil für Ihr Zuhause",
    },
  },
  Arabic: {
    translation: {
      // FirstPage
      "Let's initiate your journey with Vivid Visions": "لنبدأ رحلتك مع Vivid Visions ",
      "Turn your image into budget-friendly, breathtaking wall art.":
        "حوّل صورتك إلى ديكور جداري مذهل وبأسعار معقولة",
      "Let's begin!": "لنبدأ",
      // PrivacySettings
      "Information Protection Settings": "إعدادات حماية المعلومات",
      "Our website utilizes external website tracking tools to enrich and customize our services, while also serving advertising goals. The activation of optional cookies relies on your consent, which you are free to revoke at any time":
        "يستخدم موقعنا على الويب أدوات تتبع مواقع الويب الخارجية لتحسين وتخصيص خدماتنا، بالإضافة إلى تحقيق أهداف الإعلان. تعتمد تفعيل ملفات تعريف الارتباط الاختيارية على موافقتك، التي يمكنك سحبها في أي وقت",
      Refuse: "رفض",
      Agree: "موافق",
      // NameRegister
      "Please enter more than 3 characters": "يرجى إدخال أكثر من 3 محارف",
      "Let's start! What's your identity?": "من انت ؟",
      "What are you called?": "ما هو اسمك",
      Next: "التالي",
      Prev: "السابق",
      // EmailRegister
      "Name should be more than 3 characters": "يجب أن يكون الاسم أكثر من 3 أحرف",
      "Please enter a valid email": "يرجى إدخال عنوان بريد إلكتروني صالح",
      "Please consent to the terms below": "يرجى الموافقة على الشروط أدناه",
      "Some thing was wrong": "حدث خطأ ما",
      Hello: "أهلاَ",
      "Your email address, please.": "عنوان بريدك الإلكتروني، من فضلك",
      "Of course, I'd be interested in receiving information and updates on new offers.":
        "نعم، يُرجى إرسال لي المعلومات والعروض الجديدة",
      "By sending, you acknowledge the terms.": "بالتقدم ,أنت توافق على ",
      "Terms of service": "شروط الاستخدام",
      "&": "و",
      "Confidentiality agreement": "سياسة الخصوصية",
      Okay: "حسناَ",
      // Home
      // Landing
      "Turn your photos into stunning wall art.":
        "تحويل صورك إلى لوحات فنية جدارية مذهلة",
      "Create beautiful walls filled with memories using your favorite photos.":
        "صمم جدرانًا جميلة مليئة بالذكريات باستخدام صورك المفضلة",
      "Free worldwide shipping!": "الشحن لجميع أنحاء العالم دون أي تكلفة",
      "Start Design": "زين صورك",
      "let's begin.": "هيا نبدأ",
      // Features
      "No nails needed!": "لا حاجة للأظافر",
      "Our frames stick to any type of wall.":
        "إطاراتنا تلتصق بجميع أنواع الجدران",
      "Delivered to your door in just 7 days.":
        "تسليم إلى باب منزلك خلال سبعة أيام",
      "Your satisfaction is guaranteed.": "نضمن رضاك",
      "Not satisfied? Get a full refund within 30 days, no questions asked.":
        "غير راضى؟ احصل على كامل المبلغ",
      // StickyFrame
      "The magic frame that sticks to any surface.":
        "الإطار السحري الذي يلتصق بأي سطح",
      "Get your photos in stylish frames that attach directly to your wall, leaving no damage.":
        "احصل على صورك في إطارات عصرية تلتصق مباشرة بالحائط الخاص بك، ولا تسبب أي ضرر",
      "limited-edition art—only 10 pieces available per artwork.": "فن إصدار محدود - 10 قطع متاحة فقط لكل عمل فني.",
      "Each exclusive masterpiece offers a unique, stunning visual experience.": "تقدم كل تحفة فنية حصرية تجربة بصرية فريدة ومذهلة.",
      // Reviews
      "Customer Reviews": "ملاحظات العملاء",
      "Your feedback drives us – your happiness is our goal!":
        "نحن نستمتع بمشاهدة الجدران المذهلة المزينة بصورك",
      "You're likely familiar with my discussions about these photos, and I must say, they're truly unparalleled":
        "من المحتمل أنك على دراية بمناقشاتي حول هذه الصور، ويجب أن أقول إنها لا مثيل لها حقًا",
      // Sidebar
      Register: "انشاء حساب",
      "to save your progress and monitor your orders.":
        "لحفظ تقدمك وتتبع الطلبات",
      "Login": "تسجيل الدخول",
      "Start Design": "ابدأ التصميم",
      "Continue Design": "متابعة التصميم",
      "Home": "الصفحة الرئيسية",
      "Art Gallery": "تصفح المجموعة الفنية",
      "Purchase a Gift Card": "اشترِ بطاقة هدية",
      "Discount Codes": "كود الحسم",
      "My Orders": "طلباتي",
      "FAQs": "الاسئلة الشائعة",
      "Terms of Service": "شروط الاستخدام",
      Logout: "تسجيل الخروج",
      // LogInAndSignUp
      "Password should be more than 6 characters":
        "يجب أن يكون طول رمز المرور 6 على الأقل",
      "Password and password confirmation are not matched":
        "رمز التأكيد غير مطابق",
      "Email and password can not be empty":
        "يجب إدخال الايميل و رمز المرور",
      successful: "تمت بنجاح",
      "Email has already been taken":
        "هذا الإيميل مأخوذ بالفعل",
      "Email or password is invalid": "يوجد مشكلة في الايميل او رمز المرور",
      Login: "تسجيل دخول",
      "Enter Your Name": "أدخل اسمك",
      "Enter Your Email": "أدخل بريدك الالكتروني",
      "Enter Password": "أدخل رمز المرور",
      "Enter Password Confirmation": "أدخل رمز التأكيد لرمز المرور",
      or: "أو",
      "sign in with Google": "تسجيل الدخول باستخدام حساب غوغل",
      "You are already have an account ?": "هل تمتلك حساب بالفعل ؟",
      "You do not have an account ?": "لا تمتلك حساباَ ؟",
      "and": "و",
      // Footer
      About: "حول",
      "Follow Us": "تابعنا",
      "Contact Us": "تواصل معنا",
      "Pinterest": "بينترست",
      Instagram: "انستغرام",
      Facebook: "فيسبوك",
      Tictok: "تيكتوك",
      "All rights reserved":
        "جميع الحقوق محفوظة",
      // SelectLanguage
      "Choose Language": "اختر اللغة",
      English: "English",
      German: "Deutsch",
      Arabic: "العربية",
      // PromoCode
      "Discount Code": "كود الحسم",
      Done: "حسناَ",
      "Quantity Discount Applied": "تم تطبيق خصم الكمية",
      "Discount depends on size & material": "الخصم يعتمد على الحجم والمادة",
      "Hello (default)": "مرحبا (افتراضي)",
      "Save up to 45%": "استمتع بتوفير يصل إلى 45%",
      "Enter Discount Code or Apply Gift Card":
        "أدخل رمز الخصم أو قم بتطبيق بطاقة الهدايا",
      "Your Code": "الكود الخاص بك",
      "Enter Here": "أدخل هنا",
      // ProcessOfAddPhoto
      "What would you like to include?": "ماذا تريد أن تضيف؟",
      "Photo Tiles": "لوحة الصور",
      "Add Photos": "إضافة الصور",
      // EditPhotos
      "Upload Your Photo": "ابدأ في الإنشاء",
      // EditPhotos and EditOnePhoto
      "delete photo": "جاري حذف الصورة",
      "Low Resolution Photo": "صورة منخفضة الدقة",
      "Replace": "استبدل",
      Material: "المادة",
      Size: "الأبعاد",
      Impact: "التأثير",
      Mat: "العمق",
      // MaterialList
      "Choose Material": "اختر المادة",
      "Black Frame": "Rahmen",
      "A design that is enduring and embodies classic elegance":
        "Ein Design, das beständig ist und klassische Eleganz verkörpert",
      Canvas: "Leinwand",
      "Cotton canvas that enhances any space":
        "Baumwollleinwand, die jeden Raum verbessert",
      // SizeList
      "Choose Size": "اختر الحجم",
      // EffectList
      "Choose Effect": "اختر التأثير",
      Authentic: "أصلي",
      Silver: "فضي",
      Noir: "معتم",
      Vivid: "واضح",
      Dramatic: "دراماتيكي",
      // MatList
      "Choose Mat": "اختر العمق",
      // MaterialList SizeList EffectList MatList
      Completed: "مكتمل",
      // EditOnePhoto
      "Edit Tile": "تعديل الصورة",
      Delete: "حذف",
      // CropPhoto
      "Switch to Rectangular": "التبديل إلى المستطيل",
      "Switch to Square": "التبديل إلى المربع",
      "Switch to Horizontal": "التبديل إلى الوضع الأفقي",
      "Switch to Vertical": "التبديل إلى الوضع العمودي",
      "Crop and Save": "قص وحفظ",
      // ArtHome
      // LandingArt
      Exquisite: "رائعة",
      "Art in frames": "الفن في الإطارات",
      "For everyone.": "للجميع",
      "Begin now.": "تبدأ الآن",
      // CheckList
      "Art is": "الفن هو",
      "The frame": "الاطار",
      "Complimentary shipping to every state in the USA":
        "شحن مجاني إلى كل ولاية في الولايات المتحدة الأمريكية",
      // Magic
      "Enchantment is part of the": "السحر متضمن",
      // MagicList
      "The renowned frame that adheres, and adheres again":
        "الإطار الشهير الذي يلتصق، ويلتصق مرة أخرى",
      "No nails needed.": "لا حاجة للأظافر",
      "No traces left on your wall.": "لم يبق أي أثر على الحائط الخاص بك",
      // Artists
      "Crafted by the most esteemed artists globally.":
        "تم تصميمه على يد أشهر الفنانين على مستوى العالم",
      "With every purchase, we contribute to supporting the artists,":
        "مع كل عملية شراء، نساهم في دعم الفنانين",
      "They maintain complete ownership of the artworks.":
        "يحتفظون بالملكية الكاملة للأعمال الفنية",
      // ArtPhotos
      "Sorted by": "مرتبة حسب",
      Latest: "الأحدث",
      "Higher Price": "الأعلى سعراَ",
      "Lower Price": "الأقل سعراَ",
      "Higher Rating": "الأعلى تقييمأ",
      "Lower Rating": "الأقل تقييماَ",
      "Best Seller": "الأكثر مبيعًا",
      "Show All Photos": "عرض كل الصور",
      // FAQsList
      "What exactly is Vivid Visions ?": "ماهو Vivid Visions",
      "Introducing Vivid Visions —your hassle-free solution for converting your digital images into ready-to-display photo tiles for your walls. Explore a variety of designs to complement your home decor perfectly. And here's the kicker: Our adhesive backing ensures zero wall damage, giving you the flexibility to rearrange your photo tiles at your convenience, as often as you desire.":
        "نقدم لك Vivid Visions - الحل الخالي من المتاعب لتحويل صورك الرقمية إلى لوحات صور جاهزة للعرض على جدرانك. استكشف مجموعة متنوعة من التصميمات لتكمل ديكور منزلك بشكل مثالي. وإليك أهم ما في الأمر: يضمن الجزء الخلفي اللاصق الخاص بنا عدم حدوث أي ضرر على الحائط، مما يمنحك المرونة لإعادة ترتيب لوحة الصور الخاص بك حسب راحتك، كلما أردت ذلك.",
      "Sounds intriguing! How can I go about making a purchase?":
        "يبدو مثيرا للاهتمام! كيف يمكنني البدء في الشراء؟",
      "If you're here, you've successfully landed on our website! To proceed with your order, simply navigate to the menu icon in the top corner and select (Continue Design) Once there, upload your images, pick your preferred photos style, and leave the rest to us!":
        'اذا كنت هنا، فقد وصلت بنجاح إلى موقعنا! لمتابعة طلبك، ما عليك سوى الانتقال إلى رمز القائمة في الزاوية العلوية وتحديد "تأطير صورك". بمجرد وصولك إلى هناك، قم بتحميل صورك، واختر نمط الصور المفضل لديك، واترك الباقي لنا!',
      "This is thrilling!, Please share all the details about your tiles with me!":
        "هذا مثير!, يرجى مشاركة جميع التفاصيل حول اللوحة الخاص بك معي!",
      'We offer an array of sizes and designs, allowing you to mix and match until you discover the ideal fit for your home\'s aesthetic. from from 8.4" x 8.4" to a stunning 27" x 36", We\'ve got all sizes available. Select from black, white, wood-toned frames, frameless designs, or opt for our authentic Canvas option. Additionally, we provide a range of mat shapes, sizes, and colors, along with various photo effects and collage options! Just follow the steps outlined above to start personalizing your order!':
        "نحن نقدم مجموعة من المقاسات والتصميمات، مما يسمح لك بالمزج والمطابقة حتى تكتشف المقاس المثالي لجمال منزلك. من 8.4 بوصة × 8.4 بوصة إلى 27 بوصة × 36 بوصة، لدينا جميع الأحجام المتاحة. اختر من بين الإطارات باللون الأسود والأبيض والخشبي والتصميمات بدون إطار، أو اختر خيار Canvas الأصلي الخاص بنا. بالإضافة إلى ذلك، نحن نقدم مجموعة من أشكال السجادات وأحجامها وألوانها، بالإضافة إلى تأثيرات الصور المتنوعة وخيارات الصور المجمعة! ما عليك سوى اتباع الخطوات الموضحة أعلاه لبدء تخصيص طلبك!",
      "Can you explain how your promotional codes function?":
        "كيف تعمل الرموز الترويجية الخاصة بك؟",
      "Excellent inquiry! The amount you save with our promotional codes varies based on your selection of size and material. However, you'll always be able to view your savings as you proceed through the ordering process and again on the Checkout page before confirming your purchase.":
        "سؤال عظيم! يعتمد الخصم الذي ستحصل عليه على الحجم والمواد التي تختارها. ولكنك ستشاهد دائمًا المبلغ الذي ستوفره أثناء عملية الطلب، ومرة ​​أخرى على صفحة الدفع، قبل الانتهاء من طلبك.",
      "Oh, and if you haven’t subscribed to our newsletter yet, now's the perfect time to do so! Simply head over to www.Vivid Visions.com/getstarted to sign up. That’s where we exclusively share our top-notch offers and discounts.":
        "أوه، وإذا لم تكن قد اشتركت في نشرتنا الإخبارية بعد، فهذا هو الوقت المثالي للقيام بذلك! ما عليك سوى التوجه إلى www.Vivid Visions .com/getstarted للتسجيل. هذا هو المكان الذي نشارك فيه حصريًا عروضنا وخصوماتنا المتميزة.",
      "Uh oh! I've already placed my order and I need to make some changes.":
        "نعم ! لقد قدمت طلبي بالفعل وأحتاج إلى إجراء بعض التغييرات.",
      "Wonderful question! The amount you'll save varies depending on the size and material you select. However, you'll always be able to view your savings as you progress through the ordering process and again on the Checkout page before completing your purchase.":
        "سؤال عظيم! يعتمد الخصم الذي ستحصل عليه على الحجم والمواد التي تختارها. ولكنك ستشاهد دائمًا المبلغ الذي ستوفره أثناء عملية الطلب، ومرة ​​أخرى على صفحة الدفع، قبل الانتهاء من طلبك.",
      'For app orders, simply navigate to "My Orders" by tapping the three lines in the upper corner. Once there, you can conveniently make adjustments such as editing or replacing photos, changing the style, or updating the delivery address!':
        'طلبات التطبيق: في التطبيق، انتقل إلى "طلباتي" من خلال النقر على الخطوط الثلاثة في الزاوية العلوية. ومن هناك، يمكنك بسهولة تعديل الصور أو استبدالها، أو تغيير النمط، أو حتى تحديث عنوان التسليم!',
      "For web orders, if you need to modify your delivery address, simply log in or create a Vivid Visions account using the same email address associated with your order. As long as we haven't begun processing it, you'll have the flexibility to make the necessary changes.":
        "طلبات الويب: إذا كان عنوان التسليم الخاص بك بحاجة إلى التغيير، فما عليك سوى تسجيل الدخول أو إنشاء حساب Vivid Visions معنا باستخدام نفس عنوان البريد الإلكتروني الذي استخدمته لطلبك. وطالما أننا لم نبدأ في معالجته، فستتمكن من إجراء هذا التغيير.",
      "If you require assistance with anything else or if you've missed the opportunity to make changes, don't fret! Simply click on the three lines once more and select \"Chat With Us,\" or feel free to send us an email at hi@Vivid Visions .com. We're always available to help with any additional inquiries or requests you may have!":
        'إذا كنت بحاجة إلى مساعدة في أي شيء آخر أو إذا فاتتك نافذة التغييرات، فلا داعي للقلق! انقر على هذه الأسطر الثلاثة مرة أخرى واختر "الدردشة معنا"، أو أرسل لنا رسالة بالبريد الإلكتروني على hi@Vivid Visions .com. سنكون هنا لتقديم المساعدة في أي طلبات أخرى قد تكون لديك!',
      "Now that I have my Vivid Visions , what comes next?":
        "لقد حصلت على Vivid Visions ، ماذا الآن؟",
      "Your Vivid Visions photos have arrived – how exciting! Before anything else, examine the back to determine if it has a single sticky strip adhesive or a magnetic square adhesive. Once done, plan your layout and proceed with the following steps:":
        "لقد وصلت صور Vivid Visions  الخاصة بك – كم هو مثير! قبل أي شيء آخر، قم بفحص الجزء الخلفي لتحديد ما إذا كان يحتوي على شريط لاصق واحد أو شريط لاصق مغناطيسي مربع. بمجرد الانتهاء من ذلك، قم بتخطيط التخطيط الخاص بك وتابع الخطوات التالية",
      "Single Sticky Adhesive Strip: First, grab a dry cloth and give the wall a quick wipe. Once you're ready, peel off that protective sheet from the sticky and apply pressure evenly around the frame.":
        "شريط لاصق واحد: أولاً، أمسك بقطعة قماش جافة وامسح الحائط سريعًا. بمجرد أن تصبح جاهزًا، انزع تلك الطبقة الواقية من المادة اللاصقة واضغط بالتساوي حول الإطار.",
      "Magnetic Adhesives: Begin by removing the protective white sheet. Next, position the tile on the desired spot on the wall and firmly press the magnet into place.":
        "المواد اللاصقة المغناطيسية: أولاً، قم بتقشير الطبقة الواقية البيضاء. ثم ضع اللوحة في المكان الذي تريده على الحائط واضغط على المغناطيس بقوة.",
      "Now, simply relish those cherished memories displayed on your walls! If you ever find yourself needing to relocate your Brintbent photos, just safeguard the adhesive part with standard kitchen wax paper, and they'll easily adhere to their new location.":
        "الآن، ببساطة استمتع بتلك الذكريات العزيزة المعروضة على جدرانك! إذا وجدت نفسك بحاجة إلى نقل صور  Vivid Visions الخاص بك، فما عليك سوى حماية الجزء اللاصق باستخدام ورق الشمع القياسي للمطبخ، وسوف يلتصق بسهولة بموقعه الجديد.",
      "What should I do if my tiles don't match my expectations?":
        "ماذا علي أن أفعل إذا كان طلبي لا يتوافق مع توقعاتي؟",
      "We offer a Happiness Guarantee to support our product. If you're not completely satisfied with your purchase for any reason, please don't hesitate to contact us. Your happiness is our top priority, and we are committed to ensuring your satisfaction!":
        "نحن ندعم منتجنا بـ (ضمان السعادة). إذا لم تكن راضيًا عن عملية الشراء لأي سبب من الأسباب، فما عليك سوى الاتصال بنا. نحن نأخذ سعادتك على محمل الجد وسنبذل قصارى جهدنا لتحقيق ذلك!",
      "Tell me about your shipping.": "أخبرني عن الشحن الخاص بك.",
      "We generally provide complimentary worldwide shipping! Occasionally, there may be a nominal shipping fee for smaller orders, which will be clearly indicated on the Checkout page prior to finalizing your purchase. Additionally, in certain countries, we offer expedited shipping for those who require their orders to arrive more quickly. Should express shipping be an option for your designated delivery address, you'll notice the choice presented on the Checkout page, accompanied by the corresponding fee for this expedited service.":
        "نحن نقدم بشكل عام شحنًا مجانيًا إلى جميع أنحاء العالم! في بعض الأحيان، قد تكون هناك رسوم شحن رمزية للطلبات الصغيرة، والتي سيتم الإشارة إليها بوضوح في صفحة الدفع قبل الانتهاء من عملية الشراء. بالإضافة إلى ذلك، في بعض البلدان، نقدم خدمة الشحن السريع لأولئك الذين يحتاجون إلى وصول طلباتهم بسرعة أكبر. إذا كان الشحن السريع خيارًا لعنوان التسليم المحدد لك، فستلاحظ الاختيار المعروض على صفحة الدفع، مصحوبًا بالرسوم المقابلة لهذه الخدمة العاجلة.",
      "And for our Canadian customers, rest assured that the import fee displayed on the Checkout screen is simply a substitution for your local sales tax. This means no unexpected charges for you!":
        "وإذا كنت في كندا، فلا تقلق بشأن رسوم الاستيراد التي تراها على شاشة الدفع. إنها موجودة لتحل محل ضريبة المبيعات المحلية،ك. وهذا يعني عدم وجود رسوم غير متوقعة بالنسبة لك!",
      "How can I keep track of my order's progress?":
        "كيف يمكنني متابعة تقدم طلبي؟",
      "No problem at all! We'll ensure you're informed about every step of your order's progress through email notifications. You'll receive an email once your order begins production, and another once it's dispatched for delivery. Plus, the tracking link provided will keep you updated on the whereabouts of your package at all times!":
        "لا مشكلة على الإطلاق! سوف نتأكد من أنك على علم بكل خطوة من خطوات تقدم طلبك من خلال إشعارات البريد الإلكتروني. ستتلقى بريدًا إلكترونيًا بمجرد بدء إنتاج طلبك، وآخر بمجرد إرساله للتسليم. بالإضافة إلى ذلك، فإن رابط التتبع المقدم سيبقيك على اطلاع دائم بمكان وجود الطرد الخاص بك في جميع الأوقات!",
      "Is it possible to present Vivid Visions as a gift?":
        "هل من الممكن تقديم Vivid Visions كهدية؟",
      'Certainly! Through our gift cards, the recipient has the freedom to select their preferred photos and craft their unique Vivid Visions . To access the Buy a Gift Card feature, simply tap on the three horizontal lines. It\'s worth noting: our gift cards are exclusively tailored for our. 8.4" x 8.4" size only.':
        "بالتأكيد! من خلال بطاقات الهدايا الخاصة بنا، يتمتع المستلم بحرية اختيار الصور المفضلة لديه وصياغة Vivid Visions الفريدة الخاصة به. للوصول إلى ميزة شراء بطاقة الهدايا، ما عليك سوى النقر على الخطوط الأفقية الثلاثة. تجدر الإشارة إلى أن بطاقات الهدايا الخاصة بنا مصممة خصيصًا لتناسب احتياجاتنا. مقاس 8.4 × 8.4 بوصة فقط.",
      "If you have particular images in mind already, no problem! We provide free worldwide shipping, allowing you to send the Vivid Visions directly to your loved one for a delightful surprise!": `إذا كان لديك بالفعل صور محددة في ذهنك، فلا داعي للقلق! نحن نقدم الشحن المجاني في جميع أنحاء العالم، حتى تتمكن من إرسال Vivid Visions مباشرة إلى أحبائك ومفاجئتهم!":`,
      "Do you have any additional inquiries?": "هل لديك أي استفسارات إضافية؟",
      "Visit our": "زر",
      "Support Center": "مركز الدعم",
      // my orders
      "It looks like you haven't placed any orders yet.":
        "يبدو أنك لم تقدم أي طلبات بعد.",
      "Select some pictures": "اختر  بعض الصور",
      Orders: "الطلبات",
      "Order ID": "رقم الطلب",
      Status: "الحالة",
      "Earliest Delivery Date": "تاريخ التسليم الأقرب",
      "Latest Delivery Date": "الموعد النهائي للتسليم",
      Items: "العناصر",
      "Details": "التفاصيل",
      Note: "الملاحظة",
      Price: "التكلفة",
      Total: "الإجمالي",
      "The gift note": "الرسالة المرافقة للهدية",
      "Login is necessary": "تسجيل الدخول إلزامي",
      "To view your orders, please login or register.":
        "لعرض طلباتك، يرجى تسجيل الدخول أو انشاء حساب",
      "Register / Log In": "انشاء حساب /تسجيل الدخول",
      // payment details
      "Payment Details": "معلومات الدفع",
      "Pay Now": "ادفع الآن",
      "Discount Total": "إجمالي الخصم",
      "Total Before Discount": "الإجمالي قبل الخصم",
      "The special note": "الملاحظة الخاصة",
      remove: "إزالة",
      "cart item": "العنصر",
      // Add address
      "Add Address": "أدخل العنوان",
      Done: "حسناَ",
      Email: "البريد الإلكتروني",
      "Your Email": "بريدك الإلكتروني",
      "Full name": "الاسم الكامل",
      "First and last name": "الاسم الأول و الأخير",
      Country: "الدولة",
      "Select Country": "اختر دولة",
      Select: "اختر",
      "Province": "مقاطعة",
      "State": "ولاية",
      "Town": "بلدة",
      "Select Region": "اختر منطقة",
      City: "المدينة",
      "Your City": "مدينتك",
      "Address line": "العنوان",
      "Street address": "معلومات الشارع",
      "Additional address": "العنوان الثانوي",
      "Zip Code": "الرمز البريدي",
      "Postal Code": "الرمز البريدي",
      "Phone number": "رقم الجوال",
      "Your phone number will only be used if we need to reach you regarding delivery.":
        "سيتم استخدام رقم هاتفك فقط إذا احتجنا إلى الاتصال بك بخصوص التسليم",
      // make decision
      "Are you sure you want to": "هل أنت متأكد أنك تريد ذلك",
      "delete": "حذف",
      "photo item": "الصورة",
      this: "هذا",
      Cancel: "الغاء",
      Ok: "موافق",
      // login with code
      "We'll send the code in a matter of seconds": "سنرسل الرمز في غضون ثوانٍ",
      Continue: "التالي",
      "Log In or Register": "تسجيل الدخول أو انشاء حساب",
      "Christmas Offer: Log in for an extra 5% off!": "سجل دخولك وسوف تحصل على خصم 5%",
      "Please input your email .": "الرجاء إدخال البريد الإلكتروني الخاص بك.",
      or: "أو",
      "By registering, you consent to our": "بالتسجيل، فإنك توافق على شروطنا",
      "Verification code has been sent to your email":
        "تم إرسال رمز التحقق إلى بريدك الإلكتروني.",
      "Payment Checkout": "إتمام عملية الدفع",
      "Submit Payment": "تأكيد الدفع",
      "Go back": "العودة للخلف",
      "An email containing the code has been dispatched to you:":
        "تم إرسال بريد إلكتروني يحتوي على الكود إليك.",
      "Please input the code": "الرجاء إدخال الرمز",
      "Send Code Again": "ارسل الرمز مجددا",
      Loading: "جار التحميل",
      // CheckoutForm
      Submit: "تأكيد",
      // PhotoDetails
      "Add photo to cart": "ضع الصورة في السلة",
      "The picture has been added to your cart":
        "تمت إضافة الصورة إلى سلة التسوق الخاصة بك",
      "Regular": "عادي",
      "Limited Release": "إصدار محدود",
      "Only": "فقط",
      "Left": "غادر",
      "Items sold in the last 24 hours": "العناصر المباعة خلال الـ 24 ساعة الماضية",
      From: "من",
      Rating: "التقييم",
      Type: "النوع",
      "Select type": "اختر النوع",
      "Select size": "اختر الحجم",
      "Add to cart": "اضافة الى السلة",
      "Required": "مطلوب",
      "Print & frame specification": "مواصفات الطباعة والإطار",
      "Delivery & returns": "التسليم والإرجاع",
      "SHIPPING": "الشحن",
      "Destination": "الوجهة",
      "Delivery time": "موعد التسليم",
      "These times are estimated and may be longer, especially during peak periods or holidays. Shipping costs are calculated at checkout. Rates may differ depending on your payment currency": "هذه الأوقات تقديرية وقد تكون أطول، خاصة خلال فترات الذروة أو العطلات. يتم حساب تكاليف الشحن عند الدفع. قد تختلف الأسعار حسب عملة الدفع الخاصة بك",
      "RETURNS": "الإرجاعات",
      "My item arrived damaged": "وصل العنصر الخاص بي تالفًا",
      "Very rarely items can be damaged in transit, but don't worry, just contact us within 14 days of the item's delivery and we'll send you a free express replacement": "نادرًا ما تتعرض العناصر للتلف أثناء النقل، ولكن لا تقلق، فقط اتصل بنا في غضون 14 يومًا من تسليم العنصر وسنرسل لك بديلًا سريعًا مجانيًا",
      "I ordered a print or frame, but changed my mind and I want to return it": "لقد طلبت طباعة أو إطارًا، لكنني غيرت رأيي وأريد إرجاعه",
      "Don't worry, we get it! Contact us within 14 days of receiving your order and we can discuss your options to return it for a full refund": "لقد طلبت طباعة أو إطارًا، لكنني غيرت رأيي وأريد إرجاعه",
      "Related Photos": "صور ذات صلة",
      "See more": "شاهد المزيد",
      "Sorry, No related photos available": "عذرا، لا توجد صور ذات صلة متاحة",
      Reviews: "التقييمات",
      "No feedback available": "لاتوجد تقييمات متاحة",
      // checkout
      "Please enter your address details": "يرجى إدخال تفاصيل عنوانك",
      "Please sign in to your account to continue":
        "الرجاء تسجيل الدخول إلى حسابك للمتابعة",
      Checkout: "الدفع",
      Modify: "تعديل",
      "Attach a special note": "قم بإرفاق ملاحظة خاصة",
      "Enter gift note here": "أدخل ملاحظة الهدية هنا",
      "The note has been saved": "تم حفظ الملاحظة",
      Save: "حفظ",
      "Promo: WELCOME": "كود الحسم: مرحبا",
      Tiles: "لوحات",
      "Tile": "لَوحَة",
      "Add a discount code": "أضف رمز خصم",
      Digits: "رقمية",
      "12 tiles available at 99": "12 لوحة متوفرة بسعر 99",
      "Add 10 tiles to your cart to activate the special offer!":
        "أضف 10 لوحة إلى سلتك لتفعيل العرض الخاص",
      Shipping: "الشحن",
      Free: "مجانا",
      "Confirm & Pay": "التأكيد & الدفع",
      "Dark Mode": "الوضع المظلم",
      "Light Mode": "الوضع الفاتح",
      // TermsAndConditions
      "Vivid Visions Terms Of Service": "الشروط والأحكام",
      "Dispute Resolution and Arbitration": "حل النزاعات والتحكيم",
      "All disputes, claims, or controversies arising from or relating to your use of Vivid Visions services or products must be settled through binding arbitration. This means that disputes will not be resolved in court, and neither party may bring or participate in any class, collective, or representative action. Arbitration will be conducted under the rules of the American Arbitration Association (AAA), and both parties agree to waive the right to a trial by jury.": "يجب حل جميع النزاعات أو المطالبات أو الخلافات الناشئة عن استخدامك لخدمات أو منتجات Vivid Visions من خلال التحكيم الملزم. هذا يعني أن النزاعات لن تُحل في المحكمة، ولا يجوز لأي من الطرفين تقديم أو المشاركة في أي دعوى جماعية أو تمثيلية. سيتم إجراء التحكيم وفقًا لقواعد جمعية التحكيم الأمريكية (AAA)، ويتنازل كلا الطرفين عن الحق في محاكمة أمام هيئة محلفين.",
      "Image Ownership and Use": "ملكية الصور والاستخدام",
      "You retain full ownership of all images uploaded to the Vivid Visions platform. Vivid Visions will not use, sell, or share your images for any purposes beyond printing and delivering the products you ordered. By using our services, you grant Vivid Visions a limited, non-exclusive right to use your images solely for fulfilling your order. This includes activities necessary to print and deliver the products, such as image processing and quality control.": "تحتفظ بالملكية الكاملة لجميع الصور التي تقوم بتحميلها على منصة Vivid Visions. لن تستخدم Vivid Visions صورك أو تبيعها أو تشاركها لأي أغراض أخرى غير طباعة وتسليم المنتجات التي طلبتها. باستخدام خدماتنا، تمنح Vivid Visions حقًا محدودًا وغير حصري لاستخدام صورك لغرض تنفيذ طلبك فقط.",
      "App Permissions": "أذونات التطبيق",
      "Vivid Visions requires access to your device’s photo gallery in order to allow you to select, crop, and order images through our app. This permission is necessary to facilitate the functionality of our services. You may revoke this access at any time by adjusting your device settings. However, disabling this access may limit your ability to use certain features of the app.": "يتطلب تطبيق Vivid Visions الوصول إلى معرض الصور الخاص بجهازك للسماح لك بتحديد الصور وقصها وطلبها. يمكنك إلغاء هذا الوصول في أي وقت من إعدادات جهازك. ومع ذلك، فإن إلغاء الوصول قد يحد من بعض ميزات التطبيق.",
      "Data Deletion and Privacy": "حذف البيانات والخصوصية",
      "We respect your privacy and offer the ability to delete your personal data and images from our systems upon request. To initiate a data deletion, simply send us an email from the address you used when placing your order. We will ensure that all associated data is removed from our servers. Our privacy practices comply with relevant data protection laws, including the General Data Protection Regulation (GDPR) for users located in the European Union.": "نحن نحترم خصوصيتك ونقدم لك خيار حذف بياناتك الشخصية وصورك من أنظمتنا عند الطلب. لبدء عملية الحذف، أرسل لنا بريدًا إلكترونيًا من العنوان الذي استخدمته عند تقديم الطلب. سنقوم بإزالة جميع البيانات المرتبطة بك من خوادمنا.",
      "Content Restrictions": "القيود على المحتوى",
      "You are responsible for ensuring that the images you upload are your own and do not contain prohibited content, such as pornography, discriminatory material, or any content that infringes upon third-party intellectual property rights. Vivid Visions reserves the right to refuse to print or remove any content that violates these terms. If any legal disputes arise from the content you upload, you will be responsible for any related legal costs or claims.": "أنت مسؤول عن التأكد من أن الصور التي تقوم بتحميلها هي ملكك الخاص ولا تحتوي على محتوى محظور مثل المواد الإباحية أو المواد التمييزية. تحتفظ Vivid Visions بالحق في رفض طباعة أو إزالة أي محتوى ينتهك هذه الشروط. إذا نشأت أي نزاعات قانونية بسبب المحتوى الذي قمت بتحميله، ستكون مسؤولًا عن أي تكاليف قانونية ذات صلة.",
      "Support and Issue Resolution": "الدعم وحل المشكلات",
      "If you encounter any issues with your order or the products you receive, we encourage you to contact our support team via email or WhatsApp. Our team is dedicated to resolving issues promptly, and we will work closely with you to ensure any problems are addressed efficiently. Response times may vary depending on the nature of your inquiry, but we strive to provide effective support for all customer concerns.": "إذا واجهت أي مشكلات في طلبك أو المنتجات التي استلمتها، نشجعك على التواصل مع فريق الدعم لدينا عبر البريد الإلكتروني أو WhatsApp. يلتزم فريقنا بحل المشكلات بسرعة، وسنعمل معك لضمان معالجة أي مشكلات بشكل فعال. قد تختلف أوقات الاستجابة بناءً على طبيعة الاستفسار، لكننا نسعى لتقديم دعم فعال لجميع استفسارات العملاء.",
      "Use of Services and Compliance": "استخدام الخدمات والامتثال",
      "By using Vivid Visions services, you agree to comply with all applicable laws and regulations. You are also responsible for ensuring that the content you upload does not violate any intellectual property rights, privacy rights, or applicable laws. We may modify these terms at any time, and it is your responsibility to stay informed about any changes. Continued use of our services after such changes will constitute your acceptance of the revised terms.": "باستخدام خدمات Vivid Visions، فإنك توافق على الامتثال لجميع القوانين واللوائح المعمول بها. يجب أن تكون مسؤولاً عن ضمان أن المحتوى الذي تقوم بتحميله لا ينتهك أي حقوق ملكية فكرية أو حقوق خصوصية أو أي قوانين معمول بها.",
      "Account Security and Responsibility": "أمان الحساب والمسؤولية",
      "You are solely responsible for maintaining the security of your account. This includes ensuring that your account credentials are kept confidential and that your account information remains up to date. Any actions taken through your account are your responsibility, and you are liable for any misuse, including unauthorized access. If you suspect that your account has been compromised, you must notify Vivid Visions immediately to prevent further unauthorized use.": "أنت المسؤول الوحيد عن الحفاظ على أمان حسابك. يجب أن تحافظ على سرية بيانات الحساب وأن تضمن تحديث معلومات الحساب بانتظام. إذا اشتبهت في أن حسابك قد تعرض للاختراق، يجب عليك إخطار Vivid Visions على الفور.",
      "Subscriptions and Payments": "الاشتراكات والمدفوعات",
      "If you subscribe to Vivid Visions services, your subscription will automatically renew at the end of each billing cycle unless you cancel it before the renewal date. Subscription fees will be charged to the payment method you provided during registration. We do not offer refunds for partial subscription periods, but we will provide refunds in cases of product errors or damage. If you wish to cancel your subscription, please contact our support team before the renewal date to avoid further charges.": "إذا كنت مشتركًا في خدمات Vivid Visions، فسيتم تجديد اشتراكك تلقائيًا ما لم تقم بإلغائه قبل تاريخ التجديد. سيتم خصم رسوم الاشتراك من طريقة الدفع التي قدمتها عند التسجيل. لا نقدم استردادًا للفترات الجزئية، ما لم يكن هناك خطأ أو تلف في المنتج.",
      "Refunds and Returns": "رد الأموال والإرجاع",
      "Vivid Visions offers refunds or replacements if the product you receive is damaged, defective, or does not meet the specifications of your order. In such cases, please contact our support team and provide relevant details, including photos of the product if necessary. We do not offer refunds if you change your mind after placing an order or if there are user errors during the ordering process, unless otherwise required by applicable law.": "تقدم Vivid Visions رد أموال أو استبدال إذا كانت المنتجات التي استلمتها تالفة أو لا تطابق المواصفات. إذا ارتكبت خطأ في الطلب أو غيرت رأيك، فلن تكون مؤهلاً لاسترداد الأموال، إلا إذا كان ذلك مطلوبًا بموجب القانون المعمول به.",
      "Third-Party Links and Services": "الروابط والخدمات الخارجية",
      "Vivid Visions may provide links to third-party websites or services as part of our app functionality. Please note that we are not responsible for the content, privacy policies, or practices of these third-party sites. If you choose to engage with third-party services through our app, you do so at your own risk. We encourage you to review the terms and privacy policies of any third-party services you access through Vivid Visions.": "قد تقدم Vivid Visions روابط لمواقع أو خدمات خارجية كجزء من وظائف التطبيق. نحن لسنا مسؤولين عن المحتوى أو السياسات أو الممارسات الخاصة بهذه المواقع الخارجية. إذا اخترت استخدام خدمات طرف ثالث من خلال تطبيقنا، فإن ذلك يكون على مسؤوليتك الخاصة.",
      "Limitation of Liability": "الحد من المسؤولية",
      "Vivid Visions will not be held liable for any indirect, incidental, special, or consequential damages arising from the use of our services, products, or any content you upload. This includes, but is not limited to, damages related to loss of data, personal injury, or issues with product quality. Our liability is limited to the amount you paid for the services in the 12 months preceding your claim. This limitation does not apply in cases of gross negligence or willful misconduct on our part.": "لن تتحمل Vivid Visions المسؤولية عن أي أضرار غير مباشرة أو عرضية أو خاصة أو ناتجة عن استخدام خدماتنا أو منتجاتنا أو أي محتوى تقوم بتحميله. يقتصر مسؤوليتنا على المبلغ الذي دفعته مقابل الخدمات خلال الـ 12 شهرًا السابقة لادعائك.",
      "GDPR and Privacy Compliance": "الامتثال للائحة العامة لحماية البيانات (GDPR)",
      "For users in the European Union, Vivid Visions fully complies with the General Data Protection Regulation (GDPR). We are committed to protecting your personal data and providing transparency in how it is collected, used, and stored. You have the right to access, modify, or delete your personal data at any time. Images uploaded but not printed will be automatically deleted from our systems after 30 days.": "بالنسبة للمستخدمين في الاتحاد الأوروبي، نحن نلتزم تمامًا باللائحة العامة لحماية البيانات (GDPR). نحن ملتزمون بحماية بياناتك الشخصية وتوفير الشفافية في كيفية جمعها واستخدامها وتخزينها. لديك الحق في الوصول إلى بياناتك أو تعديلها أو حذفها في أي وقت.",
      "Intellectual Property": "الملكية الفكرية",
      "You retain full intellectual property rights to the images you upload. However, by using our services, you grant Vivid Visions a limited, non-exclusive, royalty-free license to use, reproduce, and print your images for the purpose of fulfilling your order. Vivid Visions owns all intellectual property rights related to the app and services, including trademarks, service marks, and content, unless otherwise stated.": "تحتفظ بالحقوق الكاملة للملكية الفكرية المتعلقة بالصور التي تقوم بتحميلها. ومع ذلك، باستخدام خدماتنا، فإنك تمنح Vivid Visions حقًا محدودًا لاستخدام صورك لغرض تنفيذ طلبك فقط.",
      "Account Termination": "إنهاء الحساب",
      "Vivid Visions reserves the right to suspend or terminate your account at any time if you breach these terms or engage in fraudulent, abusive, or illegal behavior. Account suspension or termination does not affect your obligations under these terms, including indemnification, liability, and payment responsibilities.": "تحتفظ Vivid Visions بالحق في تعليق أو إنهاء حسابك في أي وقت إذا انتهكت هذه الشروط أو تورطت في سلوك غير قانوني أو احتيالي.",
      // FAQsList 
      "What is Vivid Visions?": "ما هو Vivid Visions؟",
      "Vivid Visions offers a hassle-free solution for turning your digital images into beautiful photo tiles. Choose from a variety of designs and styles to match your decor, and enjoy the flexibility of adhesive backing, which allows you to rearrange your tiles without damaging the walls.": "Vivid Visions تقدم لك حلاً مريحاً لتحويل صورك الرقمية إلى بلاطات صور رائعة جاهزة للعرض على الجدران. اختر من بين مجموعة متنوعة من التصميمات لتناسب ديكور منزلك. والأفضل من ذلك، أن لاصقنا الخلفي يضمن عدم تلف الجدران، مما يمنحك حرية إعادة ترتيب بلاطات الصور بكل سهولة وقتما تشاء.",
      "How to Make a Purchase?": "كيف يمكنني الشراء؟",
      "Upload your images, pick your preferred style, and proceed to checkout. Vivid Visions provides a user-friendly process for designing and ordering photo tiles.": "إذا كنت هنا، فقد وصلت إلى موقعنا بنجاح! ما عليك سوى الانتقال إلى قائمة \"البدء في التصميم\"، ثم قم بتحميل صورك، واختيار النمط الذي تفضله، ودع الباقي علينا!",
      "Details About the Tiles": "معلومات عن البلاطات",
      "Vivid Visions offers various sizes and frame options, including black, white, wood-toned, and frameless, as well as canvas options. You can also customize with mat shapes, colors, and collage effects.": "نقدم مجموعة واسعة من الأحجام والتصميمات، تتراوح من 8.4 × 8.4 إلى 27 × 36. يمكنك الاختيار من بين إطارات سوداء أو بيضاء أو بألوان الخشب، أو اختيار التصميمات بدون إطار، أو حتى الخيارات القماشية. نقدم أيضًا خيارات متعددة للألوان والتأثيرات على الصور والكولاجات!",
      "Promotional Codes": "كيف تعمل رموز الخصم؟",
      "Discounts vary depending on your selection, and you can see your savings during checkout. Subscribe to the newsletter for exclusive deals.": "تختلف الخصومات بناءً على الحجم والمواد التي تختارها. ولكن ستتمكن دائمًا من رؤية المبلغ الذي تم توفيره عند التقدم في عملية الطلب وفي صفحة الدفع.",
      "Making Changes to Your Order": "تعديلات على الطلب بعد الشراء",
      "You can edit or update your order if it hasn't been processed yet. Simply go to \"My Orders\" on the app or website, and make changes to the delivery address, style, or photos.": "يمكنك تعديل أو تغيير الطلب طالما أنه لم يبدأ معالجته بعد. في تطبيق الجوال، انتقل إلى \"طلباتي\" لإجراء التعديلات مثل تحرير الصور أو تحديث العنوان. إذا فاتتك فرصة التعديل، يمكنك التواصل معنا عبر البريد الإلكتروني وسنكون سعداء بمساعدتك.",
      "What to Do After Receiving Your Vivid Visions": "ماذا بعد وصول بلاطات Vivid Visions",
      "Instructions for applying the adhesive photo tiles to your walls, whether using sticky strips or magnetic adhesives.": "افحص اللاصق الخلفي أولاً. إذا كان لاصقًا شريطيًا، نظف الحائط بقطعة قماش جافة ثم ضع البلاطة بالضغط على الحائط. إذا كان لاصقًا مغناطيسيًا، ضع البلاطة في المكان المراد وألصق المغناطيس.",
      "Not Satisfied?": "ماذا أفعل إذا لم تعجبني البلاطات؟",
      "Vivid Visions offers a \"Happiness Guarantee.\" If you are not satisfied with your product, you can contact them for support.": "نقدم لك ضمان السعادة. إذا لم تكن راضيًا عن المنتج لأي سبب، لا تتردد في التواصل معنا، حيث أن سعادتك هي أولويتنا.",
      "Shipping": "الشحن",
      "Worldwide shipping is available, and sometimes there may be a small fee for smaller orders. Expedited shipping is offered in some countries, and there are no unexpected charges for Canadian customers.": "نقدم شحنًا مجانيًا حول العالم، وفي بعض الحالات قد تكون هناك رسوم صغيرة للطلبات الصغيرة. الشحن السريع متاح في بعض الدول. ولن تكون هناك أي رسوم غير متوقعة للعملاء الكنديين.",
      "Order Tracking": "كيف يمكنني تتبع طلبي؟",
      "You will receive email notifications with updates on your order’s progress, including tracking information.": "سوف نرسل لك إشعارات بالبريد الإلكتروني حول تقدم طلبك، بما في ذلك رابط تتبع لمتابعة حالة الطلب.",
      // LandingArt 
      "Award-Winning Gallery Art": "معرض فني حائز على جائزة",
      "Art For All Seasons, Style For Every Reason.": "لا ترضى بالمتوسط",
      "SHOP NOW": "تسوق الآن",
      // Promo
      "Autumn Sale": "تخفيضات الخريف",
      "20% OFF Everything + 15% EXTRA OFF every second poster - use code: AUTUMN": "خصم 20% على كل شيء + خصم إضافي 15% على كل ملصق ثانٍ - استخدم الكود: AUTUMN",
      // LandingGallery 
      "Transform your walls with stunning, one-of-a-kind art from our exclusive collection.": "قم بتزيين جدرانك بالفن المذهل من معرضنا الحصري.",
      "Discover unique masterpieces that will elevate your space into a stunning visual experience.": "اكتشف روائع فريدة من نوعها من شأنها أن تحول مساحتك إلى تجربة بصرية مذهلة.",
      "Shop Now": "استمتع بالفنون",
      "Browse our collection and bring the beauty of art into your home today!": "تصفح مجموعتنا وأضف جمال الفن إلى منزلك اليوم!",
      // PhotoItem
      "Out of stack": "غير متوفر",
      "Left in stack": "متبقي",
      "Limited Stock Ended": "انتهت الكمية المحدودة",
      "Limited Release": "إصدار محدود",
      // OfferTimer 
      "black friday prices": "أسعار الجمعة السوداء",
      "While stock lasts": "بينما يستمر المخزون",
    },
  },
};
const lng = localStorage.getItem("vividVisionsLang") || "English";
i18n.use(initReactI18next).init({
  resources,
  lng: lng,
  fallbackLng: lng,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
