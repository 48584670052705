import React from "react";
import Skeleton from "react-loading-skeleton";
import BreakDiv from "../../../Shared/Components/BreakDiv";

function ReviewSkeleton() {
  return (
    <div className="my-10">
      <div className="h-[40px] rounded-xl mb-4 w-[50%] max-sm:w-[80%]">
        <Skeleton className="h-full" />
      </div>
      <div className="flex flex-col gap-3">
        {Array.from({ length: 10 }).map((_, index) => (
          <div className="flex flex-col gap-2">
            <div key={index} className="flex items-center gap-2">
              <div className="rounded-full w-[50px] h-[50px] overflow-hidden">
                <Skeleton width={`100%`} className="h-full" />
              </div>
              <div
                className={`f-semi-bold text-size20 max-md:text-size18 max-vsm:text-size16`}
              >
                <Skeleton width={150} height={20} />
              </div>
            </div>
            <div className="flex gap-2">
              <div className="w-[120px] max-w-[120px] h-[140px] max-h-[140px] rounded-[6px]">
                <Skeleton width={`100%`} className="h-full" />
              </div>
              <div className="w-[200px] max-w-[300px] h-[30px] rounded-[6px]">
                <Skeleton width={`100%`} className="h-full max-w-full" />
              </div>
            </div>
            <Skeleton width={`100%`} height={20} />
            <Skeleton width={150} height={20} />
            <BreakDiv className="mt-3" />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ReviewSkeleton;
