import React, { useContext } from "react";
import SizeBoxItem from "./SizeBoxItem";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { PhotoContext } from "../../Context/PhotoContext";
import { useTranslation } from "react-i18next";
import { updatePhotoInCart } from "../../Api/Editphotos";
import { useTheme } from "../../Context/ThemeContext";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";

function SizeList({ handleOpenPopup }) {
  const { id } = useParams();
  const { theme } = useTheme();
  const { t } = useTranslation();
  const {
    setUpdateTotalPrice,
    setUpdatePhotoArray,
    selectedSize,
    setSelectedSize,
    sizes,
    sizesLoading,
    setCurrentSize,
  } = useContext(PhotoContext);

  const handleSizeClick = async (sizeId) => {
    setSelectedSize(sizeId);
    const requestBody = {
      size_id: sizeId,
    };

    try {
      const response = await updatePhotoInCart(
        id ? `updateCartItem?id=${id}` : `updateCartItem`,
        requestBody,
        id
      );
      // console.log("Success:", response.data);
      setCurrentSize(sizeId);
      setUpdateTotalPrice((prev) => prev + 1);
      setUpdatePhotoArray((prev) => prev + 1);
    } catch (error) {
      console.error("Error updating photo in cart:", error);
    }
  };

  return (
    <div
      className={`${
        theme === "dark"
          ? "bg-DarkMainBg text-DarkMainText"
          : "text-MainText bg-MainBg"
      } 
        w-full vsm:max-w-[400px] r-12 min-w-[260px] flex flex-col max-h-full`}
    >
      <div className="flex flex-col gap-[6px]">
        <div className="flex justify-between items-center m-[20px]">
          <h1
            className={`${
              theme === "dark" ? "text-DarkMainText/70" : "text-SecondText"
            } font-[600] text-size16 leading-[16px]`}
          >
            {t("Choose Size")}:
          </h1>
          <div
            onClick={() => handleOpenPopup("size")}
            className="text-PrimaryColor c-pointer p-[1em] -m-[1em]"
          >
            {t("Completed")}
          </div>
        </div>
      </div>
      <div className="flex px-[20px] pb-[24px] gap-[12px] overflow-hidden w-full sizeSwiper">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={22}
          slidesPerView={"auto"}
          navigation
        >
          {sizesLoading
            ? Array.from({ length: 10 }).map((_, index) => (
                <SwiperSlide
                  key={index}
                  className="min-w-[80px] max-w-[80px] min-h-[108px] rounded-[5px]"
                >
                  <div className="w-full h-full">
                    <Skeleton width={`100%`} className="h-full" />
                  </div>
                </SwiperSlide>
              ))
            : sizes.map((size) => (
                <SwiperSlide
                  key={size.id}
                  className="min-w-[80px] max-w-[80px]"
                >
                  <SizeBoxItem
                    onClick={() => handleSizeClick(size.id)}
                    isActive={selectedSize === size.id}
                    widthCm={size.width_cm}
                    lengthCm={size.length_cm}
                    widthInch={size.width_inch}
                    lengthInch={size.length_inch}
                  />
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
    </div>
  );
}

export default SizeList;
