import React, { useContext, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { PopupContext } from "../../Context/PopupContext";
import ProcessSpinner from "../../Shared/Components/ProcessSpinner";
import { useTheme } from "../../Context/ThemeContext";
import { toastError } from "../../Shared/Components/ToastNotification/Toast";
import { updatePhotoItem } from "../../Api/Dashboard";

function UpdatePhotoItem({
  id,
  itemName,
  itemDescription,
  itemPrice,
  itemDiscount,
  fetchedCategories,
  refreshArt,
}) {
  const { theme } = useTheme();
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const [categories, setCategories] = useState(fetchedCategories);
  const [name, setName] = useState(itemName);
  const [category, setCategory] = useState(fetchedCategories[0].id);
  const [description, setDescription] = useState(itemDescription);
  const [price, setPrice] = useState(itemPrice);
  const [priceAfterDiscount, setPriceAfterDiscount] = useState(itemDiscount);
  const { setUpdateItemOpen } = useContext(PopupContext);
  // console.log(name);
  // console.log(description);
  // console.log(category);
  // console.log(price);
  // console.log(priceAfterDiscount);
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (name && description && price && category) {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("category_id", category);
      formData.append("description", description);
      formData.append("price", price);
      formData.append("price_after_discount", priceAfterDiscount);
      try {
        setSpinnerLoad(true);
        const response = await updatePhotoItem(id, formData);
        // console.log(response);
        refreshArt();
      } catch (err) {
        // console.log("update photo item err", err);
      }
      setUpdateItemOpen(false);
    } else {
      toastError("All fields are required");
    }
  };

  return (
    <div
      className={`${
        theme === "dark" && "bg-DarkBoxBg"
      } rounded-[20px] max-md:rounded-none max-h-[673px] overflow-y-auto max-md:min-w-full max-md:overflow-y-hidden max-md:max-h-full md:min-w-[350px] lg:min-w-[600px] w-full`}
    >
      <form
        onSubmit={handleSubmit}
        className={`${
          theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-[#f7f7f7]"
        } flex flex-col pb-[15px]`}
      >
        <div
          className={`${
            theme === "dark" ? "bg-DarkBoxBg" : "md:bg-MainBg"
          } flex justify-between items-center px-[12px] mb-[20px] border-b border-b-[#ebe7e7] font-[700] text-size18 leading-[14px] h-[56px] md:sticky md:top-0 md:left-0`}
        >
          <div
            className={`${
              theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
            } p-[5px] rounded-full`}
          >
            <AiOutlineClose
              className="c-pointer"
              onClick={() => {
                setUpdateItemOpen(false);
              }}
              size={26}
            />
          </div>
          <h1
            className={`${
              theme === "dark" ? "text-DarkMainText" : "text-MainText"
            } md:py-[27px]`}
          >
            Update Item
          </h1>
          <button
            type="submit"
            className={`${
              spinnerLoad && "cursor-default pointer-events-none"
            } flex justify-center items-center h-[52px] text-size18 c-pointer font-[700] text-PrimaryColor hover:underline d-300`}
          >
            {spinnerLoad ? <ProcessSpinner /> : "Done"}
          </button>
        </div>
        <div
          className={`${
            theme === "dark"
              ? "text-DarkMainText/70 bg-DarkBoxBg"
              : "text-SecondText lg:bg-[#f7f7f7]"
          } flex flex-col px-[15px] lg:mt-[7px] lg:mb-[20px] lg:mx-[30px] overflow-y-auto`}
        >
          <div className="flex flex-col">
            <h1 className="mb-[0.25rem]">Item Name</h1>
            <input
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Item Name"
            />

            <h1 className="mb-[0.25rem]">Item Description</h1>
            <textarea
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } min-h-[200px] max-h-[200px] resize-none p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Item Description"
            />
            <h1 className="mb-[0.25rem]">Item Category</h1>
            <select
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              type="text"
              placeholder="Select Category"
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            >
              <option disabled value="">
                Select
              </option>
              {categories &&
                categories.map((cat) => {
                  return (
                    <option className="w-fit" key={cat.id} value={cat.id}>
                      {cat.name}
                    </option>
                  );
                })}
            </select>
            <h1 className="mb-[0.25rem]">Item Price</h1>
            <input
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              type="text"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              placeholder="Item Price"
            />
            <h1 className="mb-[0.25rem]">Item Price After Discount</h1>
            <input
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              type="text"
              value={priceAfterDiscount}
              onChange={(e) => setPriceAfterDiscount(e.target.value)}
              placeholder="Item Price After Discount"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default UpdatePhotoItem;
