import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { triggerPinterestEvent } from "./Home/Utils/pinterestTracking";
import { trackGoogleAds } from "./Home/Utils/googleTracking";
import payWays from "../../assets/imgs/Footer/payWays.png";
import Navbar from "../Components/Navbar";
import {
  addPictureToCart,
  fetchMaterialsPicture,
  fetchPhotos,
  fetchRandomPictures,
  fetchSizesByPictureId,
} from "../../Api/Artphotos";
import ReactPixel from "react-facebook-pixel";
import { useTheme } from "../../Context/ThemeContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, A11y, Thumbs, FreeMode } from "swiper/modules";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/controller";
import "swiper/css/pagination";
import "swiper/css/thumbs";
import "swiper/swiper-bundle.css";
import { PhotoContext } from "../../Context/PhotoContext";
import "react-loading-skeleton/dist/skeleton.css";
import Footer from "../Components/Footer";
import BreakDiv from "../../Shared/Components/BreakDiv";
import Container from "../../Shared/Components/Container";
import {
  toastLoading,
  toastSuccess,
} from "../../Shared/Components/ToastNotification/Toast";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import PhotoItemSkeleton from "../../Shared/Components/Skeleton/PhotoItemSkeleton";
import PhotoItem from "../../Shared/Components/PhotoItem";
import Skeleton from "react-loading-skeleton";
import { PopupContext } from "../../Context/PopupContext";
import PhotoRequest from "../Components/PhotoRequest";
import ReviewsList from "../Components/ReviewsList";

function PhotoDetails() {
  const { t } = useTranslation();
  const currency = localStorage.getItem("currency") || "USD";
  const { theme } = useTheme();
  const { id } = useParams();
  const [photo, setPhoto] = useState({});
  const [randomPhotos, setRandomPhotos] = useState([]);
  const [randomPhotosLoading, setRandomPhotosLoading] = useState([]);
  const [type, setType] = useState(11);
  const [size, setSize] = useState();
  const [totalPrice, setTotalPrice] = useState(0);
  const [sizesLoading, setSizesLoading] = useState(true);
  const [totalPriceLoading, setTotalPriceLoading] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0); // track the selected image
  const [specificationOpen, setSpecificationOpen] = useState(false);
  const [deliveryOpen, setDeliveryOpen] = useState(false);

  const { loading, setLoading, setUpdateTotalPrice } = useContext(PhotoContext);
  const { setPhotoRequestOpen } = useContext(PopupContext);

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
  };
  // Function to toggle the liked status of a specific photo

  const [mats, setMats] = useState([]);
  const [sizes, setSizes] = useState([]);
  const fetchPhoto = async () => {
    setLoading(true);
    try {
      const response = await fetchPhotos(1, id, null, null);
      // console.log(response.data);
      setPhoto(response.data);
    } catch (err) {
      console.error("fetch photo details err", err);
    }
  };

  const fetchMats = async () => {
    try {
      const response = await fetchMaterialsPicture();
      // console.log(response.data);
      setMats(response.data);
    } catch (err) {
      console.error("fetch materials for picture error : ", err);
    } finally {
      setLoading(false);
    }
  };
  const fetchRandomPics = async () => {
    try {
      setRandomPhotosLoading(true);
      const response = await fetchRandomPictures(id);
      // console.log(response.data.data);
      setRandomPhotos(response.data.data);
    } catch (err) {
      console.error("fetch materials for picture error : ", err);
    } finally {
      setRandomPhotosLoading(false);
    }
  };
  const fetchSizesData = async (mat, id) => {
    try {
      setSizesLoading(true);
      setTotalPriceLoading(true);
      const sizesData = await fetchSizesByPictureId(mat, id);
      // console.log(sizesData.data);
      // console.log("mat is : ", mat);
      if (
        sizesData.data.message ===
        "No sizes available for this material. Using material price directly."
      ) {
        setTotalPrice(sizesData.data.price);
      } else {
        const sizeArray = sizesData.data.map((size) => ({
          id: size.id,
          name: `${size.length_inch}x${size.width_inch}" (${size.length_cm}x${size.width_cm}cm)`,
          price: size.pivot.price,
        }));
        setSizes(sizeArray);
        setTotalPrice(sizesData.data[0].pivot.price);
        setSize(sizesData.data[0].id);
      }
    } catch (error) {
      // console.log("fetch sizes error:", error);
    } finally {
      setSizesLoading(false);
      setTotalPriceLoading(false);
      setLoading(false);
    }
  };
  const addPicture = async (picture_id, type, size) => {
    const data = {
      picture_id: picture_id,
      size_id: size,
      material_id: type,
    };
    const toastId = toastLoading(t("Add photo to cart"));
    try {
      const response = await addPictureToCart(data);
      // console.log(response.data);
      if (response.success === "true") {
        toastSuccess(t("The picture has been added to your cart"));
        // Trigger Facebook Pixel AddToCart event here
        ReactPixel.track("AddToCart", {
          content_name: response.data.size_id, // Replace 'Picture Name' with dynamic data if available
          content_ids: [picture_id], // Use actual picture ID
          value: response.data.price || 100.0, // Use actual price from response, default to 100.00 if not available
          currency: "USD", // Replace with actual currency if needed
        });
        // Trigger Pinterest AddToCart event here
        triggerPinterestEvent("addtocart", {
          event_id: "eventId0002", // Update this with your specific event ID
          product_id: picture_id.toString(), // Assuming you need to pass a product ID
          value: response.data.price || 100.0, // Pass the price as value
          currency: "USD",
          em: localStorage.getItem("userId"), // securely hashed or anonymized user ID // The currency of the transaction
        });
        trackGoogleAds({
          value: response.data.price || 0.0, // Adjust based on your data structure
          currency: "USD",
          sent_to: "AW-16751524155/YFRfCNzd7-QZELvy37M-",
        });
      }
    } catch (err) {
      console.error("add picture to cart err", err);
    } finally {
      setUpdateTotalPrice((prev) => prev + 1);
      toast.dismiss(toastId);
    }
  };

  const swiperRef = useRef(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    const sortFetch = async () => {
      await fetchPhoto();
      fetchMats();
      fetchRandomPics();
    };
    sortFetch();
  }, [id]);

  useEffect(() => {
    fetchSizesData(type, id);
  }, [type]);

  return (
    <div
      className={`${
        theme === "dark"
          ? "bg-DarkMainBg text-DarkMainText"
          : "bg-white text-MainText"
      }`}
    >
      <Navbar
        sticky={true}
        className={`${
          theme === "dark" ? "bg-DarkMainBg" : "bg-white"
        } -mb-5 max-lg:mb-0`}
      />
      <Container>
        <div className="mt-[30px]">
          <div className="grid grid-cols-2 gap-3 py-[10px] max-lg:grid-cols-1">
            {loading ? (
              <div className="w-full aspect-square max-h-[600px] mx-auto max-lg:max-h-[450px] max-lg:mb-7 rounded-xl">
                <Skeleton className="h-full rounded-xl" />
              </div>
            ) : (
              <div className="flex gap-2">
                <div>
                  <Swiper
                    direction="vertical"
                    className="thumbnails-swiper w-[110px] max-lg:w-[60px]"
                    slidesPerView={3}
                    watchSlidesVisibility
                    freeMode={true}
                    watchSlidesProgress
                    onSlideChange={(swiper) => {
                      handleThumbnailClick(swiper.activeIndex);
                    }}
                  >
                    {photo?.url_picture?.map((size, index) => (
                      <SwiperSlide
                        className="thumbnails-swiperSlide"
                        key={size.id}
                      >
                        <div
                          className={`${
                            parseFloat(selectedImageIndex) === parseFloat(index)
                              ? "border-PrimaryColor"
                              : "border-transparent hover:border-PrimaryColor"
                          } d-300 border-[3px] w-full max-lg:w-[60px] max-lg:h-[60px] rounded-[6px] overflow-hidden c-pointer`}
                        >
                          <img
                            className="w-full h-full o-cover"
                            src={size.url_image}
                            alt=""
                          />
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
                <Swiper
                  ref={swiperRef}
                  className="w-full max-w-full photoDetailsSwiper"
                  modules={[Pagination, A11y, Thumbs, FreeMode]}
                  spaceBetween={20}
                  slidesPerView={1}
                  initialSlide={3}
                  pagination={{ clickable: true }}
                  onSlideChange={(swiper) => {
                    handleThumbnailClick(swiper.activeIndex);
                  }}
                  thumbs={{ swiper: { el: ".thumbnails-swiper" } }} // Link the thumbnail Swiper instance to the main Swiper instance
                >
                  {photo?.url_picture?.map((size) => {
                    return (
                      <SwiperSlide key={size.id}>
                        <div className="aspect-square">
                          <img
                            className="w-full h-full o-cover"
                            src={size.url_image}
                            alt=""
                          />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            )}

            <div className="flex flex-col lg:mx-[70px]">
              <h1 className="f-bold text-[24px] max-vsm:text-size20 leading-[30px] max-vsmleading-[26px] mb-3">
                {loading ? (
                  <Skeleton className="h-[80px] rounded-xl" />
                ) : (
                  photo.name
                )}
              </h1>
              <h2 className="text-size18 max-vsm:text-size16 leading-[26px] mb-3">
                {loading ? (
                  <Skeleton className="h-[80px] rounded-xl" />
                ) : (
                  photo.description
                )}
              </h2>
              {loading || totalPriceLoading ? (
                <Skeleton width={150} className="h-[27px] mb-1" />
              ) : totalPrice !== 0 ? (
                <div className="flex justify-start items-center font-[500] gap-1 w-full mb-1">
                  <div
                    className={`${
                      theme === "dark"
                        ? "text-DarkMainText/90"
                        : "text-[#0F743B]"
                    } f-semi-bold flex gap-1 items-center text-size18 max-vsm:text-size16`}
                  >
                    {t("Price")}:<h1>{parseFloat(totalPrice).toFixed(2)}</h1>
                    <h1>{currency}</h1>
                  </div>
                  <div
                    className={`${
                      theme === "dark" ? "text-DarkMainText/90" : "text-red-500"
                    } text-size15 max-vsm:text-size13 flex gap-1 items-center line-through text-red-500`}
                  >
                    {t("Regular")}:
                    <h1>
                      {parseFloat(+totalPrice + totalPrice * 0.3).toFixed(2)}
                    </h1>
                    <h1>{currency}</h1>
                  </div>
                </div>
              ) : (
                <div className="flex justify-start items-center font-[500] gap-1 w-full mb-1">
                  <div
                    className={`${
                      theme === "dark"
                        ? "text-DarkMainText/90"
                        : "text-[#0F743B]"
                    } ${
                      photo.price_after_discount !== 0 && "line-through"
                    } f-semi-bold flex gap-1 items-center text-size18 max-vsm:text-size16`}
                  >
                    {t("From")}
                    <h1>
                      {photo.price_after_discount !== 0
                        ? parseFloat(photo.price + photo.price * 0.6).toFixed(2)
                        : parseFloat(photo.price).toFixed(2)}
                    </h1>
                    <h1>{currency}</h1>
                  </div>
                  {photo.price_after_discount !== 0 && (
                    <div
                      className={`${
                        theme === "dark" ? "text-[#94e1b5]" : "text-[#777]"
                      } text-size14 max-vsm:text-size12 flex gap-1 items-center`}
                    >
                      {t("From")}
                      <h1>{parseFloat(photo.price).toFixed(2)}</h1>
                      <h1>{currency}</h1>
                    </div>
                  )}
                </div>
              )}
              {loading ? (
                <Skeleton width={200} className="h-[27px] mb-1" />
              ) : (
                <div className=" flex py-2">
                  <div className="font-[500] text-size18 max-vsm:text-size16 bg-[#ae9c81] t-white px-2 ">
                    {t("Limited Release")}
                  </div>
                  <div
                    className={`${
                      theme === "dark" ? "text-gray-200 " : "text-gray-500 "
                    } text-size17 font-[500] gap-1 max-vsm:text-size12 flex px-1`}
                  >
                    {t("Only")} (
                    <span className="text-[#ae9c81] text-size18 font-[900]">
                      {parseFloat(photo.quentity)}
                    </span>
                    ) {t("Left")}
                  </div>
                </div>
              )}
              {loading ? (
                <Skeleton width={200} className="h-[27px] mb-1" />
              ) : (
                <div className="font-[500] w-fit my-2 text-size18 max-vsm:text-size16 text-red-500">
                  {photo.sold} {t("Items sold in the last 24 hours")}
                </div>
              )}
              <div className="flex flex-col">
                <div
                  className={`${
                    theme === "dark" && "text-DarkMainText"
                  } mb-3 flex items-center f-semi-bold text-size18 max-vsm:text-size16`}
                >
                  {loading ? (
                    <Skeleton width={100} height={20} />
                  ) : (
                    `${t("Type")} : `
                  )}{" "}
                </div>
                <div className="flex items-center flex-wrap gap-1 mb-[20px]">
                  {loading
                    ? Array.from({ length: 5 }).map((_, index) => (
                        <div
                          key={index}
                          className={`flex flex-col gap-1 text-center`}
                        >
                          <div className="w-[100px] h-[100px] rounded-[6px]">
                            <Skeleton width={`100%`} height={`100%`} />
                          </div>
                          <Skeleton width={30} className="h-[20px] my-1" />
                        </div>
                      ))
                    : mats.map((mat) => (
                        <div
                          onClick={() => {
                            setType(mat.id);
                            if (mat.name === "Canvas") {
                              swiperRef.current.swiper.slideTo(1);
                            } else if (mat.name === "Espresso Frame") {
                              swiperRef.current.swiper.slideTo(0);
                            } else if (mat.name === "Black Frame") {
                              swiperRef.current.swiper.slideTo(2);
                            } else if (mat.name === "Poster") {
                              swiperRef.current.swiper.slideTo(3);
                            } else if (mat.name === "White Frame") {
                              swiperRef.current.swiper.slideTo(4);
                            }
                          }}
                          className={`flex flex-col items-center c-pointer group`}
                        >
                          <div
                            className={`${
                              mat.id === type
                                ? "border-PrimaryColor rounded-t-[6px]"
                                : "border-MainText group-hover:border-PrimaryColor rounded-[6px] group-hover:rounded-b-none"
                            } border-[2px] w-[100px] h-[100px] d-300 overflow-hidden`}
                          >
                            <img
                              className="w-full h-full o-cover"
                              src={mat.image}
                              alt=""
                            />
                          </div>
                          <div
                            className={`${
                              mat.id === type
                                ? "bg-PrimaryColor t-white"
                                : theme === "dark"
                                ? "text-DarkMainText group-hover:bg-PrimaryColor group-hover:t-white"
                                : "text-MainText group-hover:bg-PrimaryColor group-hover:t-white"
                            } text-size12 w-full flex justify-center items-center rounded-b-[6px] d-300 py-1`}
                          >
                            {mat.name}
                          </div>
                        </div>
                      ))}
                </div>
              </div>
              {type !== 11 && (
                <div className="flex flex-col">
                  <div
                    className={`${
                      theme === "dark" && "text-DarkMainText"
                    } mb-3 flex items-center f-semi-bold text-size18 max-vsm:text-size16`}
                  >
                    {loading ? (
                      <Skeleton width={100} height={20} />
                    ) : (
                      `${t("Size")} : `
                    )}
                  </div>
                  {loading ? (
                    <Skeleton className="rounded-[6px] h-[47.52px] mb-[20px]" />
                  ) : (
                    <select
                      className={`${
                        type === 11
                          ? "pointer-events-none bg-[#c4c4c4] cursor-not-allowed"
                          : theme === "dark"
                          ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText cursor-pointer"
                          : "bg-white border-[#e6e6e6] focus:border-SecondText cursor-pointer"
                      } p-[10px] text-size18 max-vsm:text-size16 rounded-[6px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:outline-none d-300 focus:ring-0`}
                      value={size} // use size as the value, assuming it contains the selected size ID
                      onChange={(e) => {
                        const selectedSize = sizes.find(
                          (option) => option.id === parseInt(e.target.value)
                        ); // find the selected size object
                        setSize(selectedSize.id); // update the size state with the selected size's ID
                        setTotalPrice(selectedSize.price); // update total price based on the selected size
                      }}
                    >
                      {sizesLoading ? (
                        <Skeleton width={`100%`} className="h-[47.52px]" />
                      ) : (
                        <>
                          <option className="w-fit" disabled value="">
                            {t("Select size")}
                          </option>
                          {sizes &&
                            sizes.map((sizeOption) => (
                              <option
                                className="w-fit"
                                key={sizeOption.id}
                                value={sizeOption.id}
                              >
                                {sizeOption.name}
                              </option>
                            ))}
                        </>
                      )}
                    </select>
                  )}
                </div>
              )}
              {loading ? (
                <Skeleton className="rounded-[6px] h-[51px]" />
              ) : (
                <button
                  onClick={() =>
                    photo.quentity !== 0
                      ? addPicture(id, type, size)
                      : setPhotoRequestOpen(true)
                  }
                  className={`${
                    theme === "dark"
                      ? "bg-PrimaryColor hover:bg-HoverPrimaryColor"
                      : "bg-[#261f23] hover:bg-[#42363d]"
                  } rounded-[6px] t-white text-size18 max-md:text-size16 font-[700] h-[51px] py-[6px] px-[16px] min-w-[64px] w-full d-300`}
                >
                  {photo.quentity === 0 ? t("Required") : t("Add to cart")}
                </button>
              )}
              <div className="max-w-[328px] max-h-[55px] pt-5 ">
                <img className="w-full h-full o-cover" src={payWays} alt="" />
              </div>
              <BreakDiv className={`my-4`} />

              <div className="flex flex-col gap-2">
                {loading ? (
                  <Skeleton width={`100%`} className={`h-[20px]`} />
                ) : (
                  <div
                    onClick={() => setSpecificationOpen(!specificationOpen)}
                    className="flex justify-between items-center hover:text-PrimaryColor d-300 c-pointer text-size16 max-md:text-size14"
                  >
                    <div className="f-bold">
                      {t("Print & frame specification")}
                    </div>
                    {specificationOpen ? <FaMinus /> : <FaPlus />}
                  </div>
                )}
                <div
                  className={`${
                    specificationOpen ? "h-full block" : "h-0 hidden"
                  } animate-flashing will-change-auto transition-all d-300`}
                >
                  <div className="flex flex-col gap-5">
                    {photo?.specification?.map((item) => (
                      <div
                        key={item.id}
                        className="flex flex-col text-center gap-3"
                      >
                        <div className="flex flex-col gap-2">
                          <div className="font-[700] leading-[19px]">
                            {item.type}
                          </div>
                          <div className="text-size14 leading-[19px] text-[#777]">
                            {item.description}
                          </div>
                        </div>
                        <div className="grid grid-cols-2">
                          {item?.specification?.map((specificationItem) => (
                            <div
                              key={specificationItem.id}
                              className="flex flex-col items-center text-center gap-2 px-[10px]"
                            >
                              <div className="w-[50px] h-[50px] rounded-full">
                                <img
                                  className="w-full h-full o-cover"
                                  src={specificationItem.url_icon}
                                  alt=""
                                />
                              </div>
                              <div className="font-[600] leading-[1.2]">
                                {specificationItem.title}
                              </div>
                              <div className="text-size14 leading-[18px] text-[#777]">
                                {specificationItem.description}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <BreakDiv className={`my-4`} />
              <div className="flex flex-col gap-2">
                {loading ? (
                  <Skeleton width={`100%`} className={`h-[20px]`} />
                ) : (
                  <div
                    onClick={() => setDeliveryOpen(!deliveryOpen)}
                    className="flex justify-between items-center hover:text-PrimaryColor d-300 c-pointer text-size16 max-md:text-[14]"
                  >
                    <div className="f-bold">{t("Delivery & returns")}</div>
                    {deliveryOpen ? <FaMinus /> : <FaPlus />}
                  </div>
                )}
                <div
                  className={`${
                    deliveryOpen ? "h-full block" : "h-0 hidden"
                  } animate-flashing will-change-auto transition-all d-300`}
                >
                  <div className="flex flex-col text-center gap-3">
                    <div className="flex flex-col gap-2">
                      <div className="font-[700] leading-[19px]">
                        {t("SHIPPING")}
                      </div>
                      <div className="text-size14 leading-[19px] text-[#777]">
                        {photo?.shipping_details?.description}
                      </div>
                    </div>
                    <table className="border border-[#dbdbdb] text-size12 text-center">
                      <thead
                        className={`${
                          theme === "dark" ? "bg-[#454545]" : "bg-white"
                        }`}
                      >
                        <tr>
                          <th className="border border-[#dbdbdb] p-[6px]">
                            {t("Destination")}
                          </th>
                          <th className="border border-[#dbdbdb] p-[6px]">
                            {t("Delivery time")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {photo?.shipping_details?.[0]?.shipping_details?.map(
                          (item, index) => (
                            <tr
                              key={item.id}
                              className={`${
                                index % 2 !== 0
                                  ? theme === "dark"
                                    ? "bg-DarkMainBg"
                                    : "bg-white"
                                  : theme === "dark"
                                  ? "bg-[#5c5c5c]"
                                  : "bg-[#f4f4f499]"
                              }`}
                            >
                              <td className="border border-[#dbdbdb] p-[6px]">
                                {item.destination}
                              </td>
                              <td className="border border-[#dbdbdb] p-[6px]">
                                {item.delivery_time}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                    <div
                      className={`${
                        theme === "dark" ? "text-gray-200" : "text-[#777]"
                      } italic text-size12`}
                    >
                      {t(
                        "These times are estimated and may be longer, especially during peak periods or holidays. Shipping costs are calculated at checkout. Rates may differ depending on your payment currency"
                      )}
                    </div>
                    <BreakDiv />
                    <div className="font-[700] leading-[19px]">
                      {t("RETURNS")}
                    </div>
                    <div className="text-size14 flex flex-col gap-2">
                      <div className="font-[700]">
                        {t("My item arrived damaged")}
                      </div>
                      <div
                        className={`${
                          theme === "dark" ? "text-gray-200" : "text-[#777]"
                        }`}
                      >
                        {t(
                          "Very rarely items can be damaged in transit, but don't worry, just contact us within 14 days of the item's delivery and we'll send you a free express replacement"
                        )}
                      </div>
                    </div>
                    <div className="text-size14 flex flex-col gap-2">
                      <div className="font-[700]">
                        {t(
                          "I ordered a print or frame, but changed my mind and I want to return it"
                        )}
                      </div>
                      <div
                        className={`${
                          theme === "dark" ? "text-gray-200" : "text-[#777]"
                        }`}
                      >
                        {t(
                          "Don't worry, we get it! Contact us within 14 days of receiving your order and we can discuss your options to return it for a full refund"
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-2 my-10 z0">
            <div className="f-semi-bold text-size20 max-md:text-size18 max-vsm:text-size16">
              {t("Related Photos")} :
            </div>
            <div>
              {randomPhotosLoading ? (
                <div
                  style={{ direction: "ltr" }}
                  className="grid grid-cols-5 max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 gap-x-[1%] gap-y-3 w-full"
                >
                  {Array.from({ length: 5 }).map((_, index) => (
                    <PhotoItemSkeleton key={index} />
                  ))}
                </div>
              ) : randomPhotos && randomPhotos.length > 0 ? (
                <div className="flex flex-col gap-2">
                  <div className="grid grid-cols-5 max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 gap-x-[1%] gap-y-3 w-full">
                    {randomPhotos.map((product, index) => {
                      return (
                        <PhotoItem
                          className={`${index > 4 && "xl:hidden"} ${
                            index > 7 && "max-xl:hidden"
                          } ${index > 5 && "max-lg:hidden"}`}
                          link={`/art-photos/${product.id}`}
                          key={index}
                          image={product?.url_picture[0]?.url_image}
                          imageHover={product?.url_picture[1]?.url_image}
                          title={product.name}
                          category={product.category}
                          description={product.description}
                          price={product.price}
                          rate_value={product.rate_value}
                          discount={product.price_after_discount}
                          quentity={product.quentity}
                        />
                      );
                    })}
                  </div>
                  <Link
                    to={`/art-photos`}
                    className="text-size18 max-md:text-size16 max-vsm:text-size14 c-pointer underline hover:text-DarkMainBg d-300 mx-auto"
                  >
                    {t("See more")}
                  </Link>
                </div>
              ) : (
                <div className="text-[24px] h-[700px] w-full flex justify-center items-center">
                  <h1>{t("Sorry, No related photos available")}</h1>
                </div>
              )}
            </div>
          </div>
          <ReviewsList />
        </div>
      </Container>
      <Container>
        <Footer />
      </Container>
      <PhotoRequest id={id} />
    </div>
  );
}

export default PhotoDetails;
