import React from "react";

function BreakDiv({ className }) {
    return (
      <div
        className={`${className} w-full max-md:w-[50%] max-md:mx-auto block bg-[#ebe7e7] h-[2px]  `}
      ></div>
    );
}

export default BreakDiv;
