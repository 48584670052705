import React, { useContext, useEffect, useState } from "react";
import { fetchCategories, fetchPhotos } from "../../../../Api/Artphotos";
import PhotoItemSkeleton from "../../../../Shared/Components/Skeleton/PhotoItemSkeleton";
import { Link, useNavigate } from "react-router-dom";
import PhotoItem from "../../../../Shared/Components/PhotoItem";
import { useTranslation } from "react-i18next";
import CategoryItem from "../../../../Shared/Components/CategoryItem";
import CategorySkeleton from "../../../../Shared/Components/Skeleton/CategorySkeleton";
import { PhotoContext } from "../../../../Context/PhotoContext";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Skeleton from "react-loading-skeleton";


function ArtPhotosHome({ originalHome, categoryId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const { setCategoryId, setCurrentStatusId } = useContext(PhotoContext);
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const userId = localStorage.getItem("userId");
  const fetchCategoriesData = async () => {
    try {
      setCategoriesLoading(true);
      const categoriesData = await fetchCategories();
      // console.log(categoriesData.data);
      setCategories(categoriesData.data);
    } catch (error) {
      console.error("Error fetching categories data:", error);
    } finally {
      setCategoriesLoading(false);
    }
  };
  const fetchPhotosData = async () => {
    try {
      setLoading(true);
      const response = await fetchPhotos(
        originalHome ? 9 : 8,
        null,
        originalHome ? null : categoryId,
        1
      );
      // console.log(response);
      setPhotos(response.data);
    } catch (err) {
      console.error("fetch photos error : ", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    originalHome && fetchCategoriesData();
    fetchPhotosData();
    window.pintrk &&
      window.pintrk("track", "ArtPagevisit", {
        event_id: "eventId0004",
        em: userId,
      });
  }, []);
  return (
    <div className="flex flex-col gap-5 my-5">
      {originalHome && (
        <div style={{ direction: "ltr" }} className="artHome">
          <Slider
            slidesToShow={7}
            infinite={true}
            speed={400}
            dots={false}
            arrows={false}
            responsive={[
              {
                breakpoint: 1620,
                settings: {
                  slidesToShow: 7,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 1280,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 1,
                  arrows: true,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  arrows: true,
                },
              },
              {
                breakpoint: 640,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                  arrows: true,
                },
              },
              {
                breakpoint: 400,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  arrows: true,
                },
              },
              {
                breakpoint: 0,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  arrows: true,
                },
              },
            ]}
          >
            {categoriesLoading
              ? Array.from({ length: 7 }).map((_, index) => (
                  <div
                    className="h-fit flex justify-center items-center p-4"
                    key={index}
                  >
                    <CategorySkeleton />
                  </div>
                ))
              : categories &&
                categories.map((item) => (
                  <div
                    key={item.id}
                    className="category-slide-item group p-4 transition d-300 ease-in-out transform hover:scale-105"
                  >
                    <CategoryItem
                      key={item.id}
                      id={item.id}
                      image={item.image}
                      name={item.name}
                      onClick={() => {
                        navigate(`/art-home/${item.id}`);
                      }}
                      homeMode={true}
                    />
                  </div>
                ))}
          </Slider>
        </div>
      )}
      {loading ? (
        <div className="w-[200px] h-[45px] max-md:h-[33px] mx-auto">
          <Skeleton width={`100%`} className="h-full" />
        </div>
      ) : (
        photos.length > 0 && (
          <div className="uppercase text-[30px] max-md:text-size20 tracking-[2px] font-[400] mx-auto">
            {originalHome ? t("Best Seller") : photos[0]?.category?.name}
          </div>
        )
      )}
      <div className="grid grid-cols-4 max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 gap-x-[1%] gap-y-3 w-full">
        {loading
          ? Array.from({ length: originalHome ? 4 : 8 }).map((_, index) => (
              <PhotoItemSkeleton key={index} />
            ))
          : photos.map((product, index) => {
              return (
                <PhotoItem
                  className={`${originalHome && index > 3 && "lg:hidden"} ${
                    !originalHome && index > 7 && "lg:hidden"
                  } ${index > 5 && "max-lg:hidden"}`}
                  link={`/art-photos/${product.id}`}
                  key={index}
                  image={product?.url_picture[0]?.url_image}
                  imageHover={product?.url_picture[1]?.url_image}
                  title={product.name}
                  description={product.description}
                  price={product.price}
                  discount={product.price_after_discount}
                  quentity={product.quentity}
                />
              );
            })}
      </div>
      {loading ? (
        <div className="w-[150px] h-[27px] max-md:h-[24px] mx-auto">
          <Skeleton width={`100%`} className="h-full" />
        </div>
      ) : (
        photos.length > 0 && (
          <Link
            onClick={() => {
              if (categoryId) {
                setCategoryId(categoryId);
                setCurrentStatusId(8);
              }
            }}
            to={`/art-photos`}
            className="text-size18 max-md:text-size16 max-vsm:text-size14 c-pointer underline hover:text-DarkMainBg d-300 mx-auto"
          >
            {t("Show All Photos")}
          </Link>
        )
      )}
    </div>
  );
}

export default ArtPhotosHome;
