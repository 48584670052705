import React, { useState, useEffect, useContext } from "react";
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { PhotoContext } from "../../Context/PhotoContext";
import { PopupContext } from "../../Context/PopupContext";
import Loading from "../../Shared/Components/Loading";
import Popup from "../../Shared/Components/Popup";
import AddNewSize from "../Components/AddNewSize";
import UpdateSize from "../Components/UpdateSize";
import { fetchSizes } from "../../Api/Dashboard";
import MakeDecision from "../../Shared/Components/MakeDecision";
import { useTheme } from "../../Context/ThemeContext";
import Skeleton from "react-loading-skeleton";
import CustomPagination from "../../Shared/Components/CustomPagination";
import DashboardOutlet from "../Components/DashboardOutlet";

function SizesView() {
  const { theme } = useTheme();
  const { loading, setLoading, lazyLoading } = useContext(PhotoContext);
  const {
    addNewSizeOpen,
    setAddNewSizeOpen,
    updateSizeOpen,
    setUpdateSizeOpen,
    makeDecisionOpen,
    setMakeDecisionOpen,
  } = useContext(PopupContext);
  const [refreshKey, setRefreshKey] = useState(Date.now());
  const [sizes, setSizes] = useState([]);
  const [selectedLengthCm, setSelectedLengthCm] = useState("");
  const [selectedWidthCm, setSelectedWidthCm] = useState("");
  const [selectedLengthInch, setSelectedLengthInch] = useState("");
  const [selectedWidthInch, setSelectedWidthInch] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [decisionType, setDecisionType] = useState("");
  const [decisionItemId, setDecisionItemId] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [total, setTotal] = useState("");
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    // console.log(selectedPage);
    setPageNumber(selectedPage);
  };
  const fetchSizesData = async () => {
    try {
      setLoading(true);
      const sizesData = await fetchSizes(pageNumber);
      // console.log(sizesData.data);
      setSizes(sizesData.data.data);
      setTotalPages(sizesData.data.last_page);
      setItemPerPage(sizesData.data.per_page);
      setPageNumber(sizesData.data.current_page);
      setFrom(sizesData.data.from);
      setTo(sizesData.data.to);
      setTotal(sizesData.data.total);
    } catch (error) {
      console.log("Error fetching sizes data:", error);
    } finally {
      setLoading(false); // Ensure loading stops regardless of the fetch outcome
    }
  };
  useEffect(() => {
    fetchSizesData();
  }, [refreshKey, pageNumber]);
  const handleDeleteClick = (id) => {
    setDecisionType("size");
    setDecisionItemId(id);
    setMakeDecisionOpen(true);
  };

  return (
    <DashboardOutlet title={`Sizes`}>
      <div className="flex justify-start">
        <div className="flex pb-[7px] mb-2 ">
          <div
            onClick={() => setAddNewSizeOpen(true)}
            className={`c-pointer bg-PrimaryColor hover:bg-HoverPrimaryColor d-300 t-white font-[700] px-4 py-2 max-md:px-2 max-md:py-1 text-center border text-size15 rounded-[20px] w-fit`}
          >
            Add new size
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <table
          className={`${
            theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-white"
          } w-full table-auto`}
        >
          <thead>
            <tr>
              <th className="border px-4 py-2">Size(cm)</th>
              <th className="border px-4 py-2">Size(inch)</th>
              <th className="border px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: 20 }).map((_, outInex) => (
                <tr key={outInex}>
                  {Array.from({ length: 3 }).map((_, index) => (
                    <td key={index}>
                      <Skeleton width={"100%"} height={35} />
                    </td>
                  ))}
                </tr>
              ))
            ) : sizes && sizes.length > 0 ? (
              sizes.map((size, index) => (
                <tr key={index} className="">
                  <td className="border text-center px-4 py-2">
                    {size.length_cm}x{size.width_cm}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {size.length_inch}x{size.width_inch}
                  </td>

                  <td className="flex gap-2 justify-center items-center border text-center px-4 py-2">
                    <button
                      onClick={() => {
                        setUpdateSizeOpen(true);
                        setSelectedLengthCm(size.length_cm);
                        setSelectedWidthCm(size.width_cm);
                        setSelectedLengthInch(size.length_inch);
                        setSelectedWidthInch(size.width_inch);
                        setSelectedPrice(size.price);
                        setSelectedId(size.id);
                      }}
                      className="bg-blue-500 hover:bg-blue-700 t-white f-bold px-4 py-2 min-h-[40px] flex justify-center items-center d-300 rounded"
                    >
                      <FaUserEdit />
                    </button>
                    <button
                      onClick={() => handleDeleteClick(size.id)}
                      className="bg-red-500 hover:bg-red-700 t-white f-bold px-4 py-2 min-h-[40px] flex justify-center items-center d-300 rounded"
                    >
                      <MdDelete />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <div>No Data</div>
            )}
          </tbody>
        </table>
        <CustomPagination
          totalPages={totalPages}
          currentPage={pageNumber}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
          dashMode={true}
        />
      </div>
      {addNewSizeOpen && (
        <Popup
          toggleSwitch={addNewSizeOpen}
          children={
            <AddNewSize refreshSizes={() => setRefreshKey(Date.now())} />
          }
          overLayClassName="z40"
          className={`z50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] text-MainText d-300 max-w-[769px] max-md:w-full max-md:h-screen rounded-[20px] max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
          onClose={() => setAddNewSizeOpen(false)}
        />
      )}
      {updateSizeOpen && (
        <Popup
          toggleSwitch={updateSizeOpen}
          children={
            <UpdateSize
              selectedLengthCm={selectedLengthCm}
              selectedWidthCm={selectedWidthCm}
              selectedLengthInch={selectedLengthInch}
              selectedWidthInch={selectedWidthInch}
              selectedPrice={selectedPrice}
              selectedId={selectedId}
              refreshSizes={() => setRefreshKey(Date.now())}
            />
          }
          overLayClassName="z40"
          className={`z50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] text-MainText d-300 max-w-[769px] max-md:w-full max-md:h-screen rounded-[20px] max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
          onClose={() => setUpdateSizeOpen(false)}
        />
      )}
      <Popup
        toggleSwitch={makeDecisionOpen}
        children={
          <MakeDecision
            dashboard={true}
            sizes={sizes}
            setSizes={setSizes}
            action="delete"
            type={decisionType}
            itemId={decisionItemId}
            refreshSizes={() => setRefreshKey(Date.now())}
          />
        }
        overLayClassName="z40"
        className={`z50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] rounded-[20px] text-MainText d-300 max-w-[769px] max-md:w-full max-md:h-screen max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
        onClose={() => setUpdateSizeOpen(false)}
      />
    </DashboardOutlet>
  );
}

export default SizesView;
