import React, { useContext } from "react";
import { PhotoContext } from "../../Context/PhotoContext";
import { useTheme } from "../../Context/ThemeContext";

function FeaturesItem({ image, title, description }) {
  const { theme } = useTheme();
  const { handleImageError } = useContext(PhotoContext);

  return (
    <div className={`${theme === "dark" && "text-DarkMainText"} text-center`}>
      <div className="mx-auto w-[60px] h-[60px] max-md:w-[40px] max-md:h-[40px] mb-3">
        <img
          className="w-full h-full o-cover"
          src={image}
          alt=""
          onError={handleImageError}
        />
      </div>
      <h1 className="f-bold text-size18 max-md:text-size14">{title}</h1>
      <p className="text-size16 max-md:text-size12">{description}</p>
    </div>
  );
}

export default FeaturesItem;
