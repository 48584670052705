import React, { useContext, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/controller";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { PopupContext } from "../../Context/PopupContext";
import Loading from "../../Shared/Components/Loading";
import PhotoItem from "../../Shared/Components/PhotoItem";
import Popup from "../../Shared/Components/Popup";
import AddNewPhotoItem from "../Components/AddNewPhotoItem";
import UpdatePhotoItem from "../Components/UpdatePhotoItem";
import AddNewCategory from "../Components/AddNewCategory";
import UpdateCategory from "../Components/UpdateCategory";
import { PhotoContext } from "../../Context/PhotoContext";
import {
  fetchCategories,
  fetchPhotos,
  fetchPhotosByCategoryId,
  fetchSizes,
} from "../../Api/Dashboard";
import ReactPaginate from "react-paginate";
import CategoryItem from "../../Shared/Components/CategoryItem";
import MakeDecision from "../../Shared/Components/MakeDecision";
import Container from "../../Shared/Components/Container";
import PhotoItemSkeleton from "../../Shared/Components/Skeleton/PhotoItemSkeleton";
import CategorySkeleton from "../../Shared/Components/Skeleton/CategorySkeleton";
import { useTheme } from "../../Context/ThemeContext";
import CustomPagination from "../../Shared/Components/CustomPagination";
import DashboardOutlet from "../Components/DashboardOutlet";

function ArtView() {
  const { theme } = useTheme();
  const [categoryRefreshKey, setCategoryRefreshKey] = useState(Date.now());
  const [pictureRefreshKey, setPictureRefreshKey] = useState(Date.now());
  const [photos, setPhotos] = useState([]);
  const [categories, setCategories] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState({
    id: "",
    name: "",
  });
  const [selectedPhotoItem, setSelectedPhotoItem] = useState({
    id: "",
    name: "",
    description: "",
    discount: "",
  });
  const [deletingItem, setDeletingItem] = useState({
    id: "",
    type: "",
  });
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const {
    loading,
    lazyLoading,
    setDashItem,
    categoryLoading,
    setCategoryLoading,
    totalPages,
    setTotalPages,
    itemPerPage,
    setItemPerPage,
    pageNumber,
    setPageNumber,
    paginationKey,
    setPaginationKey,
  } = useContext(PhotoContext);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [total, setTotal] = useState("");
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    // console.log(selectedPage);
    setPageNumber(selectedPage);
  };
  const {
    addNewItemOpen,
    setAddNewItemOpen,
    updateItemOpen,
    setUpdateItemOpen,
    addNewCategoryOpen,
    setAddNewCategoryOpen,
    updateCategoryOpen,
    setUpdateCategoryOpen,
    makeDecisionOpen,
    setMakeDecisionOpen,
  } = useContext(PopupContext);
  const fetchCategoriesData = async () => {
    try {
      setCategoryLoading(true);
      const categoriesData = await fetchCategories();
      // console.log(categoriesData.data);
      setCategories(categoriesData.data);
      setCategoryLoading(false);
    } catch (error) {
      // console.log("Error fetching categories data:", error);
    }
  };
  useEffect(() => {
    setCategories([]);
    lazyLoading();
    setDashItem(true);
    fetchCategoriesData();
  }, [categoryRefreshKey]);
  const fetchPhotosData = async () => {
    try {
      setIsLoadingPage(true);
      const photosData = await fetchPhotos(pageNumber);
      // console.log(photosData.data);
      setPhotos(photosData.data); // Assuming photosData is the object structure returned by the API.
      setTotalPages(photosData.last_page);
      setItemPerPage(photosData.per_page);
      setPageNumber(photosData.current_page);
      setFrom(photosData.from);
      setTo(photosData.to);
      setTotal(photosData.total);
    } catch (error) {
      console.error("Error fetching photos data:", error);
    } finally {
      setIsLoadingPage(false);
    }
  };
  const handleCategoryChange = async (categoryId) => {
    try {
      setIsLoadingPage(true);
      const photosByCategory = await fetchPhotosByCategoryId(
        categoryId,
        pageNumber
      );
      // console.log(photosByCategory.data);
      setPhotos(photosByCategory.data);
      setTotalPages(photosByCategory.last_page);
      setItemPerPage(photosByCategory.per_page);
      setPageNumber(photosByCategory.current_page);
      setFrom(photosByCategory.from);
      setTo(photosByCategory.to);
      setTotal(photosByCategory.total);
    } catch (error) {
      console.error("Error fetching photos data by category:", error);
    } finally {
      setIsLoadingPage(false);
    }
  };
  useEffect(() => {
    if (selectedCategory === null) {
      fetchPhotosData();
    } else {
      handleCategoryChange(selectedCategory);
    }
  }, [selectedCategory, pictureRefreshKey, pageNumber]);
  useEffect(() => {
    const fetchSizesData = async () => {
      try {
        const sizesData = await fetchSizes();
        // console.log(sizesData.data);
        setSizes(sizesData.data);
      } catch (error) {
        // console.log("Error fetching sizes data:", error);
      }
    };
    fetchSizesData();
  }, []);
  const deletePhoto = (id) => {
    setDeletingItem({ id, type: "photo" });
    setMakeDecisionOpen(true);
  };

  const deleteCategory = (id) => {
    setDeletingItem({ id, type: "category" });
    setMakeDecisionOpen(true);
  };
  const editCategory = (id) => {
    // console.log("category id : ", id);
    const category = categories.find((cat) => cat.id === id);
    // console.log("category is : ", category);
    setSelectedCategoryItem(category);
    setUpdateCategoryOpen(true);
  };
  const onEdit = (id) => {
    const photoItem = photos.find((photo) => photo.id === id);
    setSelectedPhotoItem(photoItem);
    setUpdateItemOpen(true);
  };
  return loading ? (
    <Loading />
  ) : (
    <DashboardOutlet title={`Arts`}>
      <div className="flex justify-start">
        <div className="flex gap-[14px] pb-[7px] mb-2 ">
          <div
            onClick={() => setAddNewCategoryOpen(true)}
            className={`c-pointer bg-PrimaryColor hover:bg-HoverPrimaryColor d-300 t-white font-[700] px-4 py-2 max-md:px-2 max-md:py-1 text-center border text-size15 rounded-[20px] w-fit`}
          >
            Add new category
          </div>
          <div
            onClick={() => {
              setAddNewItemOpen(true);
              // console.log(sizes);
            }}
            className={`c-pointer bg-PrimaryColor hover:bg-HoverPrimaryColor d-300 t-white font-[700] px-4 py-2 max-md:px-2 max-md:py-1 text-center border text-size15 rounded-[20px] w-fit`}
          >
            Add new Item
          </div>
          {selectedCategory !== null && (
            <div
              className={`flex justify-center items-center c-pointer bg-PrimaryColor t-white hover:bg-HoverPrimaryColor d-300 font-[700] px-4 py-2 max-md:px-2 max-md:py-1 text-center border text-size15 rounded-[20px] w-fit`}
              onClick={() => {
                setSelectedCategory(null);
                setPageNumber(1);
                setPaginationKey((prev) => prev + 1);
              }}
            >
              View All Photos
            </div>
          )}
        </div>
      </div>
      <div className="force-width pb-[7px] mb-2 artSlide flex justify-center mx-auto max-h-[184px]">
        <Swiper
          className="w-full force-width flex justify-center"
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={0.2}
          breakpoints={{
            1280: {
              slidesPerView: 4.2,
            },
            1024: {
              slidesPerView: 3.2,
            },
            768: {
              slidesPerView: 2.2,
            },
          }}
          navigation
          pagination={{ clickable: true }}
        >
          {categoryLoading
            ? Array.from({ length: 4 }).map((_, index) => (
                <SwiperSlide key={index}>
                  <CategorySkeleton />
                </SwiperSlide>
              ))
            : categories &&
              categories.map((item) => {
                return (
                  <SwiperSlide key={item.id}>
                    <CategoryItem
                      key={item.id}
                      id={item.id}
                      image={item.image}
                      name={item.name}
                      onEdit={() => editCategory(item.id)}
                      onDelete={() => deleteCategory(item.id)}
                      onClick={() => {
                        setSelectedCategory(item.id);
                        setPageNumber(1);
                        setPaginationKey((prev) => prev + 1);
                      }}
                    />
                  </SwiperSlide>
                );
              })}
        </Swiper>
      </div>
      {isLoadingPage ? (
        <div
          style={{ direction: "ltr" }}
          className="grid grid-cols-5 max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 gap-x-[1%] gap-y-3 w-full"
        >
          {Array.from({ length: 25 }).map((_, index) => (
            <PhotoItemSkeleton key={index} />
          ))}
        </div>
      ) : photos && photos.length > 0 ? (
        <div className="grid grid-cols-5 max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 gap-x-[1%] gap-y-3 w-full">
          {photos.map((product, index) => {
            let imageUrl =
              product.url_picture &&
              product.url_picture[0] &&
              product.url_picture[0].url_image;
            return (
              <PhotoItem
                onEdit={() => onEdit(product.id)}
                onDelete={() => deletePhoto(product.id)}
                key={index}
                id={product.id}
                description={product.description}
                isActive={product.isActive}
                image={imageUrl}
                title={product.name}
                rate_value={product.rate_value}
                price={product.price}
                discount={product.price_after_discount}
                quentity={product.quentity}
                refreshArt={() => setPictureRefreshKey(Date.now())}
              />
            );
          })}
        </div>
      ) : (
        <div className="text-2xl h-[700px] w-full flex justify-center items-center">
          <h1>Sorry, No photos available</h1>
        </div>
      )}
      <div className="mt-4">
        <CustomPagination
          key={paginationKey}
          totalPages={totalPages}
          currentPage={pageNumber}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
          dashMode={true}
        />
      </div>
      {addNewItemOpen && (
        <Popup
          toggleSwitch={addNewItemOpen}
          children={
            <AddNewPhotoItem
              fetchedCategories={categories}
              fetchedSizes={sizes}
              refreshArt={() => setPictureRefreshKey(Date.now())}
            />
          }
          overLayClassName="z40"
          className={`z50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] text-MainText d-300 max-w-[769px] max-md:w-full max-md:h-screen rounded-[20px] max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
          onClose={() => setAddNewItemOpen(false)}
        />
      )}
      {updateItemOpen && (
        <Popup
          toggleSwitch={updateItemOpen}
          children={
            <UpdatePhotoItem
              id={selectedPhotoItem.id}
              itemName={selectedPhotoItem.name}
              itemDescription={selectedPhotoItem.description}
              itemPrice={selectedPhotoItem.price}
              itemDiscount={selectedPhotoItem.price_after_discount}
              fetchedCategories={categories}
              refreshArt={() => setPictureRefreshKey(Date.now())}
            />
          }
          overLayClassName="z40"
          className={`z50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] text-MainText d-300 max-w-[769px] max-md:w-full max-md:h-screen rounded-[20px] max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
          onClose={() => setUpdateItemOpen(false)}
        />
      )}
      {addNewCategoryOpen && (
        <Popup
          toggleSwitch={addNewCategoryOpen}
          children={
            <AddNewCategory
              refreshArt={() => setCategoryRefreshKey(Date.now())}
            />
          }
          overLayClassName="z40"
          className={`z50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] text-MainText d-300 max-w-[769px] max-md:w-full max-md:h-screen rounded-[20px] max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
          onClose={() => setAddNewCategoryOpen(false)}
        />
      )}
      {updateCategoryOpen && (
        <Popup
          toggleSwitch={updateCategoryOpen}
          children={
            <UpdateCategory
              id={selectedCategoryItem.id}
              name={selectedCategoryItem.name}
              image={selectedCategoryItem.image}
              refreshArt={() => setCategoryRefreshKey(Date.now())}
            />
          }
          overLayClassName="z40"
          className={`z50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] text-MainText d-300 max-w-[769px] max-md:w-full max-md:h-screen rounded-[20px] max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
          onClose={() => setUpdateCategoryOpen(false)}
        />
      )}
      <Popup
        toggleSwitch={makeDecisionOpen}
        children={
          <MakeDecision
            dashboard={true}
            action={"delete"}
            type={deletingItem.type}
            itemId={deletingItem.id}
            photos={photos}
            setPhotos={setPhotos}
            setCategories={setCategories}
            categories={categories}
            refreshCategories={() => setCategoryRefreshKey(Date.now())}
            refreshPictures={() => setPictureRefreshKey(Date.now())}
          />
        }
        overLayClassName="z40"
        className={`z50 top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] fixed m-[20px] text-MainText d-300 max-w-[769px] max-md:w-full max-md:h-screen rounded-[20px] max-md:rounded-none max-md:m-0 max-md:top-0 max-md:left-0 max-md:translate-x-0 max-md:translate-y-0`}
        onClose={() => setMakeDecisionOpen(false)}
      />
    </DashboardOutlet>
  );
}

export default ArtView;
