import React, { useContext, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { PopupContext } from "../../Context/PopupContext";
import ProcessSpinner from "../../Shared/Components/ProcessSpinner";
import { addCategory } from "../../Api/Dashboard";
import { useTheme } from "../../Context/ThemeContext";

function AddNewCategory({ refreshArt }) {
  const { theme } = useTheme();
  const { setAddNewCategoryOpen } = useContext(PopupContext);
  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState(null);
  const [spinnerLoad, setSpinnerLoad] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!categoryImage) {
      alert("Please select an image for the category.");
      return;
    }
    setSpinnerLoad(true);
    const formData = new FormData();
    formData.append("name", categoryName);
    formData.append("image", categoryImage);

    try {
      const response = await addCategory(formData);
      // console.log(response);
      refreshArt();
    } catch (error) {
      console.error(error);
    } finally {
      setSpinnerLoad(false);
      setAddNewCategoryOpen(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // console.log(`File name: ${file.name}`);
      // console.log(`File type: ${file.type}`);
      // console.log(`File size: ${file.size} bytes`);
      // console.log(`Last modified: ${file.lastModifiedDate}`);

      // Set the file to state
      setCategoryImage(file);
    }
  };

  return (
    <div
      className={`${
        theme === "dark" && "bg-DarkBoxBg"
      } rounded-[20px] max-md:rounded-none max-h-[673px] overflow-y-auto max-md:min-w-full max-md:overflow-y-hidden max-md:max-h-full md:min-w-[350px] lg:min-w-[600px] w-full`}
    >
      <form
        onSubmit={handleSubmit}
        className={`${
          theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-[#f7f7f7]"
        } flex flex-col pb-[15px]`}
      >
        <div
          className={`${
            theme === "dark" ? "bg-DarkBoxBg" : "md:bg-MainBg"
          } flex justify-between items-center px-[12px] mb-[20px] border-b border-b-[#ebe7e7] font-[700] text-size18 leading-[14px] h-[56px] md:sticky md:top-0 md:left-0`}
        >
          <div
            className={`${
              theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
            } p-[5px] rounded-full`}
          >
            <AiOutlineClose
              className="c-pointer"
              onClick={() => setAddNewCategoryOpen(false)}
              size={26}
            />
          </div>
          <h1
            className={`${
              theme === "dark" ? "text-DarkMainText" : "text-MainText"
            } md:py-[27px]`}
          >
            Add New Category
          </h1>
          <button
            type="submit"
            className={`${
              spinnerLoad && "cursor-default pointer-events-none"
            } flex justify-center items-center h-[52px] text-size18 c-pointer font-[700] text-PrimaryColor hover:underline d-300`}
          >
            {spinnerLoad ? <ProcessSpinner /> : "Done"}
          </button>
        </div>
        <div
          className={`${
            theme === "dark"
              ? "text-DarkMainText/70 bg-DarkBoxBg"
              : "text-SecondText lg:bg-[#f7f7f7]"
          } flex flex-col px-[15px] lg:mt-[7px] lg:mb-[20px] lg:mx-[30px] overflow-y-auto`}
        >
          <div className="flex flex-col">
            <label htmlFor="categoryName" className="mb-[0.25rem]">
              Category Name
            </label>
            <input
              id="categoryName"
              className={` ${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none d-300 focus:ring-0`}
              type="text"
              placeholder="Category Name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
            <label htmlFor="categoryImage" className="mb-[0.25rem]">
              Category Image
            </label>
            <input
              id="categoryImage"
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:outline-none d-300 focus:ring-0`}
              type="file"
              onChange={handleFileChange}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddNewCategory;
