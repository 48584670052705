import React, { useState, useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { PopupContext } from "../../Context/PopupContext";
import ProcessSpinner from "../../Shared/Components/ProcessSpinner";
import { addNewSize } from "../../Api/Dashboard";
import { useTheme } from "../../Context/ThemeContext";

function AddNewSize({ refreshSizes }) {
  const { theme } = useTheme();
  const { setAddNewSizeOpen } = useContext(PopupContext);
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const [form, setForm] = useState({
    length_cm: "",
    width_cm: "",
    length_inch: "",
    width_inch: "",
  });
  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    setSpinnerLoad(true);
    e.preventDefault();
    try {
      const response = await addNewSize(form);
      // console.log(response);
    } catch (error) {
      console.error("new size adding err", error);
    }
    setAddNewSizeOpen(false);
    setSpinnerLoad(false);
    refreshSizes();
  };
  return (
    <div
      className={`${
        theme === "dark" && "bg-DarkBoxBg"
      } rounded-[20px] max-md:rounded-none max-h-[673px] overflow-y-auto max-md:min-w-full max-md:overflow-y-hidden max-md:max-h-full md:min-w-[350px] lg:min-w-[600px] w-full`}
    >
      <form
        onSubmit={handleSubmit}
        className={` ${
          theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-[#f7f7f7]"
        } flex flex-col pb-[15px]`}
      >
        <div
          className={`${
            theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "md:bg-MainBg"
          } flex justify-between items-center px-[12px] mb-[20px] border-b border-b-[#ebe7e7] font-[700] text-size18 leading-[14px] h-[56px] md:sticky md:top-0 md:left-0`}
        >
          <div
            className={`${
              theme === "dark" ? "hover:bg-white/20" : "hover:bg-black/10"
            } p-[5px] rounded-full`}
          >
            <AiOutlineClose
              className="c-pointer"
              onClick={() => {
                setAddNewSizeOpen(false);
              }}
              size={26}
            />
          </div>
          <h1
            className={`${
              theme === "dark" ? "text-DarkMainText" : "text-MainText"
            } md:py-[27px]`}
          >
            Add New Size
          </h1>
          <button
            type="submit"
            className={`${
              spinnerLoad && "cursor-default pointer-events-none"
            } flex justify-center items-center h-[52px] text-size18 c-pointer font-[700] text-PrimaryColor hover:underline d-300`}
          >
            {spinnerLoad ? <ProcessSpinner /> : "Done"}
          </button>
        </div>
        <div
          className={`${
            theme === "dark"
              ? "text-DarkMainText/70 bg-DarkBoxBg"
              : "text-SecondText lg:bg-[#f7f7f7]"
          }  flex flex-col px-[15px] lg:mt-[7px] lg:mb-[20px] lg:mx-[30px] overflow-y-auto`}
        >
          <div className="flex flex-col">
            <h1 className="mb-[0.25rem]">length in Cm</h1>
            <input
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              type="number"
              placeholder="length in cm"
              name="length_cm"
              value={form.length_cm}
              onChange={handleChange}
            />
            <h1 className="mb-[0.25rem]">width in Cm</h1>
            <input
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              type="number"
              placeholder="width in cm"
              name="width_cm"
              value={form.width_cm}
              onChange={handleChange}
            />
            <h1 className="mb-[0.25rem]">length in Inch</h1>
            <input
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              type="number"
              placeholder="length in inch"
              name="length_inch"
              value={form.length_inch}
              onChange={handleChange}
            />
            <h1 className="mb-[0.25rem]">width in Inch</h1>
            <input
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              type="number"
              placeholder="width in inch"
              name="width_inch"
              value={form.width_inch}
              onChange={handleChange}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddNewSize;
