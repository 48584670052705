import React, { useState, useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { PopupContext } from "../../Context/PopupContext";
import ProcessSpinner from "../../Shared/Components/ProcessSpinner";
import BreakDiv from "../../Shared/Components/BreakDiv";
import { updateOrderStatus } from "../../Api/Dashboard";
import { useTheme } from "../../Context/ThemeContext";

function UpdateOrderStatus({
  id,
  currentStatus,
  refreshOrderInfo,
  setOrderDetails,
}) {
  const { theme } = useTheme();
  const { updateOrderStatusOpen, setUpdateOrderStatusOpen } =
    useContext(PopupContext);
  const [spinnerLoad, setSpinnerLoad] = useState(false);
  const [status, setStatus] = useState(currentStatus);
  const [newStatus, setNewStatus] = useState("Cancelled");
  const [startDate, setStartDate] = useState({
    year: "",
    month: "",
    day: "",
  });
  const [endDate, setEndDate] = useState({
    year: "",
    month: "",
    day: "",
  });

  const handleSubmit = async (e) => {
    setSpinnerLoad(true);
    e.preventDefault();
    try {
      const payload = {
        status: newStatus,
      };
      // Only add from and to if newStatus is "Processing"
      if (newStatus === "Processing") {
        // Format the startDate and endDate to 'year-month-day'
        const formattedStartDate = `${startDate.year}-${startDate.month}-${startDate.day}`;
        const formattedEndDate = `${endDate.year}-${endDate.month}-${endDate.day}`;
        // Add the formatted dates to the payload
        payload.from = formattedStartDate;
        payload.to = formattedEndDate;
      }
      const response = await updateOrderStatus(id, payload);
      // console.log(response);
    } catch (error) {
      console.error("update status order details err", error);
    }
    setSpinnerLoad(false);
    setUpdateOrderStatusOpen(false);
    refreshOrderInfo();
  };
  const handleDateChange = (e, dateKey, dateType) => {
    const value = e.target.value;
    if (dateType === "start") {
      setStartDate((prevState) => ({ ...prevState, [dateKey]: value }));
    } else if (dateType === "end") {
      setEndDate((prevState) => ({ ...prevState, [dateKey]: value }));
    }
  };
  return (
    <div
      className={`${
        theme === "dark"
          ? "text-DarkMainText/70 bg-DarkBoxBg"
          : "text-SecondText lg:bg-[#f7f7f7]"
      } rounded-[20px] max-md:rounded-none max-h-[673px] overflow-y-auto max-md:min-w-full max-md:overflow-y-hidden max-md:max-h-full md:min-w-[350px] lg:min-w-[720px] w-full`}
    >
      <form onSubmit={handleSubmit} className="flex flex-col pb-[15px]">
        <div
          className={`${
            theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "md:bg-MainBg"
          } flex justify-between items-center px-[12px] mb-[20px] border-b border-b-[#ebe7e7] font-[700] text-size18 leading-[14px] h-[56px] md:sticky md:top-0 md:left-0`}
        >
          <div className="hover:bg-black/10 p-[5px] rounded-full">
            <AiOutlineClose
              className="c-pointer"
              onClick={() => {
                setUpdateOrderStatusOpen(false);
              }}
              size={26}
            />
          </div>
          <h1
            className={`${
              theme === "dark" ? "text-DarkMainText" : "text-MainText"
            } md:py-[27px]`}
          >
            Update Order Type
          </h1>
          <button
            type="submit"
            className={`${
              spinnerLoad && "cursor-default pointer-events-none"
            } flex justify-center items-center h-[52px] text-size18 c-pointer font-[700] text-PrimaryColor hover:underline d-300`}
          >
            {spinnerLoad ? <ProcessSpinner /> : "Done"}
          </button>
        </div>
        <div
          className={`${
            theme === "dark"
              ? "text-DarkMainText/70 bg-DarkBoxBg"
              : "text-SecondText lg:bg-[#f7f7f7]"
          } flex flex-col px-[15px] lg:mt-[7px] lg:mb-[20px] lg:mx-[30px] overflow-y-auto`}
        >
          <div className="flex flex-col">
            <h1 className="mb-[0.25rem]">Current Status</h1>
            <input
              readOnly
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              type="text"
              placeholder="User Name"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            />
            <h1 className="mb-[0.25rem]">Select New Order Status</h1>
            <select
              className={`${
                theme === "dark"
                  ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                  : "bg-[#fbf6f6] border-[#b5a7ac66] hover:border-SecondText focus:border-SecondText"
              } p-[0.75rem] rounded-[5px] border-[1.5px] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:shadow-none focus:no-outline d-300 focus:ring-0`}
              value={newStatus}
              onChange={(e) => {
                setNewStatus(e.target.value);
              }}
            >
              <option disabled value="">
                Select
              </option>
              <option value="Cancelled">Cancelled</option>
              <option value="Processing">Processing</option>
              <option value="Shipped">Shipped</option>
              <option value="Delivered">Delivered</option>
            </select>

            {newStatus === "Processing" && (
              <div>
                <BreakDiv className="my-2" />
                <h1 className="mb-[0.25rem]">Delivery Start Date</h1>
                <div className="flex gap-2 items-center">
                  <div className="flex flex-col">
                    <h1 className="mb-[0.25rem]">Year</h1>
                    <input
                      className="bg-white p-[0.75rem] rounded-[5px] border-[1.5px] border-[#e6e6e6] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:border-SecondText focus:shadow-none focus:no-outline d-300 focus:ring-0"
                      type="number"
                      placeholder="Year Of Start"
                      onChange={(e) => handleDateChange(e, "year", "start")}
                    />
                  </div>
                  <div className="flex flex-col">
                    <h1 className="mb-[0.25rem]">Month</h1>
                    <input
                      className="bg-white p-[0.75rem] rounded-[5px] border-[1.5px] border-[#e6e6e6] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:border-SecondText focus:shadow-none focus:no-outline d-300 focus:ring-0"
                      type="number"
                      placeholder="Month Of Start"
                      onChange={(e) => handleDateChange(e, "month", "start")}
                    />
                  </div>
                  <div className="flex flex-col">
                    <h1 className="mb-[0.25rem]">Day</h1>
                    <input
                      className="bg-white p-[0.75rem] rounded-[5px] border-[1.5px] border-[#e6e6e6] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:border-SecondText focus:shadow-none focus:no-outline d-300 focus:ring-0"
                      type="number"
                      placeholder="Day Of Start"
                      onChange={(e) => handleDateChange(e, "day", "start")}
                    />
                  </div>
                </div>
                <h1 className="mb-[0.25rem]">Delivery End Date</h1>
                <div className="flex gap-2 items-center">
                  <div className="flex flex-col">
                    <h1 className="mb-[0.25rem]">Year</h1>
                    <input
                      className="bg-white p-[0.75rem] rounded-[5px] border-[1.5px] border-[#e6e6e6] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:border-SecondText focus:shadow-none focus:no-outline d-300 focus:ring-0"
                      type="number"
                      placeholder="Year Of End"
                      onChange={(e) => handleDateChange(e, "year", "end")}
                    />
                  </div>
                  <div className="flex flex-col">
                    <h1 className="mb-[0.25rem]">Month</h1>
                    <input
                      className="bg-white p-[0.75rem] rounded-[5px] border-[1.5px] border-[#e6e6e6] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:border-SecondText focus:shadow-none focus:no-outline d-300 focus:ring-0"
                      type="number"
                      placeholder="Month Of End"
                      onChange={(e) => handleDateChange(e, "month", "end")}
                    />
                  </div>
                  <div className="flex flex-col">
                    <h1 className="mb-[0.25rem]">Day</h1>
                    <input
                      className="bg-white p-[0.75rem] rounded-[5px] border-[1.5px] border-[#e6e6e6] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:border-SecondText focus:shadow-none focus:no-outline d-300 focus:ring-0"
                      type="number"
                      placeholder="Day Of End"
                      onChange={(e) => handleDateChange(e, "day", "end")}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
}

export default UpdateOrderStatus;
