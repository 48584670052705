import React, { useState, useEffect } from "react";
import { HiChevronDoubleUp } from "react-icons/hi";
import { useTheme } from "../../Context/ThemeContext";

function ScrollTopButton({ className }) {
  const [isVisible, setIsVisible] = useState(false);
  const { theme } = useTheme();

  // Show button when page is scrolled upto given distance
  const toggleVisibility = () => {
    if (!isVisible) {
      window.addEventListener("scroll", handleScroll);
    }
    setIsVisible(!isVisible);
  };

  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Listen for scroll event
  const handleScroll = () => {
    let show = false;
    if (
      document.body.scrollTop > 400 ||
      document.documentElement.scrollTop > 400
    ) {
      show = true;
    }
    setIsVisible(show);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    isVisible && (
      <div
        onClick={scrollToTop}
        className={`${className} ${
          theme === "dark" ? "shadow-registerWayBoxShadowDM" : "shadow-xl"
        } text-DarkMainText border-DarkMainText border fixed c-pointer bottom-4 max-md:bottom-[90px] right-4 bg-PrimaryColor hover:bg-HoverPrimaryColor f-bold p-3 rounded-full hover:-translate-y-[3px] d-300`}
      >
        <HiChevronDoubleUp size={20} />
      </div>
    )
  );
}

export default ScrollTopButton;
