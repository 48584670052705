import { useState, useEffect, useContext } from "react";
import { HiDotsVertical } from "react-icons/hi";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { MdDateRange } from "react-icons/md";
import { PhotoContext } from "../../Context/PhotoContext";
import { PopupContext } from "../../Context/PopupContext";
import OrderFilterList from "../Components/OrderFilterList";
import { fetchOrders } from "../../Api/Dashboard";
import Calendar from "react-calendar";
import useOutsideClick from "../../Shared/Components/UseOutsideClick";
import { useTheme } from "../../Context/ThemeContext";
import Skeleton from "react-loading-skeleton";
import CustomPagination from "../../Shared/Components/CustomPagination";
import { formatDateData } from "../../utils/dateUtils";
import DashboardOutlet from "../Components/DashboardOutlet";

function OrdersView() {
  const { theme } = useTheme();
  const startDateRef = useOutsideClick(() => setStartDateOpen(false));
  const endDateRef = useOutsideClick(() => setEndDateOpen(false));
  const dropdownRef = useOutsideClick(() => setOrderFilterOpen(false));

  const formatDate = (date) => {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0")
    );
  };
  const [orders, setOrders] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { loading, setLoading, filterStatus, setFilterStatus, selectedFilter } =
    useContext(PhotoContext);
  const {
    orderFilterOpen,
    setOrderFilterOpen,
    startDateOpen,
    setStartDateOpen,
    endDateOpen,
    setEndDateOpen,
  } = useContext(PopupContext);
  const [totalPages, setTotalPages] = useState(0);
  const [itemPerPage, setItemPerPage] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [total, setTotal] = useState("");
  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    // console.log(selectedPage);
    setPageNumber(selectedPage);
  };

  const handleFetchAllOrders = async () => {
    try {
      setLoading(true);
      const response = await fetchOrders(
        filterStatus,
        endDate ? formatDate(endDate) : "",
        startDate ? formatDate(startDate) : "",
        pageNumber
      );
      // console.log(response.data);
      setOrders(response.data.data);
      setTotalPages(response.data.last_page);
      setItemPerPage(response.data.per_page);
      setPageNumber(response.data.current_page);
      setFrom(response.data.from);
      setTo(response.data.to);
      setTotal(response.data.total);
    } catch (error) {
      console.error("Error fetching orders data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleFetchAllOrders();
  }, [filterStatus, pageNumber]);

  return (
    <DashboardOutlet title={`Orders`}>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-5">
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="flex items-center c-pointer outline-none gap-2 bg-PrimaryColor t-white rounded-[20px] border-2 px-4 py-2 max-md:px-2 font-[700] max-md:py-1 hover:text-gray-100 hover:bg-HoverPrimaryColor hover:shadow-lg d-300"
          >
            <option
              className={`${
                theme === "dark"
                  ? "bg-DarkBoxBg text-DarkMainText"
                  : "bg-white text-MainText"
              }`}
              disabled
              checked
              value={""}
            >
              All status
            </option>
            <option
              className={`${
                theme === "dark"
                  ? "bg-DarkBoxBg text-DarkMainText"
                  : "bg-white text-MainText"
              }`}
              value="1"
            >
              Pending
            </option>
            <option
              className={`${
                theme === "dark"
                  ? "bg-DarkBoxBg text-DarkMainText"
                  : "bg-white text-MainText"
              }`}
              value="2"
            >
              Processing
            </option>
            <option
              className={`${
                theme === "dark"
                  ? "bg-DarkBoxBg text-DarkMainText"
                  : "bg-white text-MainText"
              }`}
              value="3"
            >
              Confirmed
            </option>
            <option
              className={`${
                theme === "dark"
                  ? "bg-DarkBoxBg text-DarkMainText"
                  : "bg-white text-MainText"
              }`}
              value="4"
            >
              Shipped
            </option>
            <option
              className={`${
                theme === "dark"
                  ? "bg-DarkBoxBg text-DarkMainText"
                  : "bg-white text-MainText"
              }`}
              value="5"
            >
              Delivered
            </option>
            <option
              className={`${
                theme === "dark"
                  ? "bg-DarkBoxBg text-DarkMainText"
                  : "bg-white text-MainText"
              }`}
              value="6"
            >
              Cancelled
            </option>
          </select>
          {filterStatus ? (
            <div
              onClick={() => setFilterStatus("")}
              className={`flex items-center c-pointer gap-2 bg-PrimaryColor t-white rounded-[20px] border-2 px-4 py-2 max-md:px-2 font-[700] max-md:py-1 hover:text-gray-100 hover:bg-HoverPrimaryColor hover:shadow-lg d-300`}
            >
              View all orders
            </div>
          ) : null}
        </div>
        <div className="flex gap-2 items-center">
          <div
            onClick={() => setStartDateOpen(!startDateOpen)}
            className="relative c-pointer"
            ref={startDateRef}
          >
            <Calendar
              className={`${startDateOpen ? "" : "hidden"} ${
                theme === "dark" && "bg-DarkBoxBg"
              } absolute min-w-[350px] top-[40px] right-0 z50`}
              onChange={setStartDate}
              value={startDate}
            />
            <div className="flex items-center gap-2 bg-PrimaryColor t-white rounded-[20px] border-2 px-4 py-2 max-md:px-2 font-[700] max-md:py-1 hover:text-gray-100 hover:bg-HoverPrimaryColor hover:shadow-lg d-300">
              <h1 className="flex items-center gap-1">
                From {startDate ? formatDate(startDate) : "yyyy-mm-dd"}
              </h1>
              <MdDateRange />
            </div>
          </div>
          <div
            onClick={() => setEndDateOpen(!endDateOpen)}
            className="relative c-pointer"
            ref={endDateRef}
          >
            <Calendar
              className={`${endDateOpen ? "" : "hidden"} ${
                theme === "dark" && "bg-DarkBoxBg"
              } absolute min-w-[350px] top-[40px] right-0 z50`}
              onChange={setEndDate}
              value={endDate}
            />
            <div className="flex items-center gap-2 bg-PrimaryColor t-white rounded-[20px] border-2 px-4 py-2 max-md:px-2 font-[700] max-md:py-1 hover:text-gray-100 hover:bg-HoverPrimaryColor hover:shadow-lg d-300">
              <h1 className="flex items-center gap-1">
                To {endDate ? formatDate(endDate) : "yyyy-mm-dd"}
              </h1>
              <MdDateRange />
            </div>
          </div>
          <button
            onClick={handleFetchAllOrders}
            className="flex items-center gap-2 bg-PrimaryColor t-white rounded-[20px] border-2 px-4 py-2 max-md:px-2 font-[700] max-md:py-1 hover:text-gray-100 hover:bg-HoverPrimaryColor hover:shadow-lg d-300"
          >
            Search
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <table
          className={`${
            theme === "dark" ? "bg-DarkBoxBg text-DarkMainText" : "bg-white"
          } w-full table-auto`}
        >
          <thead>
            <tr>
              <th className="border px-4 py-2">Order ID</th>
              <th className="border px-4 py-2">Order date</th>
              <th className="border px-4 py-2">Owner</th>
              <th className="border px-4 py-2">Location</th>
              <th className="border px-4 py-2">Status</th>
              <th className="border px-4 py-2">Total price</th>
              <th className="border px-4 py-2 relative">
                <HiDotsVertical className="absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%]" />
              </th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              Array.from({ length: 20 }).map((_, outInex) => (
                <tr key={outInex}>
                  {Array.from({ length: 7 }).map((_, index) => (
                    <td key={index}>
                      <Skeleton width={"100%"} height={35} />
                    </td>
                  ))}
                </tr>
              ))
            ) : orders && orders.length > 0 ? (
              orders.map((order, index) => (
                <tr key={index} className="">
                  <td className="border text-center px-4 py-2">#{order?.id}</td>
                  <td className="border text-center px-4 py-2">
                    {formatDateData(order?.created_at, "YYYY-MM-DD HH:mm:ss")}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {order?.cart?.user.name}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {order?.addresses?.address_line_1}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {order?.status}
                  </td>
                  <td className="border text-center px-4 py-2">
                    {order?.total_price}
                  </td>
                  <td className="border text-center px-4 py-2">
                    <Link
                      to={`/dashboard/orders/${order.id}`}
                      className="text-PrimaryColor f-bold c-pointer hover:underline d-300"
                    >
                      view
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <div>No Data</div>
            )}
          </tbody>
        </table>
        <CustomPagination
          totalPages={totalPages}
          currentPage={pageNumber}
          itemPerPage={itemPerPage}
          handlePageClick={handlePageClick}
          from={from}
          to={to}
          total={total}
          dashMode={true}
        />
      </div>
    </DashboardOutlet>
  );
}

export default OrdersView;
