import React, { useState, useContext } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { PhotoContext } from "../../Context/PhotoContext";
import { PopupContext } from "../../Context/PopupContext";
import ProcessSpinner from "../../Shared/Components/ProcessSpinner";
import { updateCategory } from "../../Api/Dashboard";

function UpdateCategory({ id, name, image, refreshArt }) {
  const { updateCategoryOpen, setUpdateCategoryOpen } =
    useContext(PopupContext);

  const [categoryName, setCategoryName] = useState(name);
  const [categoryImage, setCategoryImage] = useState(image);
  const [spinnerLoad, setSpinnerLoad] = useState(false);

  const handleSubmit = async (e) => {
    setSpinnerLoad(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", categoryName);
    formData.append("image", categoryImage);

    try {
      const response = await updateCategory(id, formData);
      // console.log(response);
      refreshArt();
    } catch (error) {
      console.error(error);
    } finally {
      setUpdateCategoryOpen(false);
    }
  };
  return (
    <div className="rounded-[20px] max-md:rounded-none max-h-[673px] overflow-y-auto max-md:min-w-full max-md:overflow-y-hidden max-md:max-h-full md:min-w-[350px] lg:min-w-[600px] w-full">
      <form
        onSubmit={handleSubmit}
        className="flex flex-col pb-[15px] bg-[#f7f7f7] "
      >
        <div className="flex justify-between items-center px-[12px] mb-[20px] border-b border-b-[#ebe7e7] font-[700] text-size18 leading-[14px] h-[56px] md:sticky md:top-0 md:left-0 md:bg-MainBg">
          <div className="hover:bg-black/10 p-[5px] rounded-full">
            <AiOutlineClose
              className="c-pointer"
              onClick={() => {
                setUpdateCategoryOpen(false);
              }}
              size={26}
            />
          </div>
          <h1 className="md:py-[27px] text-MainText">Update Category</h1>
          <button
            type="submit"
            className={`${
              spinnerLoad && "cursor-default pointer-events-none"
            } flex justify-center items-center h-[52px] text-size18 c-pointer font-[700] text-PrimaryColor hover:underline d-300`}
          >
            {spinnerLoad ? <ProcessSpinner /> : "Done"}
          </button>
        </div>
        <div className="flex flex-col px-[15px] text-SecondText lg:bg-[#f7f7f7] lg:mt-[7px] lg:mb-[20px] lg:mx-[30px] overflow-y-auto">
          <div className="flex flex-col">
            <h1 className="mb-[0.25rem]">Category Name</h1>
            <input
              className="bg-white p-[0.75rem] rounded-[5px] border-[1.5px] border-[#e6e6e6] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:border-SecondText focus:shadow-none focus:no-outline d-300 focus:ring-0"
              type="text"
              placeholder="Category Name"
              value={categoryName}
              onChange={(e) => setCategoryName(e.target.value)}
            />
            <h1 className="mb-[0.25rem]">Category Image</h1>
            <input
              className="bg-white p-[0.75rem] rounded-[5px] border-[1.5px] border-[#e6e6e6] inputsAddressBoxShadow placeholder:text-[#958289] mb-[20px] focus:border-SecondText focus:shadow-none focus:no-outline d-300 focus:ring-0"
              type="file"
              onChange={(e) => setCategoryImage(e.target.files[0])}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default UpdateCategory;
