import React from "react";
import Skeleton from "react-loading-skeleton";

function CheckoutSkeleton() {
  return (
    <div className="mb-[8px]">
      <div className="flex justify-between items-center text-size16">
        <Skeleton height={26} width={100} />
      </div>
      <div className="flex flex-col gap-1">
        <Skeleton height={24} width={60} />
        <Skeleton height={24} width={80} />
        <Skeleton height={24} width={80} />
        <Skeleton height={24} width={80} />
      </div>
      <div className="w-full mb-[10px]">
        <Skeleton className="w-full h-[20px]" />
      </div>
      <div className="mb-[8px] flex justify-between items-center text-size16 font-[400] leading-[24px]">
        <Skeleton height={24} width={50} />
        <Skeleton height={24} width={50} />
      </div>
      <div className="mb-[8px] flex justify-between items-center text-size16 font-[400] leading-[24px]">
        <Skeleton height={24} width={50} />
        <Skeleton height={24} width={50} />
      </div>
    </div>
  );
}

export default CheckoutSkeleton;
