import React, { useContext, useState } from "react";
import { AiOutlineClose, AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaHouseChimney } from "react-icons/fa6";
import { BsFillPostcardHeartFill } from "react-icons/bs";
import { PiBookmarksFill } from "react-icons/pi";
import { useEffect } from "react";
import ProcessSpinner from "../../Shared/Components/ProcessSpinner";
import { PhotoContext } from "../../Context/PhotoContext";
import { PopupContext } from "../../Context/PopupContext";
import {
  fetchAddress,
  showCartInfo,
  checkOutOrder,
  postDiscountCode,
} from "../../Api/Editphotos";
import { useTheme } from "../../Context/ThemeContext";
import Popup from "../../Shared/Components/Popup";
import AddAddress from "./AddAddress";
import CheckoutSkeleton from "./Skeleton/CheckoutSkeleton";
import {
  toastError,
  toastSuccess,
} from "../../Shared/Components/ToastNotification/Toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BreakDiv from "../../Shared/Components/BreakDiv";
import Skeleton from "react-loading-skeleton";
import { triggerPinterestEvent } from '../Pages/Home/Utils/pinterestTracking';
function Checkout() {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const currency = localStorage.getItem("currency") || "USD";
  const lng = localStorage.getItem("vividVisionsLang") || "English";
  const giftNote = localStorage.getItem("giftNote");
  const navigate = useNavigate();
  const {
    setAddress,
    setRegionType,
    cartItemsInfo,
    setCartItemsInfo,
    cartItemsInfoUpdate,
    setCartItemsInfoUpdate,
    checkoutDataLoading,
    setCheckoutDataLoading,
  } = useContext(PhotoContext);
  const { setCheckoutOpen, setAddressOpen, addressOpen } =
    useContext(PopupContext);

  const [checkLoading, setCheckLoading] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);
  const [note, setNote] = useState(giftNote);
  const [codeOpen, setCodeOpen] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [spinnerLoad, setSpinnerLoad] = useState(false);

  const savePromoCode = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("code", promoCode);
    if (giftNote) {
      formData.append(`gift_note`, giftNote.trim());
    }
    if (cartItemsInfo.addressId) {
      formData.append(`address_id`, cartItemsInfo.addressId);
    }
    if (cartItemsInfo.orderId) {
      formData.append("order_id", cartItemsInfo.orderId);
    }
    try {
      setSpinnerLoad(true);
      const response = await checkOutOrder(formData);
      // console.log(response);
      if (response.success === "true") {
        toastSuccess(response.message);
        setPromoCode("");
        setCodeOpen(false);
        // window.location.reload();
        setCartItemsInfoUpdate((prev) => prev + 1);
      }
    } catch (error) {
      console.error("post discount code error", error);
    } finally {
      setSpinnerLoad(false);
    }
  };

  const handleCheckout = async () => {
    const formData = new FormData();
    if (cartItemsInfo.discountCode) {
      formData.append("code", cartItemsInfo.discountCode);
    }
    if (giftNote) {
      formData.append(`gift_note`, giftNote.trim());
    }
    if (cartItemsInfo.addressId) {
      formData.append(`address_id`, cartItemsInfo.addressId);
    }
    if (cartItemsInfo.orderId) {
      formData.append("order_id", cartItemsInfo.orderId);
    }
    if (cartItemsInfo.addressId) {
      try {
        setCheckLoading(true);
        const response = await checkOutOrder(formData);
        // console.log(response);
        if (response.success === "true") {
          navigate(`/payment-details/${response.data.number}`);
          triggerPinterestEvent('checkout', {
            order_id: cartItemsInfo.orderId,
            event_id: 'eventId0003', // Update this with your specific event ID
            value: cartItemsInfo.totalCost || 100.0, // Pass the price as value
            currency: "USD",
            em: localStorage.getItem("userId") // securely hashed or anonymized user ID // The currency of the transaction
          });

          setCheckoutOpen(false);

        }
      } catch (err) {
        // console.log("checkout err : ", err);
      } finally {
        setCheckLoading(false);
      }
    } else {
      toastError(t("Please enter your address details"));
      setAddressOpen(true);
    }
  };

  const showCart_cartItems = async () => {
    try {
      setCheckoutDataLoading(true);
      const response = await showCartInfo();
      // console.log(response.data);
      setCartItemsInfo({
        ...cartItemsInfo,
        totalMaterialsCount: response.data.totalMaterialsCount,
        totalPictureCount: response.data.totalPicturesCount,
        totalCost: response.data.total_cost,
        totalCostWithDiscount: response.data.price_after_discount,
        cartItems: response.data.cartItems,
        discountCode: response.data.cartItems[0]?.order?.discount?.code,
        addressId: response.data.cartItems[0]?.order?.addresses?.id,
        orderId: response.data.cartItems[0]?.order?.id,
        orderNumber: response.data.cartItems[0]?.order?.number,
      });
    } catch (err) {
      console.error("show cart information err", err);
    } finally {
      setCheckoutDataLoading(false);
    }
  };

  useEffect(() => {
    showCart_cartItems();
  }, [cartItemsInfoUpdate]);

  const fetchAddressInfo = async () => {
    try {
      const response = await fetchAddress(cartItemsInfo.addressId);
      // console.log(response.data);
      // Extract necessary data from the response
      const {
        address_line_1,
        address_line_2,
        country_id,
        region_id,
        email,
        city,
        postcode,
        zip,
        contact_number,
        full_name,
      } = response.data;

      // Update the address state
      setAddress((prevAddress) => ({
        ...prevAddress,
        address_line_1,
        address_line_2,
        country_id,
        region_id,
        email,
        city,
        postcode,
        zip,
        contact_number,
        full_name,
      }));
      setRegionType(response.data.country.type);
    } catch (error) {
      console.error("Error fetching address data:", error);
    }
  };

  useEffect(() => {
    // console.log("cartItem is : ", cartItemsInfo);
  }, [cartItemsInfo]);

  return (
    <div className="max-h-full relative">
      <h1 className="flex font-[700] text-[30px] max-md:text-size20 items-center pb-[18px]">
        {t("Checkout")}
      </h1>
      <div
        onClick={() => setCheckoutOpen(false)}
        className={`${
          theme === "dark"
            ? "bg-PrimaryColor hover:bg-HoverPrimaryColor border-white"
            : "bg-PrimaryColor hover:bg-HoverPrimaryColor "
        } ${
          lng === "Arabic" ? "-left-[9px]" : "-right-[9px]"
        } text-white d-300 absolute border top-[0px] p-1 rounded-full c-pointer flex justify-center items-center`}
      >
        <AiOutlineClose />
      </div>
      <div className="flex flex-col">
        {checkoutDataLoading ? (
          <Skeleton className="w-full h-[31.25px]" />
        ) : (
          <div
            onClick={async () => {
              if (cartItemsInfo.addressId) {
                setAddressOpen(true);
                await fetchAddressInfo();
              } else {
                setAddressOpen(true);
              }
            }}
            className={`${
              cartItemsInfo.addressId
                ? theme === "dark"
                  ? "text-DarkMainText"
                  : "text-MainText"
                : "text-PrimaryColor"
            } flex justify-between items-center w-full c-pointer pb-2`}
          >
            <div className="flex gap-[20px]">
              <div className="inline-block w-[18px] h-[18px]">
                <FaHouseChimney size={18} />
              </div>{" "}
              <h1 className="text-size16 font-[600] inline-block">
                {cartItemsInfo?.addressId
                  ? `${
                      cartItemsInfo?.cartItems[0]?.order?.addresses?.country
                        ?.name
                    }
                  ,
                  ${cartItemsInfo?.cartItems[0]?.order?.addresses?.region?.name}
                  ${
                    cartItemsInfo?.cartItems[0]?.order?.addresses?.city !== "0"
                      ? `,
                  ${cartItemsInfo?.cartItems[0]?.order?.addresses?.city}`
                      : ""
                  } 
                  ${
                    cartItemsInfo?.cartItems[0]?.order?.addresses
                      ?.address_line_1 !== "0"
                      ? `,
                  ${cartItemsInfo?.cartItems[0]?.order?.addresses?.address_line_1}`
                      : ""
                  }
                   ${
                     cartItemsInfo?.cartItems[0]?.order?.addresses
                       ?.address_line_2 !== "0"
                       ? `,
                  ${cartItemsInfo?.cartItems[0]?.order?.addresses?.address_line_2}`
                       : ""
                   }
                  `
                  : t("Add Address")}
              </h1>
            </div>
            <h1
              className={`${
                theme === "dark"
                  ? "hover:text-DarkMainText"
                  : "hover:text-MainText"
              } ${
                cartItemsInfo.addressId ? "" : "hidden"
              } underline font-[400] text-size14 text-PrimaryColor d-300`}
            >
              {t("Modify")}
            </h1>
          </div>
        )}
        <BreakDiv />
        {checkoutDataLoading ? (
          <Skeleton className="w-full h-[31.25px]" />
        ) : (
          <>
            <div
              onClick={() => setNoteOpen(true)}
              className={`${
                giftNote
                  ? theme === "dark"
                    ? "text-DarkMainText"
                    : "text-MainText"
                  : "text-PrimaryColor"
              } flex justify-between items-center w-full c-pointer h-[40px]`}
            >
              <div className="flex items-center gap-[20px]">
                <BsFillPostcardHeartFill size={18} />
                <h1 className="text-size16 font-[600]">
                  {t("Attach a special note")}
                </h1>
              </div>
              <h1
                className={`${
                  theme === "dark"
                    ? "hover:text-DarkMainText"
                    : "hover:text-MainText"
                } ${
                  note ? "" : "hidden"
                } underline font-[400] text-size14 text-PrimaryColor d-300`}
              >
                {t("Modify")}
              </h1>
            </div>
            {noteOpen && (
              <div className="w-full h-[150px] relative mt-5">
                <textarea
                  className={`${
                    theme === "dark"
                      ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                      : "text-MainText hover:border-[#958289] focus:border-PrimaryColor"
                  } w-full h-full resize-none text-size16 leading-[21px] border-transparent outline outline-[#f2e9e9] rounded-[8px] py-[14px] pr-[20px] pl-[16px] d-300`}
                  placeholder={t("Enter gift note here")}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  maxLength={100}
                />
                <div
                  onClick={() => setNoteOpen(false)}
                  className={`${
                    theme === "dark"
                      ? "bg-DarkBoxBg hover:bg-slate-500 border-white"
                      : "bg-slate-300 hover:bg-slate-400 border-MainText"
                  } absolute border border-transparent -top-[9px] -right-[9px] p-1 rounded-full d-300 c-pointer`}
                >
                  <AiOutlineClose />
                </div>
                <div
                  onClick={() => {
                    localStorage.setItem("giftNote", note);
                    toastSuccess(t("The note has been saved"));
                    setNoteOpen(false);
                  }}
                  className={`${
                    theme === "dark"
                      ? "bg-DarkBoxBg hover:bg-slate-500 border-white"
                      : "bg-slate-300 hover:bg-slate-400 border-MainText"
                  } ${
                    lng === "Arabic" ? "-left-[9px]" : "-right-[9px]"
                  } absolute border border-transparent -bottom-[9px] px-2 py-1 rounded-full d-300 c-pointer`}
                >
                  {t("Save")}
                </div>
              </div>
            )}
            {giftNote && (
              <div className="flex flex-col gap-1 mb-2 max-w-full">
                <div className="text-size16 font-[600]">{t("Note")} :</div>
                <div
                  className={`${
                    theme === "dark" ? "text-DarkMainText" : "text-MainText"
                  } overflow-x-hidden text-size16 leading-[21px]`}
                >
                  {giftNote}
                </div>
              </div>
            )}
          </>
        )}
        <BreakDiv />
        {checkoutDataLoading ? (
          <Skeleton className="w-full h-[31.25px]" />
        ) : (
          <>
            <div
              onClick={() => setCodeOpen(true)}
              className={`${
                cartItemsInfo.discountCode
                  ? theme === "dark"
                    ? "text-DarkMainText"
                    : "text-MainText"
                  : "text-PrimaryColor"
              } flex justify-between items-center w-full c-pointer h-[40px]`}
            >
              <div className="flex items-center gap-[20px]">
                <PiBookmarksFill size={18} />
                <h1 className="text-size16 font-[600]">
                  {t("Add a discount code")}
                </h1>
              </div>
              <h1
                className={`${
                  theme === "dark"
                    ? "hover:text-DarkMainText"
                    : "hover:text-MainText"
                } ${
                  cartItemsInfo.discountCode ? "" : "hidden"
                } underline font-[400] text-size14 text-PrimaryColor d-300`}
              >
                {t("Modify")}
              </h1>
            </div>
            {codeOpen && (
              <form onSubmit={savePromoCode} className="w-full relative mt-5">
                <input
                  className={`${
                    theme === "dark"
                      ? "text-DarkMainText bg-DarkMainBg border-[#acadad] hover:border-DarkMainText focus:border-DarkMainText"
                      : "text-MainText hover:border-[#958289] focus:border-PrimaryColor"
                  } w-full h-full text-size16 leading-[21px] border-transparent outline outline-[#f2e9e9] rounded-[8px] py-[14px] pr-[20px] pl-[16px] d-300`}
                  placeholder={t("Enter Here")}
                  value={promoCode}
                  onChange={(e) => setPromoCode(e.target.value)}
                  maxLength={100}
                />
                <div
                  onClick={() => setCodeOpen(false)}
                  className={`${
                    theme === "dark"
                      ? "bg-DarkBoxBg hover:bg-slate-500 border-white"
                      : "bg-slate-300 hover:bg-slate-400 border-MainText"
                  } absolute border border-transparent -top-[9px] -right-[9px] p-1 rounded-full d-300 c-pointer`}
                >
                  <AiOutlineClose />
                </div>
                <button
                  type="submit"
                  className={`${
                    theme === "dark"
                      ? "bg-DarkBoxBg hover:bg-slate-500 border-white"
                      : "bg-slate-300 hover:bg-slate-400 border-MainText"
                  } ${
                    spinnerLoad
                      ? "pointer-events-none cursor-default"
                      : "c-pointer"
                  } ${
                    lng === "Arabic" ? "-left-[9px]" : "-right-[9px]"
                  } min-w-[49.46px] min-h-[33.28px] flex justify-center items-center absolute border border-transparent -bottom-[9px] px-2 py-1 rounded-full d-300`}
                >
                  {spinnerLoad ? (
                    <AiOutlineLoading3Quarters className="animate-spin" />
                  ) : (
                    t("Save")
                  )}
                </button>
              </form>
            )}
          </>
        )}
        {cartItemsInfo?.cartItems[0]?.order?.discount?.code && (
          <div className="flex flex-col gap-1 mb-2 max-w-full">
            <div className="text-size16 font-[600] inline-block">
              {t("Discount Code")} :
            </div>
            <div
              className={`${
                theme === "dark" ? "text-DarkMainText" : "text-MainText"
              } inline-block overflow-x-hidden text-size16 leading-[21px]`}
            >
              {cartItemsInfo?.cartItems[0]?.order?.discount?.code}
            </div>
          </div>
        )}
        <div className="text-size14 leading-[1.45] mb-[12px]">
          <BreakDiv className={`my-2`} />
          {checkoutDataLoading ? (
            <CheckoutSkeleton />
          ) : (
            <div
              className={`${
                theme === "dark" ? "text-DarkMainText" : "text-MainText"
              } mb-[8px]`}
            >
              <div className={`flex justify-between items-center text-size16`}>
                <div className="flex flex-col">
                  <h1 className="font-[400] leading-[26px]">
                    {cartItemsInfo.cartItems.length + " "}
                    {t("Tiles")}
                  </h1>
                  <div className="flex flex-col gap-2 my-2">
                    {cartItemsInfo.cartItems.length > 0 &&
                      cartItemsInfo.cartItems.map((item, index) => (
                        <div key={index} className="flex flex-col gap-1">
                          <h1 className="text-PrimaryColor font-[600]">
                            {t("Tile")} {index + 1}
                          </h1>
                          <div className="flex items-center gap-1">
                            <span className="font-[600]">
                              {t("Material")} :{" "}
                            </span>{" "}
                            {item?.material?.name}
                          </div>
                          {item.size && (
                            <div className="flex items-center gap-1">
                              <span className="font-[600]">{t("Size")} : </span>
                              {`${item?.size?.length_inch}x${item?.size?.width_inch}" (${item?.size?.length_cm}x${item?.size?.width_cm} cm)`}
                            </div>
                          )}
                          <div className="flex items-center gap-1">
                            <span className="font-[600]">{t("Price")} : </span>{" "}
                            {parseFloat(item?.price).toFixed(2)} {currency}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                {/* <h1 className="underline c-pointer font-[400] text-size14 text-PrimaryColor d-300 hover:text-MainText">
                  {t("Modify")}
                </h1> */}
              </div>
              <div className="mb-[8px] flex justify-between items-center text-size16 font-[400] leading-[24px]">
                <h1>{t("Shipping")}</h1>
                <h1>{t("Free")}</h1>
              </div>
              <div className="mb-[8px] flex justify-between items-center text-size16 font-[400] leading-[24px]">
                <h1>
                  {cartItemsInfo?.totalCostWithDiscount !== 0
                    ? t("Total Before Discount")
                    : t("Total")}
                </h1>
                <h1 className="font-[600]">
                  {Number(cartItemsInfo?.totalCost).toFixed(2)} {currency}
                </h1>
              </div>
              {cartItemsInfo?.totalCostWithDiscount !== 0 && (
                <div className="mb-[8px] flex justify-between items-center text-size16 font-[400] leading-[24px]">
                  <h1>{t("Total")}</h1>
                  <h1 className="font-[600]">
                    {Number(cartItemsInfo?.totalCostWithDiscount).toFixed(2)}{" "}
                    {currency}
                  </h1>
                </div>
              )}
            </div>
          )}
        </div>
        <div
          onClick={() => {
            handleCheckout();
          }}
          className={`${
            checkoutDataLoading || checkLoading
              ? "opacity-30 cursor-default pointer-events-none"
              : ""
          } bg-PrimaryColor hover:bg-HoverPrimaryColor py-1 px-2 text-center flex justify-center items-center h-[52px] w-full min-w-[209px] c-pointer text-size20 text-[#fff] font-[700] outline-none r-12 my-[10px] d-300`}
        >
          {checkoutDataLoading || checkLoading ? (
            <ProcessSpinner />
          ) : (
            t("Confirm & Pay")
          )}
        </div>
      </div>
    </div>
  );
}

export default Checkout;
